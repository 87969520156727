<template>
	<div class="text-center py-5" v-if="triggers.guardando">
		<h5>Estableciendo los cambios en la unidad {{ unidad.nombre }}</h5>
		<div class="progress w-50 mx-auto mt-3" style="height:6px">
			<div class="progress-bar" ref="progressBar">
			</div>
		</div>
	</div>
	<div class="text-center py-5" v-if="triggers.success">
		<h4 class="text-success">✔ Unidad {{ unidad.nombre }} actualizada</h4>
	</div>
  <form v-if="!triggers.guardando && !triggers.success">
    <div class="form-row">
      <div class="col-12 px-0">
        <label class="form-label">Seleccioná un producto de la lista</label>
        <select-producto @seleccion="obtenerSeleccion($event)"></select-producto>
        <!-- <Select2 class="w-100 input-error" :options="productosSelect" :settings="{ settingOption: value, width: '100%', theme: 'classic', }" @select="obtenerSeleccion($event)" /> -->
      </div>
      <!-- Card producto seleccionado y botón agregar -->
      <div class="col-12 mt-2 px-0" v-if="seleccion.fireId">
        <div class="card">
          <div class="card-body p-2">
            <div class="row align-items-center">
              <div class="col-2">
                <img v-if="seleccion.imagen" class="img-fluid" :src="seleccion.imagen"/>
                <img v-else class="img-fluid" src="../../../public/assets/images/fs-preview-icon.jpg"/>
              </div>
              <div class="col-8">
                <div class="row">
                  <div class="col-12">
                    <h5 class="text-orange">{{ seleccion.nombre }}</h5>
                    <p class="font-sm">{{ seleccion.detalle }}</p>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <div class="btn-agregar" :class="{ 'btn-agregar-disabled': productoExistente || productoSeleccionadoAgregado }" @click="asignarProducto()">Agregar</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Alert producto existente -->
      <div class="alert alert-warning my-2 w-100" v-if="productoExistente">
        El producto
        <span class="font-weight-bold">ya existe en esta unidad de negocio</span
        >, podés usar el buscador de la tabla para encontrarlo
      </div>
      <div class="alert alert-success my-2 w-100" v-if="productoSeleccionadoAgregado">
        El producto fue agregado a la lista
      </div>
    </div>
  </form>
  <!-- Tabla -->
  <lista-carga class="mt-4" v-if="load" :card="false"></lista-carga>
  <div class="row mt-4" v-if="(!triggers.guardando && !triggers.success) && !load">
    <div class="col">
      <label class="tabla-label" >Administra los productos asignados a la unidad {{ unidad.nombre }}</label >
      <div class="table-responsive">
        <table id="productos" class="table" width="100%">
          <thead>
            <tr>
              <th></th>
              <th>Nombre</th>
              <th>Detalle</th>
              <th>Accion</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(producto, i) in productosEnUnidad" v-bind:key="producto.fireId">
              <td>
                <img class="img-icon" v-if="producto.imagen" :src="producto.imagen" />
                <img v-else class="img-icon" src="../../../public/assets/images/fs-preview-icon.jpg" />
              </td>
              <td>{{ producto.nombre }}</td>
              <td>
                <div class="texto-incompleto">{{ producto.detalle }}</div>
              </td>
              <td>
                <button
                  @click="abrirModalVerMas(producto)"
                  type="button"
                  class="btn btn-primary btn-xs mr-2"
                >
                  Ver mas
                </button>
                <button
                  v-on:click="quitarDeUnidad(i,producto)"
                  type="button"
                  class="btn btn-danger btn-xs"
                >
                  Quitar de unidad
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="row mt-5" v-if="!triggers.guardando && !triggers.success">
    <div class="col text-right">
      <button type="button" @click.prevent="close()" class="btn btn-light" @keydown.esc="close()" >Cancelar</button>
      <button type="button" @click.prevent="modificarProductos()" class="btn btn-success ml-2">Guardar los productos seleccionados</button>
    </div>
  </div>

  <modal-lista-ver-mas
    v-if="modalVerMas.open"
    :producto="modalVerMas.producto"
    @cerrar-modal-ver-mas="modalVerMas.open = false"
  ></modal-lista-ver-mas>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
import axios from "axios";
import ListaCarga from "../ListaCarga";
import ModalListaVerMas from "../Producto/modal/ModalListaVerMas";
import SelectProducto from '../Producto/SelectProducto'

import $ from "jquery";
// require("datatables.net");
// require("datatables.net-buttons/js/buttons.colVis.js")();
// require("datatables.net-buttons/js/buttons.html5.js")();
// require("datatables.net-buttons/js/buttons.flash.js")();
// require("datatables.net-buttons/js/buttons.print.js")();
import { productoConElId, tableConfig } from "../../../.env";
var productoTabla = tableConfig;
productoTabla.pageLength = 10;
productoTabla.buttons = [];

export default {
  name: "AdministrarProductosEnUnidad",
  components: { ListaCarga, ModalListaVerMas, SelectProducto},
  // directives: {VTooltip},
  props: ["unidad"],
  data() {
    return {
      // Controla las actualizaciones del padre.
      seReasigno: false,
      load: true,
		triggers: {
			guardando: false,
			success: false,
		},
      seleccion: {},
      productosEnUnidad: [],
      productoExistente: false,
      productoSeleccionadoAgregado: false,
      // actualizarSelect: 0,
      modalVerMas: {
        open: false,
        producto: null,
      },
    };
  },
  mounted() {
    let promesas = [];
	/* eslint-disable */
	if (this.unidad.productos!=undefined && this.unidad.productos != null) {
		Object.keys(this.unidad.productos).forEach((index) => {
		  let promesa = axios
			.get(productoConElId(this.unidad.productos[index].producto_id))
			.then((productoRes) => {
			  // console.log(productoRes.data);
			  let producto = productoRes.data;
			  producto.fireId = this.unidad.productos[index].producto_id;
			  this.productosEnUnidad.push(producto)
			})
			.catch((error) => {
			  console.log(error);
			  console.log("El producto con error tiene id: " + this.unidad.productos[index].producto_id);
			  alert("Hubo un error al obtener uno de los productos");
			})
		  promesas.push(promesa);
		});
	
		Promise.all(promesas).then(()=>{
		  this.load = false;
			this.$nextTick(() => {
			  $("#productos").dataTable(productoTabla);
			});
		})
	}else {
		this.load = false;
		this.$nextTick(() => {
			$("#productos").dataTable(productoTabla);
		});
	}
  },
  methods: {
    abrirModalVerMas(producto) {
      this.modalVerMas.producto = producto;
      this.modalVerMas.open = true;
    },
	animarProgressBar(){
        var progressBar = this.$refs.progressBar;
		progressBar.style.width = "0%";

		setTimeout(function() {
			progressBar.style.width = "10%";
			setTimeout(function() {
				progressBar.style.width = "30%";
				setTimeout(function() {
					progressBar.style.width = "100%";
					setTimeout(function() {
						return true
					}, 200); 
				}, 100);
			}, 200); 
		}, 100); 
	},
    modificarProductos(){
		this.triggers.guardando = true;
		/**
		 * Si el producto de la lista original no está en productosEnUnidad entonces
		 * el producto se elimino, se busca en empresas por el id de unidad y se elimina
		 */ 
		let productosOriginalesEliminados = [];
		
		// Se recorren los productos originales

		if (this.unidad.productos!=undefined && this.unidad.productos == null) {
			Object.keys(this.unidad.productos).flatMap((i) => {
				// Se comprueba uno por uno
				let esta = false;
				// Si en la lista de productosEnUnidad aparece seguimos por otro
				Object.keys(this.productosEnUnidad).forEach((productoEnUnidad) => {				
					if (this.unidad.productos[i].producto_id == this.productosEnUnidad[productoEnUnidad].fireId) {
						esta = true;
						return null
					}
				});
				// Si el producto no se encontro en la lista de productosEnUnidad entonces fué eliminado
				if (!esta) {
					productosOriginalesEliminados.push(this.unidad.productos[i].producto_id);
				}
			});
		}

		// console.log(productosOriginalesEliminados);
		if (productosOriginalesEliminados.length) {
			var referencias = [];
			var empresasRef = firebase.database().ref('empresas');
			empresasRef.on("child_added", (snapshot) => {
				let empresaId = snapshot.key;
				// Se puede enriquecer la información con data de la empresa que tiene el producto
				// console.log(empresaId);
				let unidades = snapshot.val().unidades;
				// Se recorren los productos eliminados
				Object.keys(productosOriginalesEliminados).forEach((index)=>{

					Object.keys(unidades).forEach((idUnidad) => {
						if (unidades[idUnidad].unidad_id == this.unidad.fireId) {
							if (unidades[idUnidad].productos != undefined) {
								Object.keys(unidades[idUnidad].productos).forEach((productoIdEnUnidad) => {
									if (unidades[idUnidad].productos[productoIdEnUnidad].producto_id == productosOriginalesEliminados[index]) {
										// console.log('empresas/'+empresaId+'/unidades/'+idUnidad+'/productos/'+productoIdEnUnidad);
										referencias.push('empresas/'+empresaId+'/unidades/'+idUnidad+'/productos/'+productoIdEnUnidad);
										return null
									}
								})
							}
							return null
						}
					})
				})
			});
			// console.log(referencias);
			// Si se obtuvieron referencias en empresas con el id de la unidad se eliminan
			referencias.forEach((url) => {
                firebase.database().ref(url).remove();
            });
		}

		let productos = Object.keys(this.productosEnUnidad).map((productoIndex) => {
			return {
				producto_id: this.productosEnUnidad[productoIndex].fireId
			}
		})
		// Luego se eliminan los productos en la unidad y se vuelven updatean
		let referenciaProductosEnUnidad = firebase.database().ref('unidades_negocio/'+ this.unidad.fireId+ '/productos');
		referenciaProductosEnUnidad.remove().then(()=>{
			Object.keys(productos).forEach(i => {
				referenciaProductosEnUnidad.push(productos[i])
			})
		}).then(()=>{
			this.triggers.guardando = true;
      this.animarProgressBar();
      setTimeout(() => {
        this.triggers.guardando = false;
        this.triggers.success = true;
        setTimeout(() => {
          this.$emit('productos-unidad-actualizados', true);
        }, 800);
      }, 800);
		})
    },
    quitarDeUnidad(indice) {
      this.load = true;
      this.productoSeleccionadoAgregado = false;
      
      this.productosEnUnidad.splice(indice, 1);

      $("#productos").dataTable().fnDestroy();
      this.load = false;
      this.$nextTick(() => {
        $("#productos").dataTable(productoTabla);
      });
      // console.log(indice);

      // console.log(producto.fireId);
      // firebase
      //   .database()
      //   .ref(
      //     "unidades_negocio/" +
      //       this.unidad.fireId +
      //       "/productos/" +
      //       producto.idEnUnidad
      //   )
      //   .remove()
      //   .then(() => {
      //     this.productosEnUnidad.splice(i, 1);
      //     console.log(producto.nombre + " removido");
      //     this.load = false;
      //     this.seReasigno = true;
      //     $("#productos")
      //       .dataTable()
      //       .fnDestroy();
      //     this.$nextTick(() => {
      //       $("#productos").dataTable(productoTabla);
      //     });
      //   })
      //   .catch((error) => {
      //     if (error) {
      //       console.log("Hubo un error");
      //     }
      //   });
    },
    asignarProducto() {
      if (this.productoExistente) return null;
      this.productoSeleccionadoAgregado = true;

      this.productosEnUnidad.push(this.seleccion);
      $("#productos").dataTable().fnDestroy();
      this.$nextTick(() => {
        $("#productos").dataTable(productoTabla);
      });
      // firebase
      //   .database()
      //   .ref("unidades_negocio/" + this.unidad.fireId + "/productos")
      //   .push({
      //     producto_id: this.seleccion.id,
      //     precio: this.seleccion.precio ? this.seleccion.precio : null,
      //   })
      //   .then(() => {
      //     setTimeout(() => {
      //       // this.seleccion = {};
      //       this.productoExistente = true;
      //       // this.actualizarSelect++;
      //     }, 200);
      //     this.load = false;
      //     this.seReasigno = true;
      //     $("#productos")
      //       .dataTable()
      //       .fnDestroy();
      //     this.$nextTick(() => {
      //       $("#productos").dataTable(productoTabla);
      //     });
      //   })
      //   .catch((error) => {
      //     if (error) {
      //       console.log(error);
      //       alert("Se producjo un error");
      //     }
      //   });
    },
    // obtenerSeleccion({ id, text, detalle, precio, imagen })
    obtenerSeleccion(productoSeleccionado) {
      this.productoSeleccionadoAgregado = false;
      this.seleccion = productoSeleccionado;
      this.productoExistente = this.elProductoExiste();
      
    },
    elProductoExiste() {
    //   console.log("entró a comprobar");
      let existe = false;
      Object.keys(this.productosEnUnidad).forEach((producto) => {
        if (this.productosEnUnidad[producto].fireId == this.seleccion.fireId) {
          this.productoExistente = true;
          console.log("El producto existe");
          existe = true;
          return null;
        }
      });
      return existe;
    },
    close() {
      this.$emit('productos-unidad-actualizados', false);
    },
    mostrarDataOGuion(data) {
      if (data) { return data } else { return "-"; }
    },
  },
};
</script>

<style scoped>
.tabla-label {
  position: absolute;
  top: 0;
  font-size: large;
  margin-bottom: 0;
}


.texto-incompleto {
  width: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .texto-incompleto {
    width: 100px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .texto-incompleto {
    width: 200px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .texto-incompleto {
    width: 400px;
  }
}

.text-orange {
  color: #e86d20;
}

.img-fluid {
  width: 100%;
  height: 60px;
  object-fit: cover;
  border-radius: 6px;
}

.btn-agregar {
  background: #10b759;
  border-radius: 6px;
  color: #fff;
  min-width: 100%;
  height: 60px;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  cursor: pointer;
}

.btn-agregar-disabled {
  background: #ececec;
  color: #212529;
  cursor: default;
}

.font-sm {
  font-size: 0.9rem;
}

h3 {
  color: #1ea21e;
}

.tag {
  cursor: pointer;
  margin-right: 0.4rem;
}

.form-control {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-error {
  border-color: red;
}

input,
select {
  font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
