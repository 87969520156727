<template>
  <div class="page-content">
    <!-- Navegación superior tipo breadcrumb -->
    <nav class="page-breadcrumb mb-2" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
          <router-link :to="{ name: 'Dashboard' }" class="text-dark">Inicio</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ view.title }}
        </li>
      </ol>
    </nav>

    <!-- Card title section -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h3 class=" mb-0">{{ capitalizeR(view.title) }}</h3>
            <div class="d-flex-center-between flex-wrap flex-md-nowrap">
              <p class="mb-0">{{view.description}}</p>
              <div class="w-50 text-left text-md-right">
                <button class="btn btn-light mr-2" v-if="trigger.create || trigger.update" @click.prevent="refreshTriggers('list')">Cancelar</button>
                <div v-else>
                  <button type="button" class="btn btn-primary" disabled v-if="!platos">Obteniendo {{view.title.toLowerCase()}}</button>
                  <button type="button" class="btn btn-primary" v-else @click.prevent="refreshTriggers('create')">
                    Crear {{view.title.toLowerCase()}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- Create -->
    <div class="row" v-if="trigger.create">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <create 
              :recetas="recetas"
              :categories="categories" 
              @end="handleEnd($event)"
            ></create>
          </div>
        </div>
      </div>
    </div>
    <!-- List -->
    <div class="row" v-if="trigger.list">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <list 
              :platos="platos" 
              :categories="categories"
              :recetas="recetas"
              @update="handleEvent('update',$event)"
              @delete="handleDelete($event)"
            ></list>
          </div>
        </div>
      </div>
    </div>
    <!-- Update -->
    <div class="row" v-if="trigger.update">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <update 
              :platos="platos" 
              :selected="selection"
              :categories="categories"
              @edit="handleEvent('update',$event)" 
              @end="handleEnd($event)"
            >
            </update>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getDataFrom,capitalize} from '@/utils';
import {apis} from '../../.env';
// import $ from "jquery";
import Swal from "sweetalert2";

import firebase from "firebase/app";
import "firebase/database";

import Create from '@/components/Plato/CreatePlato.vue';
import List from '@/components/Plato/ListPlato.vue';
import Update from '@/components/Plato/UpdatePlato.vue';

export default {
  name: "Platos",
  components:{
    Create,
    List,
    Update,
  },
  data(){
    return {
        view:{title:'Platos',description:'Agregue y modifique platos, estos sirven para mostrar a los comensales la información de su plato.'},
        entity:'platos',

        selection:null,
        platos:null,
        categories:[],
        recetas:[],
        trigger:{
            create:false,
            update:false,
            list:false,
        }
    }
  },
  mounted(){
      getDataFrom(apis.recetasEntradas, false).then((data)=>{
          this.updateAndList(); // Get platos

          this.categories = data;

          getDataFrom(apis.recetas, false).then((data)=>{ this.recetas = data; });
      });
  },
  methods:{
    refreshTriggers(active) {
        Object.keys(this.trigger).forEach((t) => { this.trigger[t] = false });
        if(active!=undefined) this.trigger[active] = true
    },
    handleEvent(action,selection){
      this.selection = selection;
      this.refreshTriggers(action);
    },
    updateAndList(){
      getDataFrom(apis[this.entity], false).then((data)=>{
          this[this.entity] = (data) ? data : [];
          this.refreshTriggers('list');
      }).catch((err)=>{ console.error(err); alert(`Ocurrio un error al obtener ${this.entity}`); });
    },
    handleEnd(update){
      if (update) {
        this.updateAndList()
      }else {
        this.refreshTriggers('list')
      }
    },
    handleDelete(fireId){
      Swal.fire({
        title: '¿Vas a eliminar el grupo?',
        text: "Cuidado! Esta acción no se puede deshacer",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          firebase.database().ref(`platos/${fireId}`).remove().then(()=>{
            Swal.fire(
              'Todo listo!',
              'El grupo fué eliminado.',
              'success'
            )

            this.updateAndList();
          })
        }
      })
    },
    capitalizeR(t){return capitalize(t)}
  }
}
</script>

<style>

</style>