<template>
  <nav class="sidebar">
    <div class="sidebar-header d-flex align-items-center">
      <a href="" class="sidebar-brand" style="font-size:1.3rem; margin-top: 4.8px;">
        <span>FS</span> ALIMENTACION
      </a>
      <div class="sidebar-toggler not-active">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="sidebar-body">
      <ul class="nav">
        <!-- <li class="nav-item">
          <router-link :to="{ name: 'Usuarios' }" class="nav-link">
            <i class="link-icon" data-feather="users"></i>
            <span class="link-title">Usuarios</span>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link :to="{ name: 'Turnos' }" class="nav-link">
            <i class="link-icon" data-feather="clock"></i>
            <span class="link-title">Turnos</span>
          </router-link>
        </li>
        <li class="nav-item nav-category">Reportes</li>
        <li class="nav-item">
          <router-link :to="{ name: 'Reservas' }" class="nav-link">
            <i class="link-icon" data-feather="calendar"></i>
            <span class="link-title">Reservas</span>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link :to="{ name: 'Reporte' }" class="nav-link">
            <i class="link-icon" data-feather="clipboard"></i>
            <span class="link-title">Reporte</span>
          </router-link>
        </li> -->

        <!-- <li class="nav-item nav-category" v-if="roles.editor">Aplicativos</li>   -->

         <!-- <li class="nav-item" v-if="roles.editor">
          <router-link :to="{ name: 'UnidadesDeNegocio' }" class="nav-link">
            <i class="link-icon" data-feather="package"></i>
            <span class="link-title">Unidades de negocio</span>
          </router-link>
        </li>       -->

        <!-- <li class="nav-item" v-if="roles.editor">
          <router-link :to="{ name: 'Empresas' }" class="nav-link">
            <i class="link-icon" data-feather="box"></i>
            <span class="link-title">Empresas</span>
          </router-link>
        </li> -->

        <!-- <li class="nav-item nav-category" v-if="roles.editor">Productos</li> -->

        <!-- <li class="nav-item" v-if="roles.editor">
          <router-link :to="{ name: 'Productos' }" class="nav-link">
            <i class="link-icon" data-feather="shopping-bag"></i>
            <span class="link-title">Agregar productos</span>
          </router-link>
        </li> -->
        <!-- <li class="nav-item" v-if="roles.editor">
          <router-link :to="{ name: 'ListaProductos' }" class="nav-link">
            <i class="link-icon" data-feather="list"></i>
            <span class="link-title">Lista de productos</span>
          </router-link>
        </li> -->
        <li class="nav-item nav-category">Recetario</li>
        
        <li class="nav-item">
          <router-link :to="{ name: 'Recetas' }" class="nav-link">
            <i class="link-icon" data-feather="map"></i>
            <span class="link-title">Recetas</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="role.editor">
          <router-link :to="{ name: 'Etiquetas' }" class="nav-link">
            <i class="link-icon" data-feather="tag"></i>
            <span class="link-title">Etiquetas</span>
          </router-link>
        </li>
        <li class="nav-item" v-if="role.editor">
          <router-link :to="{ name: 'Platos' }" class="nav-link">
            <i class="link-icon" data-feather="package"></i>
            <span class="link-title">Platos</span>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));
export default {
  name: "Menu",
  data() {
    return {
      role:{editor:false},
    }
  },
  mounted() {
    // Para esto es indiferente el tipo de rol editor (//TODO Diferenciar para ciertas aplicaciones los permisos de editor segund nivel (nivel 0: Romina, nivel 1: Carolina, Julieta) )
    if(userPermission){
      let permission = userPermission.find(record => record.ModulosAccess === "Alimentacion" || record.ModulosAccess === "AlimentacionEditor")
      this.role.editor = (permission!=undefined) ? permission.Editor : false;
    } else {
      this.role.editor =  false;
    }
  },
    methods:{
     isRoll(modulo){
        var status =  userPermission.find(record => record.ModulosAccess === modulo).Lector;
        return status;
      }
  }

};
</script>
