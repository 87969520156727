<template>
  <lista-carga v-if="load" :card="false"></lista-carga>
   <div class="row" v-else>
      <div class="col">
        <div class="table-responsive">
          <table id="unidades" class="table" width="100%">
            <thead>
              <tr>
                  <th>Nombre</th>
                  <th>Productos</th>
                  <th>Empresas</th>
                  <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(unidad, i) in unidadesDeNegocio" v-bind:key="i">
                  <td>{{ unidad.nombre }}</td>
                  <td>{{ unidad.cantidadProductos }}</td>
                  <td>{{ unidad.empresas }}</td>
                  <td class="text-right">
                      <button type="button" class="btn btn-success btn-sm" @click="abrirmodalAdministrarProductos(unidad)">Administrar productos</button>
                      <button type="button" class="btn btn-primary btn-sm ml-3" @click="abrirmodalAdministrarUnidad(unidad)">Administrar</button>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  <!-- <ModalAdministrarPoductos
    v-if="modalAdministrarProductos.open"
    :unidad="modalAdministrarProductos.unidad"
    @cerrar-modal-productos="cerrarModal($event)"
  ></ModalAdministrarPoductos> -->
  <modal-administrar-unidad
    v-if="modalAdministrarUnidad.open"
    :unidad="modalAdministrarUnidad.unidad"
    @cerrar-modal-unidad="cerrarModal($event)"
  ></modal-administrar-unidad>
</template>
<script>
import axios from "axios";
// import firebase from "firebase/app";
// import "firebase/database";
import ListaCarga from "../ListaCarga";
// import ModalListaVerMas from "./modal/ModalListaVerMas"
// import ModalAdministrarPoductos from "./modal/ModalAdministrarPoductos";
import ModalAdministrarUnidad from "./modal/ModalAdministrarUnidad";

import $ from "jquery";
require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();
import { apis, tableConfig } from "../../../.env";


export default {
  name: "ListaUnidadesDeNegocio",
  components: { ListaCarga, ModalAdministrarUnidad },
  data() {
    return {
      unidadesDeNegocio: [],
      load: true,
      modalAdministrarProductos: {
        open: false,
        unidad: null
      },
      modalAdministrarUnidad: {
        open: false,
        unidad: null
      }
    };
  },
  methods: {
    mostrarDataOGuion(data){ 
      if (data) { return data } else { return "-" } 
    },
    abrirmodalAdministrarUnidad(unidad){
      this.modalAdministrarUnidad.unidad = unidad;
      this.modalAdministrarUnidad.open = true;
    },
    abrirmodalAdministrarProductos(unidad){
      this.$emit('administrar-productos', unidad);
      // this.modalAdministrarProductos.unidad = unidad;
      // this.modalAdministrarProductos.open = true;
    },
    cerrarModal(reasignacion){
      this.modalAdministrarProductos.open = false;
      this.modalAdministrarUnidad.open = false;
      // console.log(reasignacion);
      if (reasignacion) {
        this.obtenerUnidadesDeNegocio();
      }

    },
    obtenerUnidadesDeNegocio(){
      this.load = true;
      this.unidadesDeNegocio = null;
      this.unidadesDeNegocio = [];
      axios
      .get(apis.unidadesDeNegocio)
      .then((responseUnidades) => {
        // console.log(responseProductos.data);
        if (responseUnidades.data) {
          this.unidadesDeNegocio = Object.keys(responseUnidades.data).map((unidad)=>{
            return {
              nombre: responseUnidades.data[unidad].nombre,
              cantidadProductos: (responseUnidades.data[unidad].productos != undefined) ? Object.keys(responseUnidades.data[unidad].productos).length : 0,
              productos: responseUnidades.data[unidad].productos,
              empresas: 0,
              fireId: unidad,
            }
          })
          
        }
        let empresasEnUnidades = [];
        
        axios.get(apis.empresas)
        .then(empresasResponse=>{
          if (empresasResponse.data) {
            let empresas = empresasResponse.data;

            Object.keys(empresas).forEach(empresa=>{
              if (empresas[empresa].unidades != undefined) {
                Object.keys(empresas[empresa].unidades).forEach(item=>{
                  // console.log(empresas[empresa].unidades[item].unidad_id);
                  empresasEnUnidades[empresas[empresa].unidades[item].unidad_id] = (empresasEnUnidades[empresas[empresa].unidades[item].unidad_id] || 0) + 1;
                });              
              }
            })
            Object.keys(this.unidadesDeNegocio).forEach(unidad=>{
              this.unidadesDeNegocio[unidad].empresas = empresasEnUnidades[this.unidadesDeNegocio[unidad].fireId];
            })
            
          }
        })

        $('#unidades').dataTable().fnDestroy();
        // this.unidadesDeNegocio = responseUnidades.data;
        this.load = false;
      }).then(()=>{
        let config = tableConfig;
        config.pageLength = 10;
        config.emptyTable = "No hay unidades de negocio creadas, creá una nueva"
        $('#unidades').dataTable(config);
      })
      .catch((error) => {
        console.log(error);
        alert("Se produjo un error al obtener las unidades de negocio. Actualice la página (tecla F5) y reintente o comuníquese con sistemas.");
      });
    }

  },
  mounted() {
    setTimeout(() => {
      this.obtenerUnidadesDeNegocio();
    }, 200);
  },
};
</script>

<style scoped>
.form-control {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.textarea {
  height: auto;
}

.img-icon {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 30%;
}

.texto-incompleto {
  width: 96px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
