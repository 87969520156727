<template>
  <lista-carga v-if="load" :card="false"></lista-carga>
   <div class="row" v-else>
      <div class="col">
        <div class="table-responsive">
          <table id="empresasLista00" class="table" width="100%">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Tipo</th>
                <th>Unidades</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(empresa, i) in empresas" v-bind:key="i">
                <td>{{ empresa.nombre }}</td>
                <td><div class="badge badge-success">{{capitalize(empresa.modalidad)}}</div></td>
                <td>
                  <div class="d-flex flex-wrap">
                    <div class="badge badge-primary my-1 mr-2" v-for="(unidad,i) in empresa.unidades" :key="i">
                      {{unidad.nombre}}: {{unidad.productosCantidad}}
                    </div>
                  </div>
                </td>
                <td class="text-right">
                    <button type="button" class="btn btn-success btn-sm" @click="administrarProductos(empresa)">Administrar productos</button>
                    <div class="dropdown d-inline ml-2">
                      <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Modificar empresa
                      </button>
                      <div class="dropdown-menu ml-3" aria-labelledby="dropdownMenuButton">
                        <!-- <a class="dropdown-item" href="#">Action</a>
                        <a class="dropdown-item" href="#">Another action</a>
                        <a class="dropdown-item" href="#">Something else here</a> -->
                        <button type="button" class="dropdown-item" @click="abrirModalModificarPerfiles(empresa)">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg> Editar perfiles
                        </button>
                        <div class="dropdown-divider"></div>
                        <button type="button" class="dropdown-item" @click="abrirModalModificarModalidad(empresa)">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg> Modificar modalidad
                        </button>
                        <div class="dropdown-divider"></div>
                        <button type="button" class="dropdown-item" @click="abrirModalAdministrarEmpresa(empresa)">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg> Administrar
                        </button>
                      </div>
                    </div>


                    <!-- <button type="button" class="btn btn-success btn-sm" @click="administrarProductos(empresa)">Administrar productos</button>
                    <button type="button" class="btn btn-primary btn-sm ml-3" @click="abrirModalModificarPerfiles(empresa)">Modificar perfiles</button>
                    <button type="button" class="btn btn-primary btn-sm ml-3" @click="abrirModalModificarModalidad(empresa)">Modificar modalidad</button>
                    <button type="button" class="btn btn-primary btn-sm ml-3" @click="abrirModalAdministrarEmpresa(empresa)">Administrar</button> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <modal-modificar-modalidad
      v-if="modalModificarModalidad.open"
      :empresa="modalModificarModalidad.empresa"
      @cerrar-modal-modalidad="cerrarModal($event)"
    ></modal-modificar-modalidad>
    <modal-modificar-perfiles
      v-if="modalModificarPerfiles.open"
      :empresa="modalModificarPerfiles.empresa"
      @cerrar-modal-perfiles="cerrarModal($event)"
    ></modal-modificar-perfiles>
    <modal-administrar-empresa
      v-if="modalAdministrarEmpresa.open"
      :empresa="modalAdministrarEmpresa.empresa"
      @cerrar-modal-administrar="cerrarModal($event)"
    ></modal-administrar-empresa>
</template>
<script>
import axios from "axios";
// import firebase from "firebase/app";
// import "firebase/database";
import ListaCarga from "../ListaCarga";
import $ from "jquery";
import { apis, tableConfig } from "../../../.env";
import ModalAdministrarEmpresa from './modal/ModalAdministrarEmpresa.vue';
import ModalModificarModalidad from './modal/ModalModificarModalidad'
import ModalModificarPerfiles from './modal/ModalModificarPerfiles'

export default {
  name: "ListaEmpresas",
  components: { ListaCarga,ModalAdministrarEmpresa, ModalModificarModalidad, ModalModificarPerfiles },
  data() {
    return {
      load: true,
      empresas: [],
      unidadesDeNegocio: [],
      modalAdministrarEmpresa: {
        open: false,
        empresa: null
      },
      modalModificarModalidad: {
        open: false,
        empresa: null
      },
      modalModificarPerfiles: {
        open: false,
        empresa: null
      }
    };
  },
  methods: {
    mostrarDataOGuion(data){
      if (data) { return data } else { return "-" }
    },
    // abrirmodalAdministrarProductos(unidad){
    //   this.modalAdministrarProductos.unidad = unidad;
    //   this.modalAdministrarProductos.open = true;
    // },
    abrirModalModificarPerfiles(empresa){
      this.modalModificarPerfiles.empresa = empresa;
      this.modalModificarPerfiles.open = true;
    },
    abrirModalAdministrarEmpresa(empresa){
      this.modalAdministrarEmpresa.empresa = empresa;
      this.modalAdministrarEmpresa.open = true;
    },
    abrirModalModificarModalidad(empresa){
      this.modalModificarModalidad.empresa = empresa;
      this.modalModificarModalidad.open = true;
    },
    cerrarModal(reasignacion){
      this.modalModificarPerfiles.open = false;
      this.modalModificarModalidad.open = false;
      this.modalAdministrarEmpresa.open = false;
      // console.log(reasignacion);
      if (reasignacion) {
        this.obtenerEmpresas();
      }
    },
    obtenerUnidadesDeNegocio(){
      axios
      .get(apis.unidadesDeNegocio)
      .then(responseUnidades => {
          this.unidadesDeNegocio = responseUnidades.data;
      }).catch(error=>{
        alert("Hubo un error al obtener las unidades de negocio");
        console.log(error);
      })
    },
    /**
     * Toma todas las empresas desde firebase y las guarda en la propiedad empresas.
     */
    obtenerEmpresas(){
      this.load = true;
      this.empresas = null;
      this.empresas = [];


      axios
      .get(apis.empresas)
      .then((responseEmpresas) => {
        if(responseEmpresas.status == 200 && responseEmpresas.data){
          let data = responseEmpresas.data;
          /**
           * flatMap filtra elementos según una condición, al retornar [] los pasa por alto.
           */
          this.empresas = Object.keys(data).flatMap((empresa_id)=>{
            // [ATENCIÓN] - Esto causa que no se listen emmpresas sin unidades
            if (data[empresa_id].unidades == undefined || data[empresa_id].unidades == null) return []

            // console.log(data[empresa_id].nombre);
            // console.log("Unidades: "+data[empresa_id].unidades);

            let unidadesDeEmpresa = Object.keys(data[empresa_id].unidades).flatMap(unidadIndex=>{   
              // [ATENCIÓN] - Esto causa que no se listen unidades sin productos
              if(data[empresa_id].unidades[unidadIndex].productos == undefined) return []  
              // Desde la propiedad unidadesDeDegocio toma el nombre de la unidad
              let unidadFullData = this.unidadesDeNegocio[data[empresa_id].unidades[unidadIndex].unidad_id];          
              return {
                  fireId: data[empresa_id].unidades[unidadIndex].unidad_id,
                  nombre: unidadFullData.nombre,
                  productosCantidad: Object.keys(data[empresa_id].unidades[unidadIndex].productos).length,
                  productos: data[empresa_id].unidades[unidadIndex].productos,
                };
            })

            let empresa = data[empresa_id];
            empresa.unidades = unidadesDeEmpresa;
            empresa.tipo_empresa = (data[empresa_id].tipo_empresa) ? data[empresa_id].tipo_empresa : 'NINGUNO';
            empresa.id_empresa = empresa_id;
            return empresa
            // return {
            //   nombre: data[empresa_id].nombre,
            //   tipo_empresa: (data[empresa_id].tipo_empresa) ? data[empresa_id].tipo_empresa : 'NINGUNO',
            //   id: empresa_id,
            //   unidades: unidadesDeEmpresa,
            //   modalidad: data[empresa_id].modalidad,
            //   contador: data[empresa_id].contador,
            //   anticipacion: data[empresa_id].anticipacion,
            //   stock: data[empresa_id].stock,
            // }
          })
          // $('#empresasLista00').dataTable().destroy();
          // $('#empresasLista00').dataTable().empty();

          $('#empresasLista00').dataTable().fnDestroy();
          // this.empresas = responseEmpresas.data;
          this.load = false;
        }else {
          this.load = false;
        }
      }).then(()=>{
        let config = tableConfig;
        config.pageLength = 10;
        $('#empresasLista00').dataTable(config);
      })
      .catch((error) => {
        console.log(error);
        alert("Se produjo un error al obtener las empresas");
      });
    },
    administrarProductos(empresa){
      this.$emit("modificar-empresa", empresa);
    },
    capitalize(string){
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

  },
  mounted() {
    this.obtenerUnidadesDeNegocio();
    setTimeout(() => {
      this.obtenerEmpresas();
      
    }, 500);
  },
};
</script>

<style scoped>
.dropdown-item {
  font-size: medium !important;
  display: flex;
  flex-flow: row;
  align-items: center;
}
.dropdown-item svg {
  width: 12px;
  stroke: #000;
  margin-right: 4px;
  padding-bottom: 2px;
}

.form-control {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.textarea {
  height: auto;
}

.img-icon {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 30%;
}

.texto-incompleto {
  width: 96px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
