<template >
      <div id="app" v-if="logged==true">
        <div class="main-wrapper">
          <Menu/>
          <div class="page-wrapper">
            <Navbar/>
            <router-view></router-view>
          </div>
        </div>
      <!--  <df-messenger
          intent="WELCOME"
          chat-title="MiFoodservice"
          agent-id="7ad439dc-9cb1-4455-a5c5-a856d27dd15b"
          language-code="es"
        ></df-messenger>-->
      </div>

      <div v-else>
          <router-view></router-view>
      </div>
   


</template>


<script>
import axios from 'axios';
import Menu from './components/Menu/Menu.vue'
import Navbar from './components/Navbar/Navbar.vue'
//const userData = JSON.parse(sessionStorage.getItem('userData'));


import firebase from "firebase/app";
import "firebase/database";
import { firebaseConfig,firebaseRecetario } from "../.env";

firebase.initializeApp(firebaseConfig);
/*eslint-disable*/
// const fireRecetario = firebase.initializeApp(firebaseRecetario,'fireRecetario');
/*eslint-enable*/
// let recetarioDB = firebase.initializeApp(firebaseRecetario);

// // var fireLogin = firebase.initializeApp(firebaseLogin, "fireLogin");
/*eslint-disable*/
const fireRecetario = firebase.initializeApp(firebaseRecetario,'fireRecetario');
/*eslint-enable*/






const userData = JSON.parse(sessionStorage.getItem("userData"));

export default {
    name: 'app',
    components:{
          Menu,
          Navbar,
          
  },
    data: () => ({
      logged: false,
    }),
    methods: {
      isLogged() {
      userData ? (this.logged = true) : (this.logged = false);

      if (this.logged) {
        sessionStorage.clear();
        this.$router.push({ path: "/" });

        axios
          .get(`https://auth.foodservice.com.ar/?type=user&dni=${userData.leg_numdoc}&access_token=1234567`)
          .then((response) => {
            sessionStorage.setItem(
              "userPermisos",
              JSON.stringify(response.data[0].Permisos)
            );
            sessionStorage.setItem(
              "userData",
              JSON.stringify(response.data[0].datos)
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$router.push("/Login");
      }
    },
      logout() {
        sessionStorage.removeItem('userData')
        this.$router.push('/login')
      }
    },
    computed: {
      loggedUser() {
        const token = sessionStorage.getItem('userData')
        const payload = JSON.parse(atob(token.split('.')[1]))

        return payload
      }
    },
    mounted() {
      this.isLogged()

    }
  };


</script>

<style>
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: white!important;
    background: #7c85f780!important;

}

.dt-button {
    background:none !important;
    display: inline-block!important;
    font-weight: 400!important;
    text-align: center!important;
    vertical-align: middle!important;
    user-select: none!important;
    border: none!important;
    font-size: 0.75rem!important;
    line-height: 1.5!important;
    border-radius: 0.1875rem!important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out!important;
    color: #fff!important;
    background-color: #727cf5!important;
    border-color: #727cf5!important;
    padding: 0.35rem 1rem!important;
    cursor: pointer!important;
    margin-top: .4rem;
}

.buttons-excel, .buttons-copy, .buttons-csv, .buttons-pdf {
    color: #212529 !important;
    background-color: #ececec !important;
    border-color: #ececec !important;
}

.buttons-excel:hover, .buttons-copy:hover, .buttons-csv:hover, .buttons-pdf:hover {
    color: #212529 !important;
    background-color: #dbdbdb !important;
    border-color: #dbdbdb !important;
}



/* 
.buttons-excel:hover {
    background-color: #0da34e!important;
}

.buttons-copy, .buttons-csv {
    background-color: #727cf5!important;
}

.buttons-copy:hover, .buttons-csv:hover {
    background-color: #6971d9!important;
}


.buttons-copy, .buttons-csv {
    background-color: #727cf5!important;
}

.buttons-copy:hover, .buttons-csv:hover {
    background-color: #6971d9!important;
}


.buttons-pdf {
    background-color: #ff3366 !important;
}

.buttons-pdf:hover {
    background-color: #f42c5e !important;
} */







.dataTables_wrapper .dataTables_paginate .paginate_button{
    background:none !important;
    display: inline-block!important;
    font-weight: 400!important;
    text-align: center!important;
    vertical-align: middle!important;
    user-select: none!important;
    border: none!important;
    font-size: 0.75rem!important;
    line-height: 1.5!important;
    border-radius: 0.1875rem!important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out!important;
    color: rgb(17, 17, 17)!important;
    padding: 0.35rem 1rem!important;
    cursor: pointer!important;
    margin-top: .3rem;
}


.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover{
    background-color: #727cf5!important;
    color: #fff !important;
}





 .multiselect__tags {
        border-color: #282f3ae6 !important;
    }

    .multiselect__content-wrapper {
        border-color: rgba(40, 47, 58, 0.9) !important 

    }

    .multiselect__tag-icon::after {
        color: rgb(253, 253, 253) !important;
    }

    .multiselect__tag {
        background: #727CF5 !important;
    }
    .multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
        background: #4e5bf2 !important;
    }

    .multiselect__tag_light {
        background: #ececec !important;
        position: relative;
        display: inline-block;
        padding: 4px 26px 4px 10px;
        border-radius: 5px;
        margin-right: 10px;
        color: rgb(27, 27, 27);
        line-height: 1;
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
    }

    .multiselect__tag-icon_light {
        cursor: pointer;
        margin-left: 7px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        font-weight: 700;
        font-style: normal;
        width: 22px;
        text-align: center;
        line-height: 22px;
        transition: all .2s ease;
        border-radius: 5px;
    }

    .multiselect__tag-icon_light::after {
        content: "\D7";
        color: #181818;
        font-size: 14px;
    }


    .multiselect__tag-icon_light:focus, .multiselect__tag-icon_light:hover {
        background: #aaaaaa !important;
    }

.d-flex-center-between {
  display:flex;
  justify-content: space-between;
  align-items: center;
}

</style>

