<template>
    <div v-if="error.productos.length">
        <div class="alert alert-warning">
            {{error.productos}}
        </div>
    </div>
    <div v-else>
        <input-carga v-if="!productos.length"></input-carga>
        <Select2 v-else class="w-100 input-error" :options="productos" :settings="{ settingOption: value, width: '100%', theme: 'classic', }" @select="obtenerSeleccion($event)" />
    </div>
</template>

<script>
import { apis } from "../../../.env";
import Select2 from "vue3-select2-component";
import axios from 'axios'
import InputCarga from '../InputCarga';
export default {
    components: { Select2, InputCarga },    
	name: 'SelectProducto',
    data() {
        return {
			productos:[],
            error:{
                productos:''
            }
        }
    },
    mounted(){
        axios
        .get(apis.productos)
        .then((productosRes) => {
            if(productosRes.status == 200 && productosRes.data){
                this.productos = Object.keys(productosRes.data).map((fireId) => {
                    // En el evento se emite con la propiedad nombre en lugar de text y fireId como id
                    return {
                        id: fireId,
                        text: productosRes.data[fireId].nombre,
                        detalle: productosRes.data[fireId].detalle,
                        imagen: (productosRes.data[fireId].imagen != undefined) ? productosRes.data[fireId].imagen : null,
                        modo_preparacion: (productosRes.data[fireId].modo_preparacion != undefined) ? productosRes.data[fireId].modo_preparacion : null,
                        info_nutricional: (productosRes.data[fireId].info_nutricional != undefined) ? productosRes.data[fireId].info_nutricional : null,
                        activo: (productosRes.data[fireId].activo != undefined) ? productosRes.data[fireId].activo : null,
                    };
                });
            }else if(!productosRes.data){
                this.error.productos = 'No existen productos cargados, cree productos nuevos';
            }
        })
        .catch(error => {
            if (error) {
                console.log(error);
                alert("Hubo un error al obtener los productos. Reintente o consulte a sistemas");
            }
        })
    },
    methods:{
        obtenerSeleccion(data){
            let dataSeleccionada = {
                fireId: data.id,
                nombre: data.text,
                detalle: data.detalle,
                imagen: data.imagen,
                modo_preparacion: data.modo_preparacion,
                info_nutricional: data.info_nutricional,
                activo: data.activo,
            }
            this.$emit('seleccion',dataSeleccionada);
        }
    }
}
</script>