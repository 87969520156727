<template>
    <div class="row" v-if="triggers.formulario">
        <div class="col-12"><h4 class="mb-3" ref="tituloDelCoso">Estás modificando un producto</h4></div>
        <div class="col">
            <form>
                <!-- Nombre y detalle -->
                <div class="form-row">
                    <div class="col-12 col-md-6 mb-3">
                        <!-- Input -->
                        <label for="input-nombre" class="form-label">Elegí un nombre</label> 
                        <input type="text" class="form-control" id="input-nombre" ref="inputNombre" v-model="producto.nombre" @input="errores.nombre=false" placeholder="Plato principal"/>
                        <div class="text-danger font-weight-bold mt-2" v-if="errores.nombre">× {{errores.nombreMensaje}}</div>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <!-- Input -->
                        <label for="input-nombre" class="form-label">Detalla el producto</label> 
                        <input type="text" class="form-control" id="input-nombre" v-model="producto.detalle" @input="errores.detalle=false" placeholder="Fideos con salsa bolognesa"/>
                        <div class="text-danger font-weight-bold mt-2" v-if="errores.detalle">× {{errores.detalleMensaje}}</div>
                    </div>
                </div>
                <!-- Precio e imagen -->
                <div class="form-row">
                    <!-- <div class="col-12 col-md-6 mb-2">
                        <label for="input-precio" class="form-label">Precio</label> 
                        <div class="form-row">
                            <div class="col-8">
                                <input type="text" class="form-control" id="input-precio" ref="inputPrecio" disabled v-model="producto.precio" placeholder="0.00"/>
                            </div>
                            <div class="col-4">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="checkPrecio" value="false" v-model="triggers.precio" @change="refrescarCheckbox('precio')">
                                    <label class="form-check-label ml-1" for="checkPrecio">Tiene precio</label>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- Imagen -->
                    <!-- <div class="col-12 col-md-6 mb-2"> -->
                    <div class="col-12">
                        <div class="form-row align-items-center">
                            <div class="col-3 text-right" v-if="(productoSeleccionado.imagen && producto.imagen == null) && !triggers.removerImagen">
                                <img :src="productoSeleccionado.imagen" style="height:80px; width:100%; border-radius:6px; object-fit:cover">
                                <button @click.prevent="triggers.removerImagen = true" class="btn btn-sm btn-danger" style="position:relative; top:-32px; right:5px">× Quitar</button>
                            </div>
                            <div class="col">
                                <!-- Input File -->
                                <label for="input-imagen-l" class="form-label">Agregá una imagen</label> 
                                <div class="input-group">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="input-imagen" ref="imagen" @change="selectImagen()">
                                        <label class="custom-file-label" for="input-imagen">Reemplazar imagen</label>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between my-2 align-items-center" v-if="triggers.imagen">
                                    <div class="text-success">{{triggers.imagenSelectMensaje}}</div>
                                    <div class="btn btn-danger btn-xs font-weight-bold" @click="removeImagen()">x</div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <!-- Informacion nutricional -->
                <div class="form-row mb-1">
                    <div class="col-12">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="" value="false" v-model="triggers.infoNutricional" @change="refrescarCheckbox('infoNutricional')">
                            <label class="form-check-label ml-1" for="">Información nutricional</label>
                        </div>
                        <textarea class="form-control mb-2" id="" style="height:60px" v-if="triggers.infoNutricional" v-model="producto.infoNutricional"></textarea>
                    </div>
                </div>
                <!-- Modo preparacion -->
                <div class="form-row mb-1">
                    <div class="col-12">
                        <div class="form-check form-check-inline">
                            <input 
                                v-model="triggers.modoPreparacion" 
                                @change="refrescarCheckbox('modoPreparacion')"
                                class="form-check-input" 
                                type="checkbox"
                                value="false" 
                            >
                            <label class="form-check-label ml-1">Modo de preparación</label>
                        </div>
                        <textarea class="form-control mb-2" id="" style="height:60px" v-if="triggers.modoPreparacion" v-model="producto.modoPreparacion"></textarea>
                    </div>
                </div>
                <!-- Activo y boton submit -->
                <div class="form-row align-items-center">
                    <div class="col-12 col-md-6">
                        <div class="form-row">
                            <div class="col">
                                <div class="form-check form-check-inline mb-3">
                                    <input class="form-check-input" type="checkbox" id="" value="true" v-model="producto.activo">
                                    <label class="form-check-label ml-2" for="">Producto activo</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <input type="submit" class="btn btn-md btn-success w-100" value="Modificar producto" @click.prevent="agregarProducto()"/>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row" v-if="triggers.cargando">
        <div class="col"></div>
        <div class="col-6 text-center">
            <h3>Preparando tu producto</h3>
            <h5 class="my-3"> producto.nombre </h5>
            <div class="alert alert-light text-left">{{producto.detalle}}</div>
            <div class="progress mt-2 mx-auto" style="height:10px; width: 100%">
                <div class="progress-bar" role="progressbar" ref="progressbar" style="width:25%"></div>
            </div>
        </div>
        <div class="col"></div>
    </div>
    <div class="row" v-if="triggers.guardado">
        <div class="col"></div>
        <div class="col-6 text-center">
            <img class="img-responsive" :src="imagenUrl" v-if="imagenUrl"/>
            <img class="img-responsive" :src="productoSeleccionado.imagen" v-if="productoSeleccionado.imagen"/>
            <h3 class="my-2 text-center">{{producto.nombre}}</h3>
            <span class="badge badge-success"><h5>✓ Producto modificado</h5></span>
        </div>
        <div class="col"></div>
    </div>
</template>
<script>
// import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";
import 'firebase/storage';

export default {   
	name: 'FormModificarProducto',
    props: ['productoSeleccionado'],
    data() {
        return {
            producto: {
                nombre: this.productoSeleccionado.nombre,
                detalle: this.productoSeleccionado.detalle,
                precio: null,
                imagen: null,
                infoNutricional:(this.productoSeleccionado.info_nutricional!=undefined)?this.productoSeleccionado.info_nutricional:null,
                modoPreparacion: (this.productoSeleccionado.modo_preparacion!=undefined)?this.productoSeleccionado.modo_preparacion:null,
                activo: this.productoSeleccionado.activo,
            },
            triggers:{
                precio: false,
                imagen: false,
                imagenSelectMensaje: null,
                infoNutricional: (this.productoSeleccionado.info_nutricional!=undefined)?true:false,
                modoPreparacion: (this.productoSeleccionado.modo_preparacion!=undefined)?true:false,
                formulario: true,
                cargando: false,
                guardado:false,
                removerImagen: false
            },
            errores: {
                nombre: false,
                nombreMensaje: null,
                detalle: false,
                detalleMensaje: null,
            },
            imagenUrl: null,
        }
    },
    methods: {
        refrescarFormulario(){
            this.producto = {
                nombre: null,
                detalle: null,
                precio: null,
                imagen: null,
                infoNutricional: null,
                modoPreparacion: null,
                activo: true,
            },
            this.triggers = {
                precio: false,
                imagen: false,
                imagenSelectMensaje: null,
                infoNutricional: false,
                modoPreparacion: false,
                formulario: true,
                cargando: false,
                guardado:false,
            }
            this.imagenUrl = null;
        },
        almacenarProducto(imgUrl){
            // let idEmpresa = sessionStorage.getItem("empresa");
            firebase.database().ref('productos/'+this.productoSeleccionado.fireId).update({
                nombre: this.producto.nombre,
                detalle: this.producto.detalle,
                precio: this.producto.precio,
                activo: this.producto.activo,
                info_nutricional: this.producto.infoNutricional,
                modo_preparacion: this.producto.modoPreparacion,
                imagen: (this.triggers.removerImagen || this.producto.imagen) ? imgUrl : this.productoSeleccionado.imagen,
            })
            .then(()=>{
                console.log("Producto agregado");
                this.triggers.cargando=false;
                this.triggers.guardado=true;
                setTimeout(() => {
                    this.$emit("producto-modificado", true)
                }, 1000);

            }).catch((error)=>{
                if(error!=null) {
                    console.log(error);
                    alert("Se produjo un error, comprobá el internet o llama a soporte");
                }
            })
        },
        agregarProducto(){
            if (this.datosInvalidos()) return null 
            // Cambio de stages
            this.triggers.formulario = false;
            this.triggers.cargando = true;
            // Subida de imágenes -> setea la url
            if (this.producto.imagen) {
                this.subirImagen();
            }else {
                this.almacenarProducto(null);
            }
        },
        subirImagen(){
            // File or Blob named mountains.jpg
            var file = this.producto.imagen;

            // Create the file metadata
            var metadata = {
                contentType: this.producto.imagen.type
            };

            var storageRef = firebase.storage().ref();

            // Upload file and metadata to the object 'images/mountains.jpg'
            var uploadTask = storageRef.child('productos/' + file.name).put(file, metadata);
            const vm = this;
            // Listen for state changes, errores, and completion of the upload.
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function(snapshot) {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                vm.$refs.progressbar.style.width = progress + '%';
                switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                }
            }, function(error) {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errores
                switch (error.code) {
                    case 'storage/unauthorized':
                    console.log("User doesn't have permission to access the object");
                    break;
                    case 'storage/canceled':
                    console.log("User canceled the upload");
                    break;
                    case 'storage/unknown':
                    console.log("Unknown error occurred, inspect error.serverResponse");
                    break;
                }
            }, function() {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                    vm.imagenUrl = downloadURL;
                    vm.almacenarProducto(downloadURL);
                    console.log('File available at', downloadURL);
                });
            });
        },
        datosInvalidos(){
            let errores = false;
            if (this.producto.nombre == null || !this.producto.nombre.length) {
                this.errores.nombreMensaje = "El nombre no puede estar vacio";
                this.errores.nombre = true;
                errores = true;
            }
            if (this.producto.detalle == null || !this.producto.detalle.length) {
                this.errores.detalleMensaje = "El detalle no puede estar vacio";
                this.errores.detalle = true;
                errores = true;
            }
            return errores;
        },
		refrescarCheckbox(dato){
            switch (dato) {
                case "precio":
                    this.$refs.inputPrecio.disabled = !this.$refs.inputPrecio.disabled;
                    if(!this.triggers.precio) this.producto.precio = null;
                    break;
                case "infoNutricional":
                    if(!this.triggers.infoNutricional) this.producto.infoNutricional = null;
                    break;
                case "modoPreparacion":
                    if(!this.triggers.modoPreparacion) this.producto.modoPreparacion = null;
                    break;
                default:
                    break;
            }
        },
        selectImagen(){
            this.producto.imagen = this.$refs.imagen.files[0];
            this.triggers.imagenSelectMensaje = "✓ Seleccionaste: " + this.$refs.imagen.files[0].name;
            this.triggers.imagen = true;

            console.log(this.producto.imagen.type);
        },
        removeImagen(){
            this.producto.imagen = null;
            this.triggers.imagenSelectMensaje = null;
            this.triggers.imagen = false;
        }
    },
	mounted() {
    },
}
</script>

<style scoped>
.form-control {
	display: inline-block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 1.75rem 0.375rem 0.75rem;
	font-size: .9rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	vertical-align: middle;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.textarea {
	height: auto;
}

.img-responsive {
    width: 80px;
    height: 80px;
    border-radius: 30%;
    object-fit: cover;
}
</style>