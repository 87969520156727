<template>
  <div class="page-content">
    <!-- Breadcrumb -->
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ breadcrumbB }}
        </li>
      </ol>
    </nav>
    <!-- Page title -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">{{ breadcrumbB }}</h6>
            <div class="d-flex align-items-center">
              <p class="card-description w-75 p-0 m-0">{{ descripcion }}</p>
              <button
                v-if="roles.editor"
                v-on:click="crearReceta()"
                type="button"
                class="btn w-25"
                :class="{
                  'btn-light': triggers.crearReceta,
                  'btn-success': !triggers.crearReceta,
                }"
              >
                {{
                  !triggers.crearReceta
                    ? "Crear una nueva receta"
                    : "Cancelar la creación"
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Buscador -->
    <buscador :recetas="recetas" v-if="triggers.buscador && !triggers.modificarReceta"></buscador>      
    <!-- Row crear receta -->
    <div class="row mb-3 mt-2" v-if="triggers.crearReceta && roles.editor">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <crear-receta
              :recetas="recetas"
              :recetasSalida="recetasSalida"
              @cerrar="cerrarActualizar($event)"
            ></crear-receta>
          </div>
        </div>
      </div>
    </div>
    <!-- Row modificar receta -->
    <div class="row mb-3 mt-2" v-if="roles.editor">
      <div class="col stretch-card" v-if="triggers.modificarReceta">
        <div class="card">
          <div class="card-body">
            <modificar-receta
              :key="keyModificar"
              :recetas="recetas"
              :receta="recetaModificable"
              :recetasSalida="recetasSalida"
              @cerrar="cerrarActualizar($event)"
            ></modificar-receta>
          </div>
        </div>
      </div>
    </div>
    <!-- Row lista de recetas -->
    <div class="row mb-3 mt-2" v-if="roles.editor">
      <div class="col stretch-card" v-if="triggers.listarRecetas && (!triggers.modificarReceta || !triggers.crearReceta)">
        <div class="card">
          <div class="card-body">
            <lista-recetas
              :recetas="recetas"
              @modificar-receta="seleccionarModificar($event)"
              :key="keyListado"
              @actualizar-lista="obtenerRecetas()"
            >
            </lista-recetas>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apis } from "../../.env";
import axios from "axios";

// import firebase from "firebase/app";
// import "firebase/database";

import ListaRecetas from "../components/Receta/listas/Recetas.vue";
import CrearReceta from "../components/Receta/forms/Crear.vue";
import ModificarReceta from "../components/Receta/forms/Modificar";
import Buscador from "../components/Receta/Buscador";

export default {
  name: "Recetas",
  components: { CrearReceta, ModificarReceta, ListaRecetas, Buscador},
  data() {
    return {
      roles:{
        editor:false,
        lector:false,
      },
      breadcrumbA: "Inicio",
      breadcrumbB: "Recetas",
      descripcion: "Agregá nuevas recetas y modifica las existentes",
      recetas: [],
      recetasSalida: [],
      recetaModificable: null,
      triggers: {
        crearReceta: false,
        listarRecetas: false,
        modificarReceta: false,
        buscador:true,
      },
      // Variables que actualizan los componentes
      keyListado: 0,
      keyModificar: 0,

      // Ingredientes:[],
      // normalizarIngredientes: [],
    };
  },


  methods: {
    obtenerRecetas() {
      this.listarRecetas = false;
      this.recetas = [];
      axios
        .get(apis.recetas)
        .then((recetasRes) => {
          Object.keys(recetasRes.data).forEach((e)=>{
            let receta = recetasRes.data[e];
            receta.fireId = e;
            if(receta.imagen!=undefined && receta.imagen != "https://firebasestorage.googleapis.com/v0/b/recetario-foodservice.appspot.com/o/recetas%2Fimagen.png?alt=media"){
              if(receta.codigo == '18510' || receta.codigo == '18509'){
                this.recetas.push(receta);
              }else {
                if(receta.entrada[0] != undefined && receta.entrada[0] == 'Postres'){
                  this.recetas.push(receta);
                }else {
                  this.recetas.unshift(receta);
                }
              }
            }else {
              this.recetas.push(receta);
            }
          });
          this.keyListado++;
          this.triggers.listarRecetas = true;
        })
        .catch((error) => {
          if (error) {
            console.log(error);
            alert("Se produjo un error al obtener las recetas");
          }
        });
    },
    seleccionarModificar(receta) {
      this.triggers.crearReceta = false;
      this.triggers.listarRecetas = false;
      this.recetaModificable = receta;
      this.triggers.modificarReceta = true;
      this.keyModificar++;
    },
    crearReceta() {
      this.triggers.modificarReceta = false;
      this.triggers.crearReceta = !this.triggers.crearReceta;
      this.triggers.listarRecetas = false;
    },
    cerrarActualizar(actualizacion){
      this.triggers.modificarReceta = false;
      this.triggers.crearReceta = false;
      if (actualizacion) {
        this.obtenerRecetas()  
      } else {
        this.triggers.listarRecetas = true;
      }
    },
  },
  mounted() {
      const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));
      if (userPermission) {
        userPermission.some((p)=>{
          if(p.ModulosAccess === 'Alimentacion'){
            this.roles.editor = p.Editor;
            this.roles.lector = p.Lector;
            return true;
          }
        })
      }

    this.obtenerRecetas();
  },
};
</script>

<style></style>
