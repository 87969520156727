<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-header align-items-center no-gutters" v-if="!success">
                            <div class="col-10">Modificar modalidad en empresa <div class="badge badge-warning">{{ empresa.nombre }}</div></div>
                            <div class="col-2 text-right">
                                <button type="button" class="btn btn-sm btn-light font-weight-bold" @click.prevent="close()"> x </button>
                            </div>
                        </div>
                        <div class="modal-body my-1" v-if="success">
                            <h4 class="success-title">✔ {{successMessage}}</h4>
                        </div>
                        <!-- Body -->
                        <div class="modal-body" v-if="!success">
                            <!-- Modalidad contador -->
                            <div class="" v-if="empresa.modalidad == 'contador'">
                                <h5 class="mb-4">Tipo de modalidad: Contador de comensales</h5>
                                <h5 class="mb-2">Indicá el rango de horarios</h5>
                                <div class="alert alert-info">Rango de horarios en los cuales funciona el contador de comensales</div>
                                <!-- inputs -->
                                <div class="form-row">
                                    <div class="col">
                                        <label class="form-label">Desde</label>
                                        <input type="time" class="form-control" v-model="empresaModificada.contador.desde"/>
                                    </div>
                                    <div class="col">
                                        <label class="form-label">Hasta</label>
                                        <input type="time" class="form-control" v-model="empresaModificada.contador.hasta"/>
                                    </div>
                                </div>
                                <div class="text-danger font-weight-bold mt-2" v-if="errores.contadorDesdeHasta">× Los dos campos son obligatorios, ingresa un periodo de tiempo válido, desde X hasta Y</div>
                            </div>
                            <!-- Seleccion y reserva de productos -->
                            <div class="" v-if="empresa.modalidad == 'productos'">
                                <h5 class="mb-4">Tipo de modalidad: Seleccion y reserva de productos</h5>
                                <div class="alert alert-info">
                                    Los empleados reservan su comida con una anticipación determinada, ademas es posible que la empresa maneje un stock de los productos disponibles.
                                </div>
                                <!-- Anticipacion y stock -->
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="false" v-model="empresaModificada.stock">
                                    <label class="form-check-label ml-1">La empresa maneja un stock de productos</label>
                                </div>
                                <!-- Dos columnas inputs -->
                                <h5 class="my-3">Anticipación de pedidos</h5>
                                <div class="form-row">
                                    <div class="col">
                                        <label class="form-label">Tipo</label>
                                        <select class="custom-select" v-model="empresaModificada.anticipacion.tipo" @change="empresaModificada.anticipacion.tiempo = 1">
                                            <option value="diaria">Diaria</option>
                                            <option value="semanal">Semanal</option>
                                            <option value="mensual">Mensual</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <label class="form-label">Tiempo</label>
                                        <!-- <input type="text" class="form-control" v-model="empresa.anticipacion.tiempo"/> -->
                                        <select class="custom-select" v-model="empresaModificada.anticipacion.tiempo">
                                            <option :value="option.tiempo" v-for="(option,i) in tiemposAnticipacion[empresaModificada.anticipacion.tipo]" :key="i">{{option.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- Cancelable -->
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="false" v-model="empresaModificada.anticipacion.cancelacion">
                                    <label class="form-check-label ml-1">El comensal puede cancelar y/o modificar pedidos</label>
                                </div>
                            </div>
                        </div>
                        <!-- Buttons footer -->
                        <div class="modal-footer" v-if="!success">
                            <button
                                type="button"
                                @click.prevent="close()"
                                class="btn btn-light"
                            >
                                Cancelar
                            </button>
                            <button
                                type="button"
                                @click.prevent="modificarModalidad()"
                                class="btn btn-success"
                            >
                                Modificar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
// import axios from 'axios';

export default {
    name: "ModalModificarModalidad",
    props: ['empresa'],
    data(){
        return {
            // Controla las actualizaciones del padre.
            seReasigno: false,
            success:false,
            successMessage: null,
            empresaModificada: {
                anticipacion: {
                    cancelacion: false,
                    tiempo: null,
                    tipo: null
                },
                contador: {
                    desde: null,
                    hasta: null,
                },
                stock: null,
            },
            errores:{},
            tiemposAnticipacion: {
                'diaria': [
                    {tiempo: 1, text: '1 día'},
                    {tiempo: 2, text: '2 días'},
                    {tiempo: 3, text: '3 días'},
                    {tiempo: 4, text: '4 días'},
                    {tiempo: 5, text: '5 días'},
                    {tiempo: 6, text: '6 días'},
                ],
                'semanal': [
                    {tiempo: 1, text: '1 semana'},
                    {tiempo: 2, text: '2 semanas'},
                    {tiempo: 3, text: '3 semanas'},
                ],
                'mensual': [
                    {tiempo: 1, 'text': '1 mes'}
                ]
            },
        }
    },
    mounted(){
        if (this.empresa.modalidad == 'productos') {
            this.empresaModificada.anticipacion = this.empresa.anticipacion;
        }else {
            this.empresaModificada.contador = this.empresa.contador;
        }
        this.empresaModificada.stock = this.empresa.stock;
    },
    methods: {
        modificarModalidad(){
            if (this.empresa.modalidad == 'productos') {
                firebase.database().ref('empresas/' + this.empresa.id_empresa).update({
                    anticipacion: this.empresaModificada.anticipacion,
                    stock: this.empresaModificada.stock
                }).then(()=>{
                    this.seReasigno = true;
                    this.successMessage = "La modalidad se modifico correctamente";
                    this.success = true;
                    setTimeout(() => {
                        this.close()
                    }, 1500);
                })
            }else {
                firebase.database().ref('empresas/' + this.empresa.id_empresa).update({
                    contador: this.empresaModificada.contador,
                }).then(()=>{
                    this.seReasigno = true;
                    this.successMessage = "La modalidad se modifico correctamente";
                    this.success = true;
                    setTimeout(() => {
                        this.close()
                    }, 1500);
                })
            }
        },
        close(){
            this.$emit('cerrar-modal-modalidad',this.seReasigno);
        },
        
    }
}
</script>

<style scoped>
.form-control {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.12rem 1.75rem 0.12rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}


.success-title {
    margin: 0;
    text-align: center;
    color: #1ea21e;
}

mark {
    background: none;
    text-decoration: underline red 3px;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 60%;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

    /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>