<template>
    <form>
        <h4 class="mb-3">Añadir nueva etiqueta</h4>
        <div class="form-row">
            <div class="col form-group">
                <label class="form-label" for="type">Tipo de etiqueta</label>
                <select class="custom-select" id="type" v-model="tag.type">
                    <option :value="null" disabled>Seleccione un tipo</option>
                    <option v-for="(type,i) in types" :key="i" :value="type.prop_name">{{type.label}}</option>
                </select>
                <div class="text-danger mt-1" v-if="error.type.length">&otimes; {{error.type}}</div>
            </div>
            <div class="col form-group">
                <label class="form-label" for="name">Descripción</label>
                <input type="text" class="form-control" id="name" v-model="tag.name">
                <div class="text-danger mt-1" v-if="error.name.length">&otimes; {{error.name}}</div>
            </div>
            <div class="col-12 d-flex justify-content-end align-items-center">
                <div class="btn btn-light mr-2" @click.prevent="$emit('cerrar',false)">Cancelar</div>
                <div class="btn btn-success" @click.prevent="submit">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/> <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/> </svg>
                    Crear etiqueta
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
// Firebase guarda cada instancia inicalizada en el array apps. La default queda en el indice 0 y el recetario en el 1
let fireRecetario = firebase.apps[1];
export default {
    name:'NewTag',
    props:{tipoEntradas:Object,tagCondicion:Object},
    data() {
        return {
            types:[
                // prop_name es el nombre que tienen en Firebase
                {label:'Entrada',prop_name:'tipo_entradas'},
                {label:'Condición',prop_name:'tag_condicion'}
            ],
            tag:{
                type:null,
                name:null,
            },
            error: {
                type:'',
                name:''
            }
        }
    },
    methods: {
        submit(event){
            // Se validan los inputs
             
            this.error.name = '';
            this.error.type = '';

            let valid = true;
            if(this.tag.name == null || !this.tag.name.length){
                this.error.name = 'El nombre es obligatorio';
                valid = false;
            }
            if(this.tag.type == null){
                this.error.type = 'Seleccione un tipo de etiqueta';
                valid = false;
            }

            // Si no son validos se corta el submit con errores
            if(!valid) return null;

            // Se valida la existencia
            if(this.tag.type == 'tipo_entradas'){
                // Se chequea entrada
                let exist = Object.keys(this.tipoEntradas).find((fId)=>{
                    return this.tipoEntradas[fId].nombre.trim().toLowerCase() == this.tag.name.trim().toLowerCase();
                });

                if(exist) {
                    this.error.name = 'El nombre ya existe';
                    return null;
                }
            }

            if(this.tag.type == 'tag_condicion'){
                // Se chequea condicion
                let exist = Object.keys(this.tagCondicion).find((fId)=>{
                    return this.tagCondicion[fId].nombre.trim().toLowerCase() == this.tag.name.trim().toLowerCase();
                });

                if(exist) {
                    this.error.name = 'El nombre ya existe';
                    return null;
                }
            }

            // Se actualiza la vista para mostrar es estado de carga
            event.target.innerHTML = `
                <div class="spinner-border" style="width:12px;height:12px" role="status">
                    <span class="sr-only">Loading...</span>
                </div> Creando
            `
            for (let input of document.querySelectorAll('form input,form select')) {
                input.disabled = true;
            }

            /**
             * Se sube la data
             */

            fireRecetario.database().ref(this.tag.type).push({
                nombre:this.tag.name
            }).then(()=>{
                this.$emit('cerrar',true);
            }).catch((err)=>{
                console.error(err);
                alert('se produjo un error al subir el tag')
            });

        }
    },


}
</script>

<style>

</style>