<template>
  <div class="page-content">
		<!-- Breadcrumb -->
		<nav class="page-breadcrumb" id="breadcrumb">
			<ol class="breadcrumb">
			<li class="breadcrumb-item">{{breadcrumbA}}</li>
			<li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
			</ol>
		</nav>
		<!-- Page title -->
		<div class="row">
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
				<h6 class="card-title">{{breadcrumbB}}</h6>
				<p class="card-description"> {{descripcion}}</p>
				</div>  
			</div>
			</div>
		</div> 
		<!-- Template content  -->
		<div class="row" v-if="modificarProducto.open">
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
					<form-modificar-producto
						:productoSeleccionado="modificarProducto.producto"
						@producto-modificado="productoModificado($event)"
					></form-modificar-producto>
				</div>
			</div>
			</div>
		</div>
		<!-- Template content  -->
		<div class="row">
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
                    <lista @modificar-producto="handleProductoModificar($event)" :key="keyListado"></lista>
				</div>
			</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import FormModificarProducto from '../components/Producto/FormModificarProducto.vue';
import Lista from '../components/Producto/Lista';
export default {
    name: 'ListaProductos',
    components:{Lista,FormModificarProducto},
    data() {
        return {
			breadcrumbA: 'Inicio',
            breadcrumbB: 'Listado de productos',
            descripcion: 'Agregá productos nuevos, modifica los existentes',
			modificarProducto: {
				open: false,
				producto: null
			},
			keyListado:0,
        }
    },
	methods: {
		handleProductoModificar(producto){
			// console.log(producto);
			
			
			this.modificarProducto.producto = null;
			this.modificarProducto.open = false;
			setTimeout(() => {
				this.modificarProducto.producto = producto;
				this.modificarProducto.open = true;
			}, 200);
		},
		productoModificado(modificado){
			if(modificado){
				this.modificarProducto.producto = null;
				this.modificarProducto.open = false;
				this.keyListado++;
			}
		}
	}
}
</script>

<style>

</style>