<template>
  <div class="page-content">
    <!-- Breadcrumb -->
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ breadcrumbB }}
        </li>
      </ol>
    </nav>
    <!-- Page title -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">{{ breadcrumbB }}</h6>
            <div class="d-flex align-items-center">
              <p class="card-description w-75 p-0 m-0">{{ descripcion }}</p>
              <button
                v-if="role.editor"
                v-on:click="handleEvent('create')"
                type="button"
                class="btn w-25"
                :class="{
                  'btn-light': trigger.create,
                  'btn-success': !trigger.create,
                }"
              >
                {{
                  !trigger.create
                    ? "Nueva etiqueta"
                    : "Cancelar la creación"
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Row crear receta -->
    <div class="row mb-3 mt-2" v-if="trigger.create && role.editor">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <new-tag
              :tipoEntradas="tipo_entradas"
              :tagCondicion="tag_condicion"
              @cerrar="refreshTriggers('list',$event)"
            ></new-tag>
          </div>
        </div>
      </div>
    </div>
    <!-- Row list -->
    <div class="row mb-3 mt-2" v-if="trigger.list && role.editor">
      <div class="col-12 col-md-6 stretch-card">
        <div class="card">
          <div class="card-body">
            <list-tags
              :tags="tipo_entradas"
              tipo="tipo_entradas"
              title="Etiquetas de entradas"
              @cerrar="refreshTriggers('list',$event)"
              @delete="handleDelete($event)"
              @update="handleUpdate($event)"
            ></list-tags>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 stretch-card">
        <div class="card">
          <div class="card-body">
            <list-tags
              :tags="tag_condicion"
              tipo="tag_condicion"
              title="Etiquetas de condición"
              @cerrar="refreshTriggers('list',$event)"
              @delete="handleDelete($event)"
              @update="handleUpdate($event)"
            ></list-tags>
          </div>
        </div>
      </div>
    </div>

    <eliminar-etiqueta
      :selected="selection"
      :recetasPorTag="recetasPorTag[selection.tipo][selection.nombre]"
      :recetasFull="recetas"
      :tags="this[selection.tipo]"
      v-if="trigger.delete"
      @end="refreshTriggers('list',$event)"
    >
    </eliminar-etiqueta>
    <editar-etiqueta
      :selected="selection"
      :recetasPorTag="recetasPorTag[selection.tipo][selection.nombre]"
      :recetasFull="recetas"
      :tags="this[selection.tipo]"
      v-if="trigger.update"
      @end="refreshTriggers('list',$event)"
    >
    </editar-etiqueta>
  </div>
</template>

<script>
import axios from 'axios';
import { apis } from "../../.env";
// import axios from "axios";

// import firebase from "firebase/app";
// import "firebase/database";

import ListTags from "../components/Receta/listas/ListTags.vue";
import NewTag from "../components/Receta/forms/NewTag.vue";
import EliminarEtiqueta from "../components/Receta/modals/EliminarEtiqueta.vue";
import EditarEtiqueta from "../components/Receta/modals/EditarEtiqueta.vue";
// import ModificarReceta from "../components/Receta/forms/Modificar";
// import Buscador from "../components/Receta/Buscador";

export default {
  name: "Etiquetas",
  components: { NewTag, ListTags,EliminarEtiqueta,EditarEtiqueta},
  data() {
    return {
      role:{
        editor:false,
        lector:false,
      },
      breadcrumbA: "Inicio",
      breadcrumbB: "Etiquetas",
      descripcion: "Gestioná las etiquetas que componen las recetas, añadí nuevas o modificá las existentes",
      tipo_entradas:[],
      tag_condicion:[],
      selection: null,
      recetas:null,
      recetasPorTag:null,
      trigger: {
        create: false,
        update: false,
        list: false,
        delete:false,
      },
    };
  },
  methods: {
    refreshTriggers(active,update) {
        Object.keys(this.trigger).forEach((t) => { this.trigger[t] = false });
        if(update){ this.getData(); }
        if(active!=undefined) this.trigger[active] = true
    },
    handleEvent(action,selection){
      this.selection = selection;
      this.refreshTriggers(action);
    },
    handleDelete(data){
      this.selection = data;
      this.trigger.delete = true;
    },
    handleUpdate(data){
      console.log('jelou', data);
      this.selection = data;
      this.trigger.update = true;
    },
    getData(){
      this.trigger.list = false;
      this.tipo_entradas = [];
      this.tag_condicion = [];

      axios.get(apis.recetasEntradas).then((response)=>{
        this.tipo_entradas = response.data;

        axios.get(apis.recetasCondiciones).then((response)=>{
          this.tag_condicion = response.data;

          this.trigger.list = true;
        })


      }).catch((error)=>{
        alert('Se produjo un error obteniendo los tipos y condiciones, actualice la página y reintente')
        console.error(error);
      })
    }
  },
  mounted() {
    const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));
    let permission = userPermission.find(record => record.ModulosAccess === "Alimentacion" || record.ModulosAccess === "AlimentacionEditor")
    this.role.editor = (permission!=undefined) ? permission.Editor : false;


    this.getData();

    this.recetasPorTag = {};
    this.recetasPorTag['tipo_entradas'] = {};
    this.recetasPorTag['tag_condicion'] = {};

    let entradas = {};
    let condiciones = {};
    axios.get(apis.recetas).then((response)=>{
      this.recetas = response.data;
      // console.log(response.data);
      Object.keys(response.data).forEach((fId)=>{
        // console.log(`id: ${fId} `, response.data[fId]);
        if(response.data[fId].entrada != undefined){
          // console.log('entra en if');
          response.data[fId].entrada.forEach((nombre)=>{
            if (nombre !== '') {
              if(entradas[nombre] === undefined) entradas[nombre] = [];
              // console.log('guardado en',this.recetas['condicion'][nombre]);
              entradas[nombre].push(fId);
            }else {
              if(entradas['sin-identificar'] === undefined) entradas['sin-identificar'] = [];
              entradas['sin-identificar'].push(fId);
            }
          })
        }

        if(response.data[fId].condicion != undefined){
          // console.log('entra en if');
          response.data[fId].condicion.forEach((nombre)=>{
            if (nombre !== '') {
              if(condiciones[nombre] === undefined) condiciones[nombre] = [];
              // console.log('guardado en',this.recetas['condicion'][nombre]);
              condiciones[nombre].push(fId);
            }else {
              if(condiciones['sin-identificar'] === undefined) condiciones['sin-identificar'] = [];
              condiciones['sin-identificar'].push(fId);
            }
          })
        }

        // if(response.data[fId].condicion != undefined){
        //   // console.log('entra en if');
        //   response.data[fId].condicion.forEach((nombre)=>{
        //     if(nombre == '') nombre = 'sin-identificar';
        //     if(this.recetas['condicion'][nombre] == undefined) this.recetas['condicion'][nombre] = [];

            
        //     console.log('guardado en',this.recetas['condicion'][nombre]);
            
        //     this.recetas['condicion'][nombre].push(fId);
        //   })
        // }
      });
      this.recetasPorTag['tipo_entradas'] = entradas;
      this.recetasPorTag['tag_condicion'] = condiciones;
    })
  },
};
</script>

<style></style>
