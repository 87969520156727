<template>
  <form v-if="triggers.edicion">
    <div class="card-label" ref="titleComponent">Crear nueva receta</div>
    <div class="form-row mb-3">
      <div class="col mb-2">
        <label class="form-label">Código de receta</label>
        <input
          type="number"
          class="form-control"
          v-model="recetaNueva.codigo" 
          placeholder="Ej: 1111"
          pattern="[0-9]+"
          @input="validarCodigo()"
        />
        <div class="text-danger mt-1" v-if="errores.codigo">&otimes; {{errores.codigoMensaje}}</div>
        <div class="text-success mt-1" v-if="triggers.codigoValido">&check; El código es válido</div>
      </div>
      <div class="col mb-2">
        <label class="form-label">Título</label>
        <input
          type="text"
          class="form-control"
          v-model="recetaNueva.titulo"
        />
        <div class="text-danger mt-1" v-if="errores.titulo">Por favor completa el título, es obligatorio</div>
      </div>
    </div>

    <!-- tags: entrada y condicion -->
    <div class="form-row mb-3">
      <div class="col mb-2">
        <label class="form-label">Tipo de entrada</label>
        <multiselect
          v-if="entradas.length"
          v-model="recetaNueva.entrada" 
          :options="entradas"
          :taggable="true"
          :createTag="false"
          :clearOnSelect="false"
          :hideSelected="true"
        ></multiselect>


        <!-- <Multiselect
          :key="keyEntradas"
          v-model="recetaNueva.entrada"
          mode="tags"
          :searchable="true"
          :createTag="true"
          :options="entradas"
        /> -->
        <div class="text-danger mt-1" v-if="errores.entrada">Completa el tipo de entrada, es obligatorio</div>
      </div>
      <div class="col mb-2">
        <label class="form-label">Tag de condición</label>
          <multiselect 
            v-if="condiciones.length"
            v-model="recetaNueva.condicion" 
            :options="condiciones"
            mode="tags"
            :multiple="true" 
            :taggable="true"
            :clearOnSelect="false"
            :hideSelected="true"
          ></multiselect>
        <!-- <Multiselect
          :key="keyCondicion"
          v-model="recetaNueva.condicion"
          mode="tags"
          :searchable="true"
          :createTag="true"
          :options="condiciones"
        /> -->
        <div class="text-danger mt-1" v-if="errores.condicion">Completa el tag de condicion, es obligatorio</div>
      </div>
    </div>
    <!-- Imagen e ingredientes -->
    <div class="form-row mb-3">
      <div class="col mb-2">
        <div class="form-row align-items-center">
          <!-- <div class="col-4 text-right" v-if="receta.imagen && recetaNueva.imagen == null && !triggers.removerImagen">
            <img :src="receta.imagen" style="height:80px; width:100%; border-radius:6px; object-fit:cover"/>
            <button @click.prevent="triggers.removerImagen = true" class="btn btn-sm btn-danger" style="position:relative; top:-32px; right:5px" > &otimes; Quitar </button>
          </div> -->
          <div class="col">
            <!-- Input File -->
            <label for="input-imagen-l" class="form-label"
              >Agregá una imagen</label
            >
            <div class="input-group">
              <div class="custom-file">
                <input
                  type="file"
                  class="custom-file-input"
                  id="input-imagen"
                  ref="imagen"
                  @change="selectImagen()"
                />
                <label class="custom-file-label" for="input-imagen"
                  >Reemplazar imagen</label
                >
              </div>
            </div>
            <div
              class="d-flex justify-content-between my-2 align-items-center"
              v-if="triggers.imagen"
            >
              <div class="text-success">{{ triggers.imagenSelectMensaje }}</div>
              <div
                class="btn btn-danger btn-xs font-weight-bold"
                @click="removeImagen()"
              >
                x
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col mb-2">
        <br v-if="receta.imagen && recetaNueva.imagen == null && !triggers.removerImagen">
        <label class="form-label">Ingredientes</label>
        
        <Multiselect
          :key="keyCondicion"
          v-model="recetaNueva.ingredientes"
          mode="tags"
          :searchable="true"
          :createTag="true"
          :options="ingredientes"
        />
        <div class="text-danger mt-1" v-if="errores.ingredientes">Los ingredientes son obligatorios</div>
      </div> -->
    </div>
     <!-- ingredientes -->
    <div class="form-row mb-3">
      <div class="col mb-2">
        <label class="form-label">Ingredientes</label>
        <multiselect 
          v-if="ingredientes.length"
          v-model="recetaNueva.ingredientes" 
          :options="ingredientes"
          mode="tags"
          :multiple="true" 
          :createTag="true"
          :taggable="true"
          :searchable="true"
          @tag="addTag"
          :clearOnSelect="false"
          :hideSelected="true"
        ></multiselect>



        <!-- <div class="bg-light rounded py-2">
          <div class="badge badge-success ml-2 mb-2" style="font-size:.8rem" v-for="ingrediente,i in recetaNueva.ingredientes" :key="i">
            {{ingrediente}}
          </div>
          <div class="ml-2" v-if="!recetaNueva.ingredientes.length">No existen ingredientes vinculados a esta receta</div>
        </div> -->
      </div>
    </div>
    <!-- procedimiento -->
    <div class="form-row mb-3">
      <div class="col mb-2">
        <label class="form-label">Procedimiento</label>
        <div class="text-danger mb-1" v-if="errores.procedimiento">El procedimiento es obligatorio, completalo</div>
        <textarea class="w-100 form-control" style="height:140px" v-model="recetaNueva.procedimiento"></textarea>
      </div>
    </div>
    <div class="form-row mb-3">
      <div class="col mb-2">
        <label class="form-label">Mosaico</label>
        <label for="mosaico" class="d-block mt-1"><input type="checkbox" id="mosaico" v-model="triggers.mosaico"> La receta es un mosaico que comprende varias recetas</label>
        
        <div v-if="triggers.mosaico">
          <label class="form-label">Seleccione las recetas del mosaico</label>
          
           <multiselect
            v-model="mosaicoRecetas" 
            :options="orderedRecetas"
            mode="tags"
            :multiple="true" 
            :createTag="false"
            :taggable="true"
            :searchable="true"
            :clearOnSelect="false"
            :hideSelected="true"
        ></multiselect>
          <!-- <multiselect

              v-model="mosaicoRecetas"
              :options="recetas"
              :trackBy="'codigo'"
              :label="'titulo'"
              :multiple="true"
              :taggable="false"
              :placeholder="'Selecciona recetas'"
              :selectLabel="'Pulsá enter para seleccionar'"
              :hideSelected="true"
              :closeOnSelect="false"
          ></multiselect> -->
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col text-right">
        <button class="btn btn-light mr-2" @click.prevent="$emit('cerrar',false)">Cancelar</button>
        <div class="btn btn-success" @click.prevent="modificarReceta()">Crear receta</div>
      </div>
    </div>
  </form>
  <div class="row stage" v-if="triggers.carga">
        <div class="col"></div>
        <div class="col-6 text-center">
            <h3>Preparando la receta</h3>
            <h5 class="my-3">{{recetaNueva.titulo}}</h5>
            <div class="progress mt-2 mx-auto" style="height:10px; width: 100%">
                <div class="progress-bar" role="progressbar" ref="progressbar" style="width:25%"></div>
            </div>
        </div>
        <div class="col"></div>
    </div>
    <div class="row stage" v-if="triggers.finalizado">
        <div class="col"></div>
        <div class="col-6 text-center">
            <img class="img-responsive" :src="recetaNueva.imagen">
            <h3 class="my-2 text-center">{{recetaNueva.titulo}}</h3>
            <h4 class="text-success">✓ Receta creada exitosamente</h4>
        </div>
        <div class="col"></div>
    </div>
</template>
<script>
import { apis } from "../../../../.env";
import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";
import Multiselect from '@vueform/multiselect'
require("@vueform/multiselect/themes/default.css");

// Firebase guarda cada instancia inicalizada en el array apps. Ladefault queda en el indice 0 y el recetario en el 1
let fireRecetario = firebase.apps[1];

export default {
  name: "ModificarReceta",
  props: ["recetas"],
  components: {Multiselect,},
  data() {
    return {
      recetaNueva: {
        codigo: null,
        titulo: null,
        entrada: [],
        condicion: [],
        imagen: null,
        ingredientes: [],
        procedimiento: null,
      },
      orderedRecetas:[],
      mosaicoRecetas:[],
      recetaSeleccionada:null,
      entradas: [],
      condiciones: [],
      ingredientes: [],
      ingredientesAdded:[],
      // recetasSalidaOptionsTitulo:[],
      // recetasSalidaOptionsCodigo:[],
      errores: {
          codigo: false,
          codigoMensaje: null,
          titulo: false,
          entrada: false,
          condicion: false,
          ingredientes: false,
          procedimiento: false,
      },
      triggers: {
        imagenSelectMensaje: false,
        imagen: false,
        codigoValido: false,
        removerImagen: false,
        edicion: true,
        carga: false,
        finalizado: false,
        cambiarReceta: false,
        mosaico:false,
      },
    };
  },
  methods: {
    addTag(tag){
      this.ingredientesAdded.push(tag);
    },
    validarCodigo(){
        this.errores.codigo = false;
        this.triggers.codigoValido = false;

        let existe = false;
        let nombreReceta = null;
        Object.keys(this.recetas).forEach((receta) => {
          // console.log(this.receta.fireId +'!='+ this.recetas[receta].fireId);
          if (this.recetas[receta].codigo == this.recetaNueva.codigo){
            existe = true;
            nombreReceta = this.recetas[receta].titulo;
            return null
          }
        })
        if (!existe) {
          this.triggers.codigoValido = true;
        }else {
          this.errores.codigoMensaje = `La receta: ${nombreReceta} ya posee el código ${this.recetaNueva.codigo}`;
          this.errores.codigo = true;
        }
    },
    selectImagen() {
      this.recetaNueva.imagen = this.$refs.imagen.files[0];
      this.triggers.imagenSelectMensaje =
        "✓ Seleccionaste: " + this.$refs.imagen.files[0].name;
      this.triggers.imagen = true;

      // console.log(this.recetaNueva.imagen.type);
    },
    removeImagen() {
      this.recetaNueva.imagen = null;
      this.triggers.imagenSelectMensaje = null;
      this.triggers.imagen = false;
    },
    transformarSaltosDeLinea(caracter){
      this.recetaNueva.procedimiento = (this.recetaNueva.procedimiento.replaceAll("<br>",caracter));
    },
    modificarReceta(){
      if (!this.datosValidos()) return null

      this.triggers.edicion = false;
      this.triggers.carga = true;

      this.transformarSaltosDeLinea('</br>');
            
      this.recetaNueva.ingredientes = this.recetaNueva.ingredientes.toString();
      this.recetaNueva.entrada = [this.recetaNueva.entrada];

      if(this.triggers.mosaico){
        let mosaico = this.mosaicoRecetas.map((m)=>m.split(' ')[0]);
        this.recetaNueva['mosaico'] = mosaico;
      }
      
      this.$nextTick(()=>{
        if (this.recetaNueva.imagen) {
          this.setearImagen().then(()=>{
            // console.log(`Se seteo la imagen: ${response.data}`);
            // Se hace el update de firebase con la recetaNueva
            
            fireRecetario.database().ref(`recetas`).push(this.recetaNueva)
            .then(()=>{
              // console.log('producto modificado');
              
              // Se procede a crear los nuevos ingredientes nuevos si los hay
            if (this.ingredientesAdded.length) {
              let promises = [];
              this.ingredientesAdded.forEach((label)=>{
                promises.push(fireRecetario.database().ref('ingredientes').push(label.toLowerCase()));
              });
  
              Promise.all(promises).then(()=>{
                this.triggers.carga = false;
                this.triggers.finalizado = true;
                setTimeout(() => {
                  this.$emit('cerrar',true);
                }, 1500);
                }).catch((e)=>{console.error(e);alert('error al crear un ingrediente')});
              
            }else {
              this.triggers.carga = false;
              this.triggers.finalizado = true;
              setTimeout(() => {
                this.$emit('cerrar',true);
              }, 1500);
            }

            })
            .catch(error=>{
              if (error) {
                console.log(error);
                alert("Se produjo un error al modificar el producto, actualice la página (tecla: F5), reintente o consulte a sistemas");
              }
            });
          });
          
        }else {
          this.recetaNueva.imagen = 'https://firebasestorage.googleapis.com/v0/b/recetario-foodservice.appspot.com/o/recetas%2Fimagen.png?alt=media';
          fireRecetario.database().ref(`recetas`).push(this.recetaNueva)
            .then(()=>{
              // console.log('producto modificado');
              this.triggers.carga = false;
              this.triggers.finalizado = true;
              setTimeout(() => {
                this.$emit('cerrar',true);
              }, 1500);
            })
            .catch(error=>{
              if (error) {
                console.log(error);
                alert("Se produjo un error al modificar el producto, actualice la página (tecla: F5), reintente o consulte a sistemas");
              }
            });
        }
      });
    },
    datosValidos(){
      // FIXME errores es un objecto con la finalidad de ser iterado con Object.keys
      // refactorear este código.
      let result = true;
      this.errores.titulo = false;
      this.errores.entrada = false;
      // this.errores.condicion = false;
      // this.errores.ingredientes = false;
      this.errores.procedimiento = false;

      if (this.errores.codigo) result = false

      if (!this.recetaNueva.titulo.length) {
        result = false
        this.errores.titulo = true
      }

      if (!this.recetaNueva.entrada.length) {
        result = false
        this.errores.entrada = true
      }

      // if (!this.recetaNueva.condicion.length) {
      //   result = false
      //   this.errores.condicion = true
      // }

      // if (!this.recetaNueva.ingredientes.length) {
      //   result = false
      //   this.errores.ingredientes = true
      // }

      if (!this.recetaNueva.procedimiento || !this.recetaNueva.procedimiento.length) {
        result = false
        this.errores.procedimiento = true
      }

      return result
    },
    setearImagen(){
      return new Promise((resolve,reject) => {
          // File or Blob named mountains.jpg
          var file = this.recetaNueva.imagen;
  
          // Create the file metadata
          var metadata = {
              contentType: this.recetaNueva.imagen.type
          };
  
          var storageRef = fireRecetario.storage().ref();
  
          // Upload file and metadata to the object 'images/mountains.jpg'
          let name = this.recetaNueva.titulo + "-" + this.recetaNueva.codigo + "-" + Math.floor(Math.random() * 1001);
          var uploadTask = storageRef.child('recetas/' + name).put(file, metadata);
          const vm = this;
          // Listen for state changes, errores, and completion of the upload.
          uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          function(snapshot) {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
              // Se aumenta la barra de progreso en cada cambio de estado de Firebase
              vm.$refs.progressbar.style.width = progress + '%';
              switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
          }, function(error) {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errores
              switch (error.code) {
                  case "storage/unauthorized":
                    console.log("User doesn't have permission to access the object");
                    reject("User doesn't have permission to access the object");
                    break;
                  case "storage/canceled":
                    console.log("User canceled the upload");
                    reject("User canceled the upload");
                    break;
                  case "storage/unknown":
                    console.log( "Unknown error occurred, inspect error.serverResponse" );
                    reject("Unknown error occurred, inspect error.serverResponse");
                    break;
              }
          }, function() {
              // Upload completed successfully, now we can get the download URL
              uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                  vm.recetaNueva.imagen = downloadURL;
                  // vm.almacenarProducto(downloadURL);
                  // console.log('File available at', downloadURL);
                  // Se resuelve la promesa con la url obtenida
                  // igualmente la imagen se sube a traves de la propiedad recetaNueva.imagen
                  resolve(downloadURL);
              });
          });
      })
    },
  },
  mounted() {
    // Se obtienen las entradas y condiciones
    axios
    .get(apis.recetasEntradas)
    .then((entradasResp) => {
      Object.keys(entradasResp.data).forEach((e)=>{
        // Se estructura en forma {value,label} como lo requiere el componente Multiselect
        this.entradas.push({
          value: entradasResp.data[e].nombre,
          label: entradasResp.data[e].nombre
        })
      })
    })
    axios
    .get(apis.recetasCondiciones)
    .then(condicionesResp => {
      Object.keys(condicionesResp.data).forEach((e)=>{
        // Se estructura en forma {value,label} como lo requiere el componente Multiselect
        this.condiciones.push({
          value: condicionesResp.data[e].nombre,
          label: condicionesResp.data[e].nombre
        })
      })
    })
    axios
    .get(apis.ingredientes)
    .then(ingredientes => {
      Object.keys(ingredientes.data).forEach((e)=>{
        // Se estructura en forma {value,label} como lo requiere el componente Multiselect
        // Esto es cualquiercosa ya que se pierde el ID de firebase. 
        // Pero como la data viene en forma de "azucar, harina". Se normaliza y se formatea al subir
        // FIXME Ingredientes y recetas
        this.ingredientes.push( ingredientes.data[e] )
      })
    })

    // Ordenar las recetas en array y añadir un label con código
    this.recetas.forEach((i)=>{
      this.orderedRecetas.push(`${i.codigo} ${i.titulo} (${(i.entrada)?i.entrada[0]:'Sin identificar'})`);
      // this.orderedRecetas.push({
      //   code:i.codigo,
      //   label:`${i.codigo} ${i.titulo} (${(i.entrada)?i.entrada[0]:'Sin identificar'})`
      // })
    })
    

    let to = this.$refs.titleComponent.offsetTop + 170;
    window.scroll({
      top: to,
      left: 0,
      behavior: "smooth",
    });
  },
};
</script>

<style scoped>
.multiselect-search input {
    width: 100%;
    border: 0;
    padding: 6px;
    outline: none;
    background: transparent;
    font-size: 14px;
    font-family: inherit;
    font-size: 1rem;
}

.multiselect-search {
    display: flex;
    height: 100%;
    width: 100%;
    background: transparent;
    border: 1px solid #222;
    border-radius: 3px;
}

.multiselect-clear {
  background: none;
}

.multiselect-clear::after, .multiselect-clear::before {
    background-color: #333333;
}
.multiselect-clear::after, .multiselect-clear::after {
    background-color: #333333;
}


.stage{
  padding: 4rem 0;
}

.card-label {
  font-size: large;
  margin-bottom: 1rem;
}

.img-responsive {
    width: 80px;
    height: 80px;
    border-radius: 30%;
    object-fit: cover;
}

.editable {
    width: 100%;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.form-control {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.textarea {
  height: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
