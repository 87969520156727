<template>
  <lista-carga v-if="load"></lista-carga>
  <div class="row" v-else>
    <div class="col">
      <div class="table-responsive">
        <table id="productos" class="table" width="100%">
          <thead>
            <tr>
                <!-- <th><input type="checkbox"> 0</th> -->
                <th></th>
                <th>Nombre</th>
                <!-- <th>Detalle</th> -->
                <!-- <th>Precio</th> -->
                <th>Info nutricional</th>
                <th>Modo preparación</th>
                <th>Activo</th>
                <th>Accion</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(producto, i) in productos" v-bind:key="i">
                <!-- <td><input type="checkbox" name="" id=""></td> -->
                <td>
                  <img class="img-icon" :src="producto.imagen" v-if="producto.imagen">
                  <img class="img-icon" v-else src="../../../public/assets/images/fs-preview-icon.jpg">
                </td>
                <td>{{ producto.nombre }}</td>
                <!-- <td><div class="texto-incompleto" @click="abrirModalVerMas(producto)">{{ producto.detalle }}</div></td> -->
                <!-- <td>{{ mostrarDataOGuion(producto.precio) }}</td> -->
                <td><div class="texto-incompleto badge badge-light" @click="abrirModalVerMas(producto)">{{ mostrarDataOGuion(producto.info_nutricional) }}</div></td>
                <td><div class="texto-incompleto badge badge-light" @click="abrirModalVerMas(producto)">{{ mostrarDataOGuion(producto.modo_preparacion) }}</div></td>
                <td>
                    <span
                    class="badge"
                    :class="{ 'badge-success': producto.activo, 'badge-light': !producto.activo}"
                    >{{ producto.activo ? "&nbsp;Activo&nbsp;" : "Inactivo" }}
                    </span>
                </td>
                <td>
                    <button
                    v-on:click="modificarProducto(producto)"
                    type="button"
                    class="btn btn-primary btn-xs "
                    data-toggle="modal"
                    data-target=".bd-example-modal-xl"
                    >
                    Modificar
                    </button>
                    <button
                      v-on:click="abrirModalEliminarProducto(producto)"
                      type="button"
                      class="btn btn-danger btn-xs ml-2"
                    >
                    Eliminar
                    </button>
                </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ModalListaVerMas 
    v-if="modalVerMas.open" 
    :producto="modalVerMas.producto"
    @cerrar-modal-ver-mas="modalVerMas.open = false"
  ></ModalListaVerMas>
  <modal-eliminar-producto
    v-if="modalEliminarProducto.open"
    :producto="modalEliminarProducto.producto"
    @cerrar-modal-eliminar="productoEliminado()"
  ></modal-eliminar-producto>
</template>
<script>
import axios from "axios";
// import firebase from "firebase/app";
// import "firebase/database";
import ListaCarga from "../ListaCarga";
import ModalListaVerMas from "./modal/ModalListaVerMas"
import ModalEliminarProducto from "./modal/ModalEliminarProducto";
import $ from "jquery";
require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();
import { apis, tableConfig } from "../../../.env";


export default {
  name: "ListaPorTurnos",
  components: { ListaCarga, ModalListaVerMas, ModalEliminarProducto, },
  data() {
    return {
      productos: [],
      load: true,
      modalVerMas: {
        open: false,
        producto: null
      },
      modalEliminarProducto: {
        open: false,
        producto: null
      },
    };
  },
  methods: {
    /**
     * Emite un evento al componente padre con el producto a modificar
     * y la operación a realizar
     * @param producto un producto con toda la información que lo conforma.
     */
    modificarProducto(producto){
      this.$emit('modificar-producto', producto);
    },
    /**
     * Si el string dado como parámetró no es null 
     * ni está vacio lo retorna, si no retorna "-"
     * @param data es un string a comprobar
     * @return el string o un guión medio "-"
     */
    mostrarDataOGuion(data){
      if (data!=null && data.length) { return data } else { return "-" }
    },
    abrirModalVerMas(producto){
      this.modalVerMas.producto = producto;
      this.modalVerMas.open = true;
    },
    abrirModalEliminarProducto(producto){
      this.modalEliminarProducto.producto = producto;
      this.modalEliminarProducto.open = true;
    },
    productoEliminado(seElimino){
      this.modalEliminarProducto.producto = null;
      this.modalEliminarProducto.open = false;
      if(seElimino){
        console.log("actualizar productos");
      }
    }
  },
  mounted() {
    axios
      .get(apis.productos)
      .then((responseProductos) => {
        if(responseProductos.status == 200 && responseProductos.data) {
          // console.log(responseProductos.data);
          this.productos = Object.keys(responseProductos.data).map((fireId) => {
            // console.log(fireId);
            let producto = responseProductos.data[fireId];
            producto.fireId = fireId;
            return producto
          });
          this.load = false;
        }else {
          this.load = false;
        }
      }).then(()=>{
        let config = tableConfig;
        config.pageLength = 10;
        $('#productos').dataTable(config);
      })
      .catch((error) => {
        console.log(error);
        alert("Se produjo un error");
      });
  },
};
</script>

<style scoped>
.form-control {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.textarea {
  height: auto;
}

.img-icon {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 30%;
}

.texto-incompleto {
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor:pointer
}
</style>
