<template>
  <div>
    <div class="form-row">
      <div class="col">
        <label class="form-label">Nombre del perfil</label>
        <input type="text" class="form-control" :class="{'invalid-form-control':errores.nombre}" v-model="nuevoPerfil.nombre" @change="sonValidosLosDatos()"/>
        <div class="text-danger mt-1" v-if="errores.nombre">No puede estar vacio</div>
      </div>
      <div class="col">
        <label class="form-label">Limite de platos</label>
        <input type="text" class="form-control" min="1" max="100" :class="{'invalid-form-control':errores.limite}" v-model="nuevoPerfil.limite" @change="sonValidosLosDatos()"/>
        <div class="text-danger mt-1" v-if="errores.limite">{{errores.limiteMensaje}}</div>
      </div>
      <div class="col">
        <label class="form-label">Porcentaje subvencionado</label>
        <div class="input-group">
            <input type="text" class="form-control" min="0" max="100" :class="{'invalid-form-control':errores.subvencion}" v-model="nuevoPerfil.subvencion" @change="sonValidosLosDatos()"/>
            <div class="input-group-append">
                <span class="input-group-text">%</span>
            </div>
        </div>
        <div class="text-danger mt-1" v-if="errores.subvencion">{{errores.subvencionMensaje}}</div>
      </div>
      <div class="col-2">
        <label class="form-label">&nbsp;</label><br />
        <button class="btn btn-success w-100" @click.prevent="agregarPerfil()">
          ✓ Agregar
        </button>
      </div>
    </div>
    <div class="form-row mt-3" v-if="perfiles.length">
      <div class="col">
        <label class="form-label">Nombre del perfil</label>
      </div>
      <div class="col">
        <label class="form-label">Limite de platos</label>
      </div>
      <div class="col">
        <label class="form-label">Porcentaje (%) subvencionado</label>
      </div>
      <div class="col-2"></div>
    </div>
    <div class="form-row mb-2" v-for="(perfil, i) in perfiles" :key="i">
      <div class="col">
        <input type="text" class="form-control" :class="{'invalid-form-control':perfil.nombre.length == 0}" v-model="perfil.nombre" @change="validarEmiteDatosModificados(perfil)"/>
        <div class="text-danger mt-1 w-100" v-if="perfil.errores.nombre">El nombre es obligatorio</div>
      </div>
      <div class="col">
        <input type="text" class="form-control" :class="{'invalid-form-control':perfil.limite <= 0}" v-model="perfil.limite" @change="validarEmiteDatosModificados(perfil)"/>
        <div class="text-danger mt-1 w-100" v-if="perfil.errores.limite">El limite debe ser mayor a 0</div>
      </div>
      <div class="col">
        <div class="input-group">
            <input type="text" class="form-control" :class="{'invalid-form-control':perfil.subvencion < 0 || perfil.subvencion > 100}" v-model="perfil.subvencion" @change="validarEmiteDatosModificados(perfil)"/>
            <div class="input-group-append">
                <span class="input-group-text">%</span>
            </div>
            <div class="text-danger mt-1 w-100" v-if="perfil.errores.subvencion">La subvencion debe estar entre 0 y 100%</div>
        </div>
      </div>
      <div class="col-2">
        <button class="btn btn-danger w-100" @click.prevent="quitarPerfil(i)">× Quitar</button>
      </div>
    </div>
    <div class="row mt-2" v-if="errores.perfilesIncompletos">
      <div class="col">
        <div class="alert alert-warning">Atención: los perfiles con campos marcados en rojo no se crearán</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormPerfiles",
  props: ['perfilesExistentes'],
  data() {
    return {
      nuevoPerfil: {
        nombre: '',
        limite: "1",
        subvencion: "0",
      },
      perfiles: [],
      errores: {
        nombre: false,
        limite: false,
        subvencion: false,
        perfilesIncompletos: false,
      }
    };
  },
  mounted() {
    if (this.perfilesExistentes) {
      this.perfiles = Object.keys(this.perfilesExistentes).map((p) => {
        let perfil = this.perfilesExistentes[p];
        perfil.errores = {
          limite:false,
          nombre:false,
          subvencion:false
        };
        return perfil
      });
    }
  },
  methods: {
    agregarPerfil() {
        if(this.sonValidosLosDatos()) return null
        this.perfiles.push({
            nombre: this.nuevoPerfil.nombre,
            limite: this.nuevoPerfil.limite,
            subvencion: this.nuevoPerfil.subvencion,
            errores: {nombre:false,limite:false,subvencion:false}
        });
        this.nuevoPerfil.nombre = "";
        this.nuevoPerfil.limite = "1";
        this.nuevoPerfil.subvencion = "0";
        this.emitirData();
    },
    quitarPerfil(i){
        this.perfiles.splice(i, 1);
        this.emitirData();
    },
    /**
     * Se emiten solo los perfiles con datos válidos
     */
    emitirData(){
      let perfiles = Object.keys(this.perfiles).flatMap(p => {
          if(this.perfiles[p].errores.nombre || this.perfiles[p].errores.limite || this.perfiles[p].errores.subvencion) return []
          return {
            nombre: this.perfiles[p].nombre,
            limite: this.perfiles[p].limite,
            subvencion: this.perfiles[p].subvencion
          }
      })
      this.$emit('perfiles-configurados',perfiles);
    },
    sonValidosLosDatos(){
      this.limpiarErrores();
      let error = false;

      if(!this.nuevoPerfil.nombre.length){
        this.errores.nombre = true;
        error = true;
      }

      if(!this.nuevoPerfil.limite.length){
        this.errores.limite = true;
        this.errores.limiteMensaje = 'No puede estar vacio';
        error = true;
      }else if(this.nuevoPerfil.limite < 1){
        this.errores.limite = true;
        this.errores.limiteMensaje = 'Debe ser mayor a cero';
        error = true;
      }

      if (!this.nuevoPerfil.subvencion.length) {
        this.errores.subvencion = true;
        this.errores.subvencionMensaje = 'No puede estar vacio';
        error = true;
      } else if(this.nuevoPerfil.subvencion < 0) {
        this.errores.subvencion = true;
        this.errores.subvencionMensaje = 'No puede ser negativo';
        error = true;
      } else if(this.nuevoPerfil.subvencion > 100) {
        this.errores.subvencion = true;
        this.errores.subvencionMensaje = 'No puede ser mayor a 100';
        error = true;
      }

      return error

    },
    validarEmiteDatosModificados(perfil){
      this.errores.perfilesIncompletos = false;
      perfil.errores.nombre = false;
      perfil.errores.limite = false;
      perfil.errores.subvencion = false;

      let error = false;

      if(!perfil.nombre.length){
        perfil.errores.nombre = true;
        error = true;
      }

      if(!perfil.limite.length){
        perfil.errores.limite = true;
        error = true;
      }else if(perfil.limite < 1){
        perfil.errores.limite = true;
        error = true;
      }

      if (!perfil.subvencion.length || perfil.subvencion < 0 || perfil.subvencion > 100) {
        perfil.errores.subvencion = true;
        error = true;
      }


      if (error) this.errores.perfilesIncompletos = true
      this.emitirData();
        
      
      return error

    },

    
    limpiarErrores(){
      this.errores.nombre = false;
      this.errores.limite = false;
      this.errores.subvencion = false;
    }
  },
};
</script>

<style scoped>
.input-group-text {
    border: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0;
    background: #ced4da;
    color: #495057;
}

.invalid-form-control {
    border-color: red;
}
</style>
