<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-header align-items-center no-gutters" v-if="!success && !deletePrompt">
                            <div class="col-10">Administración de unidad <div class="badge badge-warning">{{ unidad.nombre }}</div></div>
                            <div class="col-2 text-right">
                                <button type="button" class="btn btn-sm btn-light font-weight-bold" @click.prevent="close()"> x </button>
                            </div>
                        </div>
                        <div class="modal-body my-1" v-if="success">
                            <h4>✔ {{successMessage}}</h4>
                        </div>
                        <div class="modal-body" v-if="deletePrompt">
                            <div class="bg-light p-3 rounded">
                                Estas a punto de eliminar la unidad <mark>{{unidad.nombre}}</mark>, existen {{unidad.empresas}} empresas con esta unidad.
                            </div>
                            <div class="text-right mt-3">
                                <button
                                    type="button"
                                    @click.prevent="deletePrompt = false"
                                    class="btn btn-light mr-2"
                                >
                                    Cancelar
                                </button>
                                <button
                                type="button"
                                @click.prevent="removerUnidad()"
                                class="btn btn-danger"
                                >
                                Eliminar unidad
                            </button>
                            </div>
                        </div>
                        <div class="modal-body" v-if="!success && !deletePrompt">
                            <label for="form-label">Nombre nuevo</label>
                            <div class="form-row align-items-center">
                                <div class="col">
                                    <input type="text" class="form-control py-1" v-model="nuevoNombre" @input="errores.nombre=false" @keyup.enter="modificarNombre()" placeholder="Ingresa un nombre nuevo para esta unidad"/>
                                    <div class="text-danger font-weight mt-1" v-if="errores.nombre">× {{errores.nombre}}</div>
                                </div>
                            </div>
                        </div>
                        <!-- Buttons footer -->
                        <div class="modal-footer" v-if="!success && !deletePrompt">
                            <button
                                type="button"
                                @click.prevent="deletePrompt = true"
                                class="btn btn-danger"
                            >
                                Eliminar unidad
                            </button>
                            <button
                                type="button"
                                @click.prevent="modificarNombre()"
                                class="btn btn-success"
                            >
                                Modificar nombre
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
import axios from 'axios';

export default {
    name: "ModalAdministrarUnidad",
    props: ['unidad'],
    data(){
        return {
            // Controla las actualizaciones del padre.
            seReasigno: false,
            nuevoNombre: null,
            errores:{nombre:false},
            success:false,
            successMessage: null,
            deletePrompt: false,
        }
    },
    mounted(){
        
    },
    methods: {
        removerUnidad(){
            firebase.database().ref("unidades_negocio/"+this.unidad.fireId).remove()
            .then(()=>{
                this.successMessage = "Unidad de negocio removida correctamente";
                this.success = true;
                this.deletePrompt = false;
                this.seReasigno = true;
                setTimeout(() => {
                    this.close()
                }, 2000);
            }).catch(error=>{
                if(error){
                    alert("Se produjo un error, intentá nuevamente o comunicate con sistemas")
                    console.log(error);
                }
            })
        },
        modificarNombre(){
            if (this.nuevoNombre == null || !this.nuevoNombre.length) {
                this.errores.nombre = "El nombre no puede estar vacio.";
                return null;
            }

            axios
            .get('https://fs-comedores-admin-default-rtdb.firebaseio.com/unidades_negocio.json?orderBy="nombre"+&equalTo="'+this.nuevoNombre+'"')
            .then(resultado=>{
                if(Object.keys(resultado.data).length) {
                    this.errores.nombre = "Ya existe una unidad con el nombre ingresado.";
                    return null;
                }else {
                    firebase.database().ref("unidades_negocio/"+this.unidad.fireId).update({
                        nombre: this.nuevoNombre
                    })
                    .then(()=>{
                        this.successMessage = "Nombre asignado correctamente";
                        this.success = true;
                        this.seReasigno = true;
                        setTimeout(() => {
                            this.close()
                        }, 2000);
                    }).catch(error=>{
                        if(error){
                            alert("Se produjo un error, intentá nuevamente o comunicate con sistemas")
                            console.log(error);
                        }
                    })
                }
            })
        },
        close(){
            this.$emit('cerrar-modal-unidad',this.seReasigno);
        },
        
    }
}
</script>

<style scoped>
.form-control {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
padding: 0.12rem 1.75rem 0.12rem 0.75rem;
font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}


h4 {
    margin: 0;
    text-align: center;
    color: #1ea21e;
}

mark {
    background: none;
    text-decoration: underline red 3px;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 60%;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

    /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>