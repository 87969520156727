<template>
  <lista-carga v-if="load" :card="false"></lista-carga>
  <div class="row" v-else>
    <div class="col">
      <label class="tabla-label">Lista de platos</label>
      <div class="table-responsive">
        <table id="table" class="table" width="100%">
          <thead>
            <tr>
              <th></th>
              <th>Nombre</th>
              <th>URL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(plato, i) in platos" v-bind:key="i">
              <td><img :src="recetas[plato.image].imagen" style="width:32px;height:32px;object-fit:cover" class="rounded"></td>
              <td>{{plato.name}}</td>
              <td>{{`https://healthy.foodservice.com.ar/dish/${i}`}}</td>
              
              <!-- <td class="text-right">
                <button
                  type="button"
                  class="btn btn-success btn-sm"
                  @click="handleAction('update',tag,i)"
                >
                  Modificar
                </button>
                <button
                  type="button"
                  class="btn btn-danger btn-sm ml-3"
                  @click="handleAction('delete',tag,i)"
                >
                  Eliminar
                </button>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- <modal-eliminar-receta
    v-if="modalEliminarReceta.open"
    :receta="modalEliminarReceta.receta"
    @cerrar-eliminar="cerrarModal($event)"
  ></modal-eliminar-receta> -->
</template>
<script>
// import axios from "axios";
// import firebase from "firebase/app";
// import "firebase/database";
import ListaCarga from "@/components/ListaCarga";
import $ from "jquery";
import { tableConfig } from "../../../.env";
// import ModalEliminarReceta from '../modals/EliminarReceta'

export default {
  name: "ListPlato",
  props: ["recetas",'categories','platos'],
  components: { ListaCarga },
  data() {
    return {
      load: true,
      // tableId:null,
      // modalEliminarReceta: {
      //   open: false,
      //   receta: null,
      // }
    };
  },
  methods: {
    handleAction(action,tag,id) {
      this.$emit(action, {
        tipo:this.tipo,
        nombre:tag.nombre,
        id:id
      });
    },
    
  },
  mounted() {
    if (this.platos) {
      // this.tableId = 'id' + parseInt(Math.random()*100);
      setTimeout(() => {
        this.load = false;
        let config = tableConfig;
        config.pageLength = 8;
        this.$nextTick(() => {
          $(`#table`).dataTable(config);
        });
      }, 200);
    }
  },
};
</script>

<style scoped>
.tabla-label {
  position: absolute;
  top: 0;
  font-size: large;
  margin-bottom: 0;
}

.img-icon {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 30%;
}
</style>
