<template>
    <input-carga v-if="!empresas.length"></input-carga>
    <Select2 v-else class="w-100 input-error" :options="empresas" :settings="{ settingOption: value, width: '100%', theme: 'classic', }" @select="obtenerSeleccion($event)" />
</template>

<script>
// import { apis } from "../../../.env";
// import axios from 'axios'
import Select2 from "vue3-select2-component";
import InputCarga from '../InputCarga';
export default {
    components: { Select2, InputCarga },    
	name: 'SelectEmpresa',
    props: ['empresas','hasError'],
    data() {
        return {
			// empresas:[]
        }
    },
    mounted(){
        // axios.get(apis.empresasConsumos)
        // .then(empresas=>{
        //     this.empresas = empresas.data.flatMap(empresa=>{
        //         if (empresa.estado == "INACTIVA") return []
        //         return {
        //             id: empresa.id,
        //             text: empresa.name,
        //             catering_id: empresa.catering_id,
        //             centroapropiacion_id: empresa.centroapropiacion_id,
        //             tipo_empresa: empresa.tipo_empresa,
        //         }

        //     })
        // })
    },
    methods:{
        obtenerSeleccion(data){
            let dataSeleccionada = {
                id_empresa: data.id,
                nombre: data.text,
                catering_id: data.catering_id,
                centroapropiacion_id: data.centroapropiacion_id,
                tipo_empresa: data.tipo_empresa,
            }
            this.$emit('seleccion',dataSeleccionada);
        }
    }
}
</script>

<style scoped>
.has-error {border:1px solid rgb(185, 74, 72) !important;}
</style>