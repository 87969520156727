<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-body my-1" v-if="success">
                            <h4>✔ La receta se elimino correctamente</h4>
                        </div>
                        <div class="modal-body" v-if="deletePrompt">
                            <h5 class="mb-2">¡Cuidado esta acción no se puede deshacer!</h5>
                            <div class="bg-light p-3 rounded">
                                Estas a punto de eliminar la receta <mark>{{receta.titulo}}</mark> con el código <mark>{{receta.codigo}}</mark>
                            </div>
                            <div class="text-right mt-3">
                                <button
                                    type="button"
                                    @click.prevent="close(false)"
                                    class="btn btn-light mr-2"
                                >
                                    Cancelar
                                </button>
                                <button
                                type="button"
                                @click.prevent="removerReceta()"
                                class="btn btn-danger"
                                >
                                Eliminar receta
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
// import axios from 'axios';

let fireRecetario = firebase.apps[1];

export default {
    name: "EliminarReceta",
    props: ['receta'],
    data(){
        return {
            deletePrompt: true,
            success:false,
        }
    },
    mounted(){
        
    },
    methods: {
        removerReceta(){
            fireRecetario.database().ref("recetas/"+this.receta.fireId).remove()
            .then(()=>{
                this.deletePrompt = false;
                this.success = true;
                setTimeout(() => {
                    this.close(true)
                }, 2000);
            }).catch(error=>{
                if(error){
                    console.log(error);
                    alert("Se produjo un error, actualiza la página (tecla: F5), intentá nuevamente o comunicate con sistemas")
                }
            })
        },
        close(actualizacion){
            this.$emit('cerrar-eliminar',actualizacion);
        },
        
    }
}
</script>

<style scoped>
h4 {
    margin: 0;
    text-align: center;
    color: #1ea21e;
}

mark {
    background: none;
    text-decoration: underline red 3px;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 60%;
    margin: 0px auto;
    padding: 6px 20px 1px 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

    /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>