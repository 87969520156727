<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container py-1">
                        <div class="modal-body my-1" v-if="success">
                            <h4>✔ Producto eliminado correctamente</h4>
                        </div>
                        <div class="modal-body mb-0" v-if="!success">
                            <div class="bg-light p-3 rounded">
                                <h5 class="mb-2">Estas a punto de eliminar el producto <div class="badge badge-danger">{{producto.nombre}}</div></h5>
                                
                                Existen <div class="badge badge-danger">{{contadorUnidades}}</div> unidades y <div class="badge badge-danger">{{contadorEmpresas}}</div> empresas con este producto.
                            </div>
                        </div>
                        <!-- Buttons footer -->
                        <div class="modal-footer border-top-0" v-if="!success">
                            <button
                                    type="button"
                                    @click.prevent="close()"
                                    class="btn btn-light mr-2"
                                >
                                    Cancelar
                                </button>
                                <button
                                type="button"
                                @click.prevent="removerProducto()"
                                class="btn btn-danger"
                                >
                                Eliminar producto
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
import { apis } from "../../../../.env.js";
import axios from 'axios';

export default {
    name: "ModalEliminarProducto",
    props: ['producto'],
    data(){
        return {
            // Controla las actualizaciones del padre.
            seReasigno: false,
            errores:{nombre:false},
            success:false,
            referencias: [],
            contadorUnidades:0,
            contadorEmpresas:0,
        }
    },
    mounted(){
        // var keyUnidadesConElProducto = [];
        var referencias = [];
        var contadorUnidades = 0;
        var contadorEmpresas = 0;
        
        axios.get(apis.unidadesDeNegocio).then((response) => {
            Object.keys(response.data).forEach((i) => {
                let unidad = response.data[i];
                if (unidad.productos != undefined) {
                    Object.keys(unidad.productos).forEach((productoIdEnUnidad)=>{
                        // Si en la unidad existe el producto
                        if (unidad.productos[productoIdEnUnidad].producto_id == this.producto.fireId) {
                            // guardamos la referencia /unidades/-45sd8f74/productos/-5R6g56d58o
                            referencias.push(`unidades_negocio/${i}/productos/${productoIdEnUnidad}`);
                            contadorUnidades++;
                            return null
                        }
                    })
                }
            })
        }).then(()=>{
            this.contadorUnidades = contadorUnidades;
            axios.get(apis.empresas).then((responseE) => {
                Object.keys(responseE.data).forEach((e) => {
                    let empresa = responseE.data[e];
                    let unidades = empresa.unidades;
                    let tieneElProducto = false;
                    if (unidades!=undefined) {
                        Object.keys(unidades).forEach((idUnidad) => {
                            if (unidades[idUnidad].productos != undefined) {
                                Object.keys(unidades[idUnidad].productos).forEach((productoIdEnUnidad) => {
                                    // console.log(unidades[idUnidad].productos[productoIdEnUnidad].producto_id +"=="+ this.producto.fireId);
                                    if (unidades[idUnidad].productos[productoIdEnUnidad].producto_id == this.producto.fireId) {
                                        // let productoId = unidades[idUnidad].productos[productoIdEnUnidad].producto_id;
                                        tieneElProducto = true;
                                        referencias.push('empresas/'+e+'/unidades/'+ idUnidad +'/productos/'+ productoIdEnUnidad);
                                        console.log(referencias);
                                        return null
                                    }
                                })
                                contadorEmpresas += (tieneElProducto) ? 1 : 0;
                                this.contadorEmpresas = contadorEmpresas;
                            }
                        })
                    }
                })
            })
        }).then(()=>{
            this.contadorEmpresas = contadorEmpresas;
        })
        
        // var unidadesRef = firebase.database().ref('unidades_negocio');
        // unidadesRef.on("child_added", (snapshot) =>{
        //     // console.log("unidades:");
        //     // console.log("key:" + snapshot.key);
        //     // console.log(snapshot.val());

        //     // Recorrer los productos

        //     // setTimeout(() => {
        //         const unidad = snapshot.val()
        //         if (unidad.productos != undefined) {
        //             console.log(unidad.productos);
        //             Object.keys(unidad.productos).forEach((productoIdEnUnidad) => {
        //                 // Si en la unidad existe el producto
        //                 if (unidad.productos[productoIdEnUnidad].producto_id == this.producto.fireId) {
        //                     // guardamos la ruta /unidades/-45874/productos/-565658
        //                     referencias.push('unidades_negocio/'+snapshot.key+'/productos/'+productoIdEnUnidad);
        //                     // Guardar la de la unidad para eliminar luego en empresas
        //                     // keyUnidadesConElProducto.push(snapshot.key);
        //                     contadorUnidades++;
        //                     return null
        //                 }
                        
        //             });
        //         }
        //     // }, 300);

        // })
        // console.log(keyUnidadesConElProducto);

        // var contadorEmpresas = 0;
        // var empresasRef = firebase.database().ref('empresas');
        
        // empresasRef.on("child_added", (snapshot) => {
        //     // setTimeout(() => {
        //         let empresaId = snapshot.key;
        //         // Se puede enriquecer la información con data de la empresa que tiene el producto
        //         // console.log(empresaId);
        //         let unidades = snapshot.val().unidades;
        //         let tieneElProducto = false;
    
        //         // if (unidades!=undefined) {
        //             Object.keys(unidades).forEach((idUnidad) => {
        //                 if (unidades[idUnidad].productos != undefined) {
        //                     Object.keys(unidades[idUnidad].productos).forEach((productoIdEnUnidad) => {
        //                         if (unidades[idUnidad].productos[productoIdEnUnidad].producto_id == this.producto.fireId) {
        //                             tieneElProducto = true;
        //                             referencias.push('empresas/'+empresaId+'/unidades/'+idUnidad+'/productos/'+productoIdEnUnidad);
        //                             return null
        //                         }
        //                     })
        //                 }
        //             })
        //         // }
        //         contadorEmpresas += (tieneElProducto) ? 1 : 0;
        //     // }, 300);
        // })
        // console.log(`Hay ${contadorEmpresas} empresas con el producto`);
        // console.log(`Hay ${contadorUnidades} unidades con el producto`);
        // this.contadorUnidades = contadorUnidades;
        // this.contadorEmpresas = contadorEmpresas;
        this.referencias = referencias;
        this.referencias.push(`productos/${this.producto.fireId}`);
    },
    methods: {
        removerProducto(){
            this.referencias.forEach((url) => {
                firebase.database().ref(url).remove();
            });
            this.success = true;
            this.seReasigno = true;
            setTimeout(() => {
                this.close();
            }, 1500);
        },
        close(){
            this.$emit('cerrar-modal-eliminar',this.seReasigno);
        },
        
    }
}
</script>

<style scoped>
.form-control {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
    padding: 0.12rem 1.75rem 0.12rem 0.75rem;
    font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}


h4 {
    margin: 0;
    text-align: center;
    color: #1ea21e;
}

mark {
    background: none;
    text-decoration: underline red 3px;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 60%;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

    /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>