<template>
    <div class="row" v-if="!trigger.show">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h4 class="mb-1">Búsqueda de recetas</h4>
            <div class="form-row">
              <div class="col-12 col-sm-9 col-md-10  mb-2">
                <input-carga v-if="!recetas.length"></input-carga>
                <input
                  v-else
                  type="text"
                  class="form-control w-100"
                  v-model="query"
                  placeholder="Busque recetas por nombre, código o tipo de plato"
                  @keyup.enter="search()"
                  @input="startSearch()"
                />
              </div>
              <div class="col-12 col-sm-3 col-md-2 mb-2">
                <button class="btn btn-success w-100" @click="search()">
                  Buscar
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                </button>
              </div>
            </div>
            <!-- <div class="row mb-1"><div class="col"><h5>{{searchResults.length}} {{(searchResults.length==1) ? 'resultado' : 'resultados'}}:</h5></div></div> -->
            <div class="row my-2" v-if="searched && query.length"><div class="col"><h5>Resultados: <div class="badge badge-light">{{searchResults.length}}</div></h5></div></div>
            <div class="row" v-if="searched && query.length">
              <div class="col-12" v-if="!searchResults.length">
                <div class="alert alert-info mt-2">No se encontraron resultados a través de la busqueda: <strong>{{query}}</strong></div>
              </div>
              <div class="col-12 mt-2" v-for="(result,i) in searchResults" :key="i">
                  <div class="border border-light rounded p-2">
                    <div class="row align-items-center">
                      <div class="col-4 col-md-1">
                        <img :src="result.imagen" @click.prevent="showResult(result)" class="icon-image"/>
                      </div>
                      <div class="col">
                        <span class="badge badge-primary mb-2 pointer" @click.prevent="showResult(result)">{{(result.entrada!=undefined)?result.entrada[0]:'-'}}</span>
                        <h5 @click.prevent="showResult(result)" class="pointer"> {{result.titulo}} </h5>
                        <div class="text-muted pointer" @click.prevent="showResult(result)">{{result.ingredientes.substr(0,80)+".."}}</div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="d-none d-md-flex col">
                <!-- <img :src="seleccion.imagen" class="show-image"/> -->
                <!-- <div class="ml-3"> -->
                  <h4>Receta seleccionada</h4>
                  <!-- <span class="text-muted">{{seleccion.entrada[0]}}</span> -->
                <!-- </div> -->
              </div>
              <div class="col-12 col-md-3 text-right">
                <button class="btn btn-light" @click="closeResult()"><span style="transform:rotate(180deg);display:inline-block; position:relative;bottom:2px">&#10132;</span> Volver a búsqueda</button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-4">
                <img :src="seleccion.imagen" @click="showModal()" class="show-image mb-2 mb-md-0"/>
              </div>
              <div class="col">
                <h4 class="mb-3">{{capitalize(seleccion.titulo)}}</h4>
                <h5>
                  Código: <div class="badge badge-light">{{seleccion.codigo}}</div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  Tipo: <div class="badge badge-primary">{{(seleccion.entrada!=undefined)?seleccion.entrada[0]:'-'}}</div>
                </h5>
                <h6 class="mt-3">Condición: <div class="badge badge-light" v-for="(cond,i) in seleccion.condicion" :key="i">{{(cond.length) ? cond : 'Sin asignar'}}</div></h6>
                
                <hr>

                <h5 class="mb-2">Ingredientes</h5>
                <div>
                  <div class="badge badge-light mr-2 mb-2" v-for="(ingrediente,i) in seleccion.ingredientes.split(',')" :key="i">{{capitalize(ingrediente.trim())}}</div>
                </div>

                <h5 class="mt-3 mb-2">Procedimiento</h5>
                <textarea class="form-control" style="font-size:16px" rows="15" disabled :value="transformarSaltosDeLinea(seleccion.procedimiento, '<br>','\n')"> </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-image" v-if="trigger.showImage" @click="showModal()">
      <img :src="seleccion.imagen">
    </div>
    

</template>

<script>
import InputCarga from "../InputCarga.vue"

export default {
  name: "Busqueda",
  components: { InputCarga },
  props:{recetas:Array},
  data: () => ({
    seleccion:null,
    trigger:{
      show:false,
      showImage:false,
    },
    query: "",
    searchResults: [],
    searched: false,
  }),
  methods: {
    showModal(){
      this.trigger.showImage = !this.trigger.showImage;
    },
    showResult(result){
      this.cerrarVista();
      setTimeout(() => {
        this.trigger.show = true;
        this.seleccion = result;
        window.scrollTo(0,0);
      }, 200);
    },
    closeResult(){
      this.trigger.show = false;
      this.seleccion = null;
    },
    cerrarVista(){
      this.trigger.show = false;
      this.seleccion = null;
    },
    startSearch(){
      if (this.query.length) {
        setTimeout(() => {
          this.search();
        }, 500);
      }
    },
    search() {
      this.searchResults = null;
      this.searchResults = [];
      
      if (this.query.length) {
        this.searched = true;
        if(this.recetas!= null && Object.keys(this.recetas).length){
          // let undefs = [];
          Object.keys(this.recetas).forEach((e) => {

            
            
            if (this.recetas[e].titulo != undefined && this.recetas[e].titulo.toLowerCase().includes(this.query.toLowerCase())){
              this.searchResults.push(this.recetas[e]);
              return;
            }
           
           console.log(this.query+" :: "+this.recetas[e].codigo)
            if (this.recetas[e].codigo != undefined && this.recetas[e].codigo==this.query){
              this.searchResults.push(this.recetas[e]);
              return;
            }

            if (this.recetas[e].entrada != undefined && this.recetas[e].entrada.toString().toLowerCase().includes(this.query.toLowerCase())){
              this.searchResults.push(this.recetas[e]);
              return;
            }



            // if (this.recetas[e].titulo == undefined
            // || this.recetas[e].codigo == undefined
            // || this.recetas[e].entrada == undefined
            // ) {
            //   undefs.push(this.recetas[e])
            // }

            // if (this.recetas[e].titulo.toLowerCase().includes(this.query.toLowerCase()) 
            // || this.recetas[e].codigo.includes(this.query.toLowerCase())
            // || this.recetas[e].entrada.toString().toLowerCase().includes(this.query.toLowerCase())
            // ) {
            //   this.searchResults.push(this.recetas[e])
            // }
          });
          // console.log(undefs);
        }

      }
    },
    capitalize(word) {
      if (typeof word == 'string' && word.length) {
          return word[0].toUpperCase() + word.slice(1).toLowerCase();
      }
    },
    transformarSaltosDeLinea(text,charFrom,charTo){
      if (text && text.length) {
        return text.replaceAll(charFrom,charTo);
      }
    },
  },
  mounted() {
   
  },
};
</script>

<style scoped>
.icon-image {
  width: 100%;
  height:84px;
  border-radius: 6px;
  object-fit: cover;
}

.modal-image {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #00000060;
  display: flex;
  align-items: center;
}

.modal-image img {
  width: 90%;
  margin: 0 auto;
  display: block;
  border-radius: 6px;
}

@media (min-width: 768px) {
  .modal-image img {
    width: 34%;
  }
}

.show-image {
  width: 100%;
  height: 120px;
  -o-object-fit: cover;
  object-fit: cover;
  /* border: 1px solid #ededed; */
  border-radius: 6px;
  cursor:pointer;
}

@media (min-width: 768px) { 
  .show-image {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    /* border: 1px solid #ededed; */
    border-radius: 6px;
  }

  .icon-image {
    width: 64px;
    height:64px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.pointer {
  cursor:pointer
}
</style>
