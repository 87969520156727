<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-body my-1" v-if="success">
                            <h4>✔ La etiqueta se elimino correctamente</h4>
                        </div>
                        <div class="modal-body" v-if="deletePrompt">
                            <h5 class="mb-2">¡Cuidado esta acción no se puede deshacer!</h5>
                            <div class="bg-light p-3 rounded">
                                Estas a punto de eliminar la etiqueta <mark>{{selected.nombre}}</mark> existen <mark>{{recetasPorTag.length}}</mark> recetas relacionadas a esta etiqueta.
                            </div>
                            <div>
                                <p class="mt-3">¿Queres relacionar estas recetas a una etiqueta diferente?</p>
                                <p class="my-2" style="font-size:14px;font-weight:normal">Selecciona la etiqueta a continuación, sino las recetas quedan huérfanas.</p>
                                <select class="custom-select" id="type" v-model="newTag">
                                    <option :value="null">No relacionar</option>
                                    <option v-for="(tag,i) in tags" :key="i" :value="tag.nombre">{{tag.nombre}}</option>
                                </select>
                            </div>
                            <div class="text-right mt-3">
                                <button
                                    type="button"
                                    @click.prevent="$emit('end',false)"
                                    class="btn btn-light mr-2"
                                >
                                    Cancelar
                                </button>
                                <button
                                type="button"
                                @click.prevent="removerReceta"
                                class="btn btn-danger"
                                >
                                Eliminar etiqueta
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
// import axios from 'axios';

let fireRecetario = firebase.apps[1];

export default {
    name: "EliminarReceta",
    props: ['selected','recetasFull','recetasPorTag','tags'],
    data(){
        return {
            deletePrompt: true,
            success:false,
            newTag:null,
        }
    },
    mounted(){
        
    },
    methods: {
        removerReceta(event){
            // Se actualiza la vista para mostrar es estado de carga
            event.target.innerHTML = `
                <div class="spinner-border" style="width:12px;height:12px" role="status">
                    <span class="sr-only">Loading...</span>
                </div> Eliminando
            `
            event.target.disabled = true;
            for (let input of document.querySelectorAll('form input,form select')) {
                input.disabled = true;
            }
            
            
            fireRecetario.database().ref(`${this.selected.tipo}/${this.selected.id}`).remove()
            .then(()=>{
                console.log('etiqueta eliminada');
                // if(this.newTag != null){
                    let promises = [];
                    this.recetasPorTag.forEach((id)=>{
                        let tipo = (this.selected.tipo == 'tipo_entradas')?'entrada':'condicion';
                        let newTags = this.recetasFull[id][tipo].flatMap((t)=>{
                            if(t==this.selected.nombre || t==this.newTag) return []
                            return t
                        });

                        newTags.push(this.newTag);

                        promises.push( fireRecetario.database().ref(`recetas/${id}/${tipo}`).update(newTags) );
                    })

                    Promise.all(promises).then(()=>{
                        console.log('productos modificados');
                        this.$emit('end',true)
                    })
                // }

            }).catch(error=>{
                if(error){
                    console.log(error);
                    alert("Se produjo un error, actualiza la página (tecla: F5), intentá nuevamente o comunicate con sistemas")
                }
            })
        

        },
        
    }
}
</script>

<style scoped>
h4 {
    margin: 0;
    text-align: center;
    color: #1ea21e;
}

mark {
    background: none;
    text-decoration: underline red 3px;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 60%;
    margin: 0px auto;
    padding: 6px 20px 1px 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

    /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>