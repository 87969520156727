<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-header align-items-center no-gutters" v-if="!success && !deletePrompt">
                            <div class="col-10">Administrando la empresa <div class="badge badge-warning">{{ empresa.nombre }}</div></div>
                            <div class="col-2 text-right">
                                <button type="button" class="btn btn-sm btn-light font-weight-bold" @click.prevent="close()"> x </button>
                            </div>
                        </div> 
                        <div class="modal-body my-1" v-if="success">
                            <h4 class="success-title">✔ {{successMessage}}</h4>
                        </div>
                        <div class="modal-body" v-if="deletePrompt">
                            <div class="bg-light p-3 rounded">
                                Estas a punto de eliminar la empresa <mark>{{empresa.nombre}}</mark>. Esta acción no se puede deshacer
                            </div>
                            <div class="text-right mt-3">
                                <button
                                    type="button"
                                    @click.prevent="deletePrompt = false"
                                    class="btn btn-light mr-2"
                                >
                                    Cancelar
                                </button>
                                <button
                                type="button"
                                @click.prevent="removerEmpresa()"
                                class="btn btn-danger"
                                >
                                Eliminar empresa
                            </button>
                            </div>
                        </div>
                        <div class="modal-body" v-if="!success && !deletePrompt">
                            <div class="row">
                                <div class="col">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" value="false" v-model="triggers.sucursales" @change="triggerCheckbox($event,'sucursales')">
                                        <label class="form-check-label ml-1">La empresa tiene sucursales</label>
                                    </div>
                                    <!-- Si la empresa tiene sucursales, en el mounted las agregamos a nuevas, si no está indefinido se pasa un array completo -->
                                    <div class="mb-2" v-if="triggers.sucursales">
                                        <label class="form-label">Si la empresa tiene sucursales, podes agregarlas</label> 
                                        <form-etiquetas
                                            :etiquetasExistentes="nuevasSucursales"
                                            @etiquetas-seleccionadas="obtenerSeleccionEtiquetas($event)"
                                        ></form-etiquetas>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-label mt-3">¿Buscas modificar y/o agregar turnos? 
                                        <router-link :to="{ name: 'Turnos' }" class="">
                                            <span class="">ir a Turnos</span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Buttons footer -->
                        <div class="modal-footer" v-if="!success && !deletePrompt">
                            <div class="text-danger mt-3 deleteBtn" @click="deletePrompt = true">Desvincular empresa</div>
                            <div class="footer-actions">
                                <button
                                    type="button"
                                    @click.prevent="close()"
                                    class="btn btn-light"
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="button"
                                    @click.prevent="modificar()"
                                    class="btn btn-success ml-3"
                                >
                                    Modificar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
import FormEtiquetas from '../FormEtiquetas.vue';
// import FormPerfiles from "../FormPerfiles";

export default {
    name: "ModalAdministrarEmpresa",
    props: ['empresa'],
    components: {FormEtiquetas},
    data(){
        return {
            // Controla las actualizaciones del padre.
            seReasigno: false,
            success:false,
            successMessage: null,
            deletePrompt:false,
            nuevasSucursales: [],
            triggers: {
                sucursales: false,
            }
        }
    },
    mounted(){
        if (this.empresa.sucursales != undefined) {
            this.nuevasSucursales = this.empresa.sucursales;
            if (this.empresa.sucursales.length) this.triggers.sucursales = true
        }
    },
    methods: {
        removerEmpresa(){
            firebase.database().ref('empresas/' + this.empresa.id_empresa).remove()
            .then(()=>{
                this.seReasigno = true;
                this.deletePrompt = false;
                this.successMessage = "La empresa " + this.empresa.nombre + " fue eliminada"; 
                this.success = true;
                setTimeout(() => {
                    this.close()
                }, 1500);
            })
            .catch((error) => {
                if (error) {
                    console.log(error);
                    alert("Se produjo un error al eliminar la empresa, actualice la página (tecla: F5) reintente o comuniquese con sistemas")
                }
            })
        },
        modificar(){
            /**
             * Si se desmarco el checkbox sucursales o se eliminaron las sucursale
             * Se setea en null nuevasSucrusales para cuando se hace update en la referencia elimine la propiedad sucursales
             */
            if (this.nuevasSucursales.length == 0 || this.triggers.sucursales == false) {
                this.nuevasSucursales = null;
            }
            firebase.database().ref('empresas/' + this.empresa.id_empresa).update({
                sucursales: this.nuevasSucursales
            })
            .then(()=>{
                this.seReasigno = true;
                this.successMessage = "La empresa " + this.empresa.nombre + " fue modificada exitosamente"; 
                this.success = true;
                setTimeout(() => {
                    this.close()
                }, 1500);
            })
            .catch((error) => {
                if (error) {
                    console.log(error);
                    alert("Se produjo un error al modificar las sucursales, actualice la página (tecla: F5) reintente o comuniquese con sistemas")
                }
            })


        },
        obtenerSeleccionEtiquetas(seleccion){
            console.log(seleccion);
        },
         triggerCheckbox(checkbox,trigger){
            this.triggers[trigger] = checkbox.target.checked;
        },
        close(){
            this.$emit('cerrar-modal-administrar',this.seReasigno);
        },        
    }
}
</script>

<style scoped>
mark {
    background: none;
    text-decoration: underline red 3px;
}

.deleteBtn {
    cursor: pointer;
}

.deleteBtn:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
}

.modal-footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.success-title {
    margin: 0;
    text-align: center;
    color: #1ea21e;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 60%;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

    /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>