<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-header align-items-center no-gutters" v-if="!success">
                            <div class="col-10">Modificando los perfiles en empresa <div class="badge badge-warning">{{ empresa.nombre }}</div></div>
                            <div class="col-2 text-right">
                                <button type="button" class="btn btn-sm btn-light font-weight-bold" @click.prevent="close()"> x </button>
                            </div>
                        </div> 
                        <div class="modal-body my-1" v-if="success">
                            <h4 class="success-title">✔ Perfiles modificados existosamente en {{ empresa.nombre }}</h4>
                        </div>
                        <div class="modal-body" v-if="!success">
                            <form-perfiles :perfilesExistentes="empresa.perfiles" @perfiles-configurados="obtenerSeleccionPerfiles($event)"></form-perfiles>
                        </div>
                        <!-- Buttons footer -->
                        <div class="modal-footer" v-if="!success">
                            <button
                                type="button"
                                @click.prevent="close()"
                                class="btn btn-light"
                            >
                                Cancelar
                            </button>
                            <button
                                type="button"
                                @click.prevent="modificarPerfiles()"
                                class="btn btn-success"
                            >
                                Modificar perfiles
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
import FormPerfiles from "../FormPerfiles";

export default {
    name: "ModalModificarPerfiles",
    props: ['empresa'],
    components: {FormPerfiles},
    data(){
        return {
            // Controla las actualizaciones del padre.
            seReasigno: false,
            success:false,
            nuevosPerfiles: [],
        }
    },
    mounted(){
        if (this.empresa.perfiles != undefined) {
            this.nuevosPerfiles = this.empresa.perfiles;
        }
    },
    methods: {
        obtenerSeleccionPerfiles(seleccion){
            this.nuevosPerfiles = seleccion
            console.log(seleccion);
        },
        modificarPerfiles(){
            firebase.database().ref('empresas/' + this.empresa.id_empresa).update({
                perfiles: this.nuevosPerfiles
            })
            .then(()=>{
                this.success = true;
                this.seReasigno = true;
                setTimeout(() => {
                    this.close();
                }, 1500);
            })
            .catch(error=>{
                if(error){
                    console.log(error);
                    alert("Se produjo un erro al modificar el perfil, refresque la página (Tecla: F5) y reitentelo o comuniquese con sistema");
                }
            })
        },
        close(){
            this.$emit('cerrar-modal-perfiles',this.seReasigno);
        },
        
    }
}
</script>

<style scoped>
.success-title {
    margin: 0;
    text-align: center;
    color: #1ea21e;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 80%;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

    /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>