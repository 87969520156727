<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-header align-items-center no-gutters">
                        <div class="col-10">
                            {{ producto.nombre }}
                             <span
                                class="badge"
                                :class="{ 'badge-success': producto.activo, 'badge-light': !producto.activo}"
                            >{{ producto.activo ? "&nbsp;Activo&nbsp;" : "Inactivo" }}
                            </span>
                        </div>
                        <div class="col-2 text-right">
                            <button type="button" class="btn btn-sm btn-light font-weight-bold" @click.prevent="close()"> x </button>
                        </div>
                    </div>
                    <div class="modal-body my-0" v-if="!success">
                        <div class="row">
                            <div class="col-4">
                                <img v-if="producto.imagen" class="img-fluid" :src="producto.imagen"/>
                                <img v-else class="img-fluid" src="../../../../public/assets/images/fs-preview.jpg">
                            </div>
                            <div class="col-8">
                                <div class="row">
                                    <div class="col-12 mb-3">
                                        <h4 class="text-orange">{{producto.nombre}}</h4>
                                        <p class="f-size-sm">{{producto.detalle}}</p>
                                        <!-- <h3 class="text-success mt-2" v-if="producto.precio">$ {{producto.precio}}</h3> -->
                                    </div>
                                    <div class="col-12" v-if="producto.info_nutricional">
                                        <div class="alert alert-info alert-overflow">
                                            <h6 class="font-weight-bold mb-1">Información nutricional:</h6>
                                            {{producto.info_nutricional}}
                                        </div>
                                    </div>
                                    <div class="col-12" v-if="producto.modo_preparacion">
                                        <div class="alert alert-info alert-overflow">
                                            <h6 class="font-weight-bold mb-1">Modo de preparación:</h6>
                                            {{producto.modo_preparacion}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Buttons footer -->
                    <div class="modal-footer" v-if="!success">
                        <button
                            type="button"
                            @click.prevent="close()"
                            class="btn btn-light"
                        >
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "ModalListaVerMas",
    props: ['producto'],
    data(){
        return {
            // Controla las actualizaciones del padre.
            seReasigno: false,
        }
    },
    mounted(){
        
    },
    methods: {
        close(){
            this.$emit('cerrar-modal-ver-mas',this.seReasigno);
        },
    }
}
</script>

<style scoped>
.f-size-sm{
    font-size: smaller;
}

.alert-overflow {
    max-height: 100px;
    overflow-y: scroll;
}

.text-orange {
    color: #e86d20;
}

.img-fluid{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 80%;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

    /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>