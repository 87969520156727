<template>
  <div class="page-content">
		<!-- Breadcrumb -->
		<nav class="page-breadcrumb" id="breadcrumb">
			<ol class="breadcrumb">
			<li class="breadcrumb-item">{{breadcrumbA}}</li>
			<li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
			</ol>
		</nav>
		<!-- Page title -->
		<div class="row">
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
				<h6 class="card-title">{{breadcrumbB}}</h6>
				<p class="card-description"> {{descripcion}}</p>
				</div>  
			</div>
			</div>
		</div> 
		<!-- Administrar los productos -->
		<div class="row mb-5" v-if="triggers.administrarProductos">
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
					<administrar-poductos-en-unidad
						:unidad="unidadAdministrable"
						@productos-unidad-actualizados="cerrarFormularioAdministrarProductos($event)"
					></administrar-poductos-en-unidad>
				</div>
			</div>
			</div>
		</div>


		<!-- Formulario crear  -->
		<div class="row" v-if="triggers.nuevaUnidad">
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
					<form-nueva-unidad-de-negocio @unidad-creada="actualizarLista++"></form-nueva-unidad-de-negocio>
				</div>
			</div>
			</div>
		</div>
		<!-- Lista existentes -->
		<div class="row" v-if="triggers.listaUnidades">
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
					<lista-unidades-de-negocio 
						:key="actualizarLista"
						@administrar-productos="abrirFormularioAdministrarProductos($event)"
					></lista-unidades-de-negocio>
				</div>
			</div>
			</div>
		</div>
	</div>
</template>

<script>
import FormNuevaUnidadDeNegocio from '../components/UnidadDeNegocio/FormNuevaUnidadDeNegocio.vue';
import ListaUnidadesDeNegocio from '../components/UnidadDeNegocio/Lista'
import AdministrarPoductosEnUnidad from "../components/UnidadDeNegocio/AdministrarPoductosEnUnidad";
export default {
    name: 'UnidadesDeNegocio',
    components:{FormNuevaUnidadDeNegocio, ListaUnidadesDeNegocio, AdministrarPoductosEnUnidad},
    data() {
        return {
			breadcrumbA: 'Inicio',
            breadcrumbB: 'Unidades de negocio',
            descripcion: 'Agregá nuevas unidades y modifica las existentes',
			actualizarLista: 0,
			unidadAdministrable: null,
            triggers: {
				administrarProductos: false,
				nuevaUnidad: true,
				listaUnidades: true
			}
        }
    },
	methods: {
		abrirFormularioAdministrarProductos(unidad){
			this.triggers.nuevaUnidad = false;
			this.triggers.listaUnidades = false;
			this.unidadAdministrable = unidad;
			this.triggers.administrarProductos = true;
		},
		cerrarFormularioAdministrarProductos(){
			this.triggers.administrarProductos = false,
			this.triggers.nuevaUnidad = true,
			this.triggers.listaUnidades = true
		}
	}
}
</script>

<style>

</style>