<template>
	<div class="row">
        <div class="col">
            <h4 class="card-title">Crear unidades de negocio</h4>
            <form>
                <!-- Nombre y detalle -->
                <div class="form-row">
                    <div class="col-12">
                        <div class="alert alert-success" v-if="triggers.success">✓ Unidad de negocio creada correctamente, ya podés asignar productos</div>
                    </div>
                    <div class="col-8">
                        <label for="input-nombre" class="form-label">Agregá un nombre</label> 
                        <input type="text" class="form-control" id="input-nombre" v-model="unidadDeNegocio.nombre" @input="errores.nombre=false" placeholder="Nombre de la unidad de negocio"/>
                        <div class="text-danger font-weight-bold mt-2" v-if="errores.nombre">× {{errores.nombreMensaje}}</div>
                    </div>
                    <div class="col-4">
                        <label class="form-label">&nbsp;</label><br>
                        <input type="submit" class="btn btn-md btn-success w-100" value="Agregar unidad de negocio" @submit.prevent="agregarUnidadDeNegocio()" @click.prevent="agregarUnidadDeNegocio()"/>
                    </div>
                </div>
               
            </form>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";
// import { apis } from "../../../.env";

// var usuario = JSON.parse(sessionStorage.getItem('userData'));

export default {   
	name: 'FormNuevaUnidadDeNegocio',
    data() {
        return {
            unidades: [],
			unidadDeNegocio: {
				nombre: null,
                productos: 0,
                empresas: 0
			},
			errores: {
				nombre: false,
                nombreMensaje: null,
			},  
            triggers: {
                success: false,
            }         
        }
    },
    methods: {
		agregarUnidadDeNegocio(){
            if (this.unidadDeNegocio.nombre == null || !this.unidadDeNegocio.nombre.length) {
                this.errores.nombre = true;
                this.errores.nombreMensaje = "El nombre no puede esta vacio";
                return null
            }

            this.unidadDeNegocio.nombre = this.unidadDeNegocio.nombre.toLowerCase();

            axios.get('https://fs-comedores-admin-default-rtdb.firebaseio.com/unidades_negocio.json?orderBy="nombre"&equalTo="'+ this.unidadDeNegocio.nombre +'"')
            .then(response=>{
                if (Object.keys(response.data).length) {
                    console.log('la unidad existe');
                    this.errores.nombre = true;
                    this.errores.nombreMensaje = "La unidad " + this.unidadDeNegocio.nombre + " existe";
                    return null
                }
                const vue = this;
                var postsRef = firebase.database().ref("unidades_negocio");
                // Generate a reference to a new location and add some data using push()
                // var newPostRef = postsRef.push(this.unidadDeNegocio);
                postsRef.push(this.unidadDeNegocio);
                console.log("unidad de negocio agregada");
                vue.triggers.success = true;
                vue.unidadDeNegocio.nombre = null;
                setTimeout(() => {
                    vue.$emit('unidad-creada');
                    vue.triggers.success = false;
                }, 2000);



                // Get the unique key generated by push()

                // var postId = newPostRef.key;
                // // Add modificator
                // postsRef.child(postId + "/modificaciones").push({
                //         responsable_nombre: usuario.nombre,
                //         responsable_id: usuario.id,
                //         timestamp: firebase.database.ServerValue.TIMESTAMP,
                //     }, function() {
                //         console.log("unidad de negocio agregada por el usuario: " + usuario.nombre);
                //         vue.triggers.success = true;
                //         vue.$emit('unidad-creada');
                //         vue.unidadDeNegocio.nombre = null;
                //         setTimeout(() => {
                //             vue.triggers.success = false;
                //         }, 3000);
                //     }
                // )
            })
        },
    },
	mounted() {

    },
}
</script>

<style scoped>
.form-control {
	display: inline-block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 1.75rem 0.375rem 0.75rem;
	font-size: .8rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	vertical-align: middle;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.textarea {
	height: auto;
}
</style>