<template>
  <div>
    <div class="wrapper-etiquetas" @click="focusEnInput()">
      <div class="etiquetas">
        <div class="badge badge-light" v-for="(etiqueta,i) in etiquetas" :key="i" @click="quitarEtiqueta(i)">{{etiqueta}} ×</div>
      </div>
      <input
        type="text"
        v-model="nuevaEtiqueta"
        @keydown="handleKeyDown"
        placeholder="Separa con una coma o enter"
        ref="input"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "FormEtiquetas",
  props: ['etiquetasExistentes'],
  data() {
    return {
      nuevaEtiqueta: null,
      etiquetas: [],
    };
  },
  mounted() {
    if (this.etiquetasExistentes != undefined) {
      this.$nextTick(()=>{
        this.etiquetas = this.etiquetasExistentes;
      })
    }
  },
  methods: {
    handleKeyDown(e) {
      if (["Enter", ","].includes(e.key)) {
        this.etiquetas.push(this.nuevaEtiqueta);
        this.$nextTick(() => (this.nuevaEtiqueta = null));
        this.emitirData();
      }
    },
    quitarEtiqueta(i){
      this.etiquetas.splice(i,1);
      this.emitirData();
    },
    emitirData() {
      this.$emit('etiquetas-seleccionadas',this.etiquetas);
    },
    focusEnInput(){
      this.$refs.input.focus();
    }
  },
};
</script>

<style scoped>
.wrapper-etiquetas {
  display: flex;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  cursor: text
}

.etiquetas .badge {
  margin-right: 0.4rem;
  cursor: pointer;
}

input {
  border: none;
  width: 212px;
}
</style>
