import { createWebHashHistory, createRouter } from "vue-router";

import Dashboard from "@/views/Dashboard.vue";
import Login from "@/views/Login.vue";
// import Usuarios from "@/views/Usuarios.vue";
// import Turnos from "@/views/Turnos.vue";
// import Reservas from "@/views/Reservas.vue";
// import Reporte from "@/views/Reporte.vue";
import Productos from "@/views/Productos.vue";
import ListaProductos from "@/views/ListaProductos";
import Empresas from "@/views/Empresas";
import UnidadesDeNegocio from "@/views/UnidadesDeNegocio";
import WizardModificarEmpresa from "@/components/Empresa/WizardModificarEmpresa";
import Recetas from "@/views/Recetas"
import Etiquetas from "@/views/Etiquetas"
import Platos from "@/views/Platos"


const routes = [

 {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
   
  {
    path: "/login",
    name: "Login",
    component: Login,
  }
  ,
   
  // {
  //   path: "/usuarios",
  //   name: "Usuarios",
  //   component: Usuarios,
  // },
  // {
  //   path: "/turnos",
  //   name: "Turnos",
  //   component: Turnos,
  // }, 
  // {
  //   path: "/reservas",
  //   name: "Reservas",
  //   component: Reservas,
  // },
  // {
  //   path: "/reporte",
  //   name: "Reporte",
  //   component: Reporte,
  // },
  {
    path: "/empresas",
    name: "Empresas",
    component: Empresas,
  },
  {
    path: "/unidades-de-negocio",
    name: "UnidadesDeNegocio",
    component: UnidadesDeNegocio,
  },
  {
    path: "/productos",
    name: "Productos",
    component: Productos,
  },
  {
    path: "/lista-productos",
    name: "ListaProductos",
    component: ListaProductos,
  },
  {
    path: "/modificar-empresa",
    name: "WizardModificarEmpresa",
    component: WizardModificarEmpresa,
  },
  {
    path: "/recetas",
    name: "Recetas",
    component: Recetas,
  },
  {
    path: "/etiquetas",
    name: "Etiquetas",
    component: Etiquetas,
  },
  {
    path: "/platos",
    name: "Platos",
    component: Platos,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  mode: 'hash'
});

export default router;