<template>
    <div class="load-box"></div>
</template>

<script>
export default {
    name: "InputCarga", 
}
</script>

<style scoped>
@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}


.load-box {
    border-radius: 6px;
    width: 100%;
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: fff;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    height: 30px;
    -webkit-backface-visibility: hidden;
    left:0;
    right:0;
    top:0;
    bottom:0;
    transition: all .2s linear;
}
</style>
