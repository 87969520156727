<template>
  <div class="row">
        <div class="col">
            <div class="wizard-step" ref='step-0'>
                <div class="wizard-content">
                    <h4 class="mb-4">Configura los parámetros inciales</h4>
                    <!-- Nombre -->
                    <label class="form-label">Seleccioná una empresa de la lista</label> 
                    <select-empresa :empresas="empresas" :placeholder="'Lista de empresas'" @seleccion="obtenerSeleccionEmpresa($event)" class="mb-3"></select-empresa>
                    <div class="text-info mt-2" v-if="empresa.tipo_empresa">· La empresa seleccionada es de tipo <div class="badge badge-info">{{empresa.tipo_empresa}}</div></div>
                    <div class="text-danger font-weight-bold mt-2" v-if="errores.nombre">× Seleccioná una empresa, este campo es obligatorio.</div>
                    <div class="text-danger font-weight-bold mt-2" v-if="errores.empresaExistente">× La empresa seleccionada ya existe.</div>
                    <!-- Sucursales -->
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" value="false" @change="triggerCheckbox($event,'sucursales')">
                        <label class="form-check-label ml-1">La empresa tiene sucursales</label>
                    </div>
                    <div v-if="triggers.sucursales">
                        <label class="form-label">Si la empresa tiene sucursales, podes agregarlas</label> 
                        <!-- <input type="text" class="form-control mb-3" v-model="empresa.sucursales" placeholder="Separá con coma ej: planta sur, planta oeste,"/> -->
                        <form-etiquetas @etiquetas-seleccionadas="obtenerSeleccionSucursales($event)"></form-etiquetas>
                    </div>

                    <!-- <div class="form-row">
                        <div class="col-12"> -->
                            <label class="form-label w-100 my-2 mt-3">Seleccioná la modalidad de la empresa</label> 
                            <div class="alert alert-info mb-2">
                                Las empresas de tipo contador de comensales no permiten la selección ni reserva de productos por parte de los comensales.
                            </div>
                            <div class="form-check checkBox">
                                <input class="form-check-input" type="radio" name="check-comensales" id="check-comensales" value="contador" v-model="empresa.modalidad" checked>
                                <label class="form-check-label checkBoxLabel" for="check-comensales">
                                    Contador de comensales
                                </label>
                                </div>
                                <div class="form-check checkBox">
                                <input class="form-check-input" type="radio" name="check-productos" id="check-productos" value="productos" v-model="empresa.modalidad">
                                <label class="form-check-label checkBoxLabel" for="check-productos">
                                    Seleccion y reserva de productos
                                </label>
                            </div>
                        <!-- </div>
                    </div> -->
                </div>
            </div>
            <div class="wizard-step" ref='step-1'>
                <!--Contador de comensales -->
                <div class="wizard-content" v-if="empresa.modalidad == 'contador'">
                    <h4 class="mb-4">Modalidad: Contador de comensales en <div class="badge badge-warning">{{empresa.nombre}}</div></h4>
                    <h5 class="mb-2">Indicá el rango de horarios</h5>
                    <div class="alert alert-info">Es posible asignar turnos al finalizar</div>
                    <!-- inputs -->
                    <div class="form-row">
                        <div class="col">
                            <label class="form-label">Desde</label>
                            <input type="time" class="form-control" v-model="empresa.contador.desde"/>
                        </div>
                        <div class="col">
                            <label class="form-label">Hasta</label>
                            <input type="time" class="form-control" v-model="empresa.contador.hasta"/>
                        </div>
                    </div>
                    <div class="text-danger font-weight-bold mt-2" v-if="errores.contadorDesdeHasta">× Los dos campos son obligatorios, ingresa un periodo de tiempo válido, desde X hasta Y</div>
                </div>
                <!-- Seleccion y reserva de productos -->
                <div class="wizard-content" v-if="empresa.modalidad == 'productos'">
                    <h4 class="mb-4">Modalidad: Seleccion y reserva de productos en <div class="badge badge-warning">{{empresa.nombre}}</div></h4>
                    <div class="alert alert-info">
                        Los empleados reservan su comida con una anticipación determinada, ademas es posible que la empresa maneje un stock de los productos disponibles.
                    </div>
                    <!-- Anticipacion y stock -->
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" value="false" v-model="empresa.stock">
                        <label class="form-check-label ml-1">La empresa maneja un stock de productos</label>
                    </div>
                    <!-- Dos columnas inputs -->
                    <h5 class="my-3">Anticipación de pedidos</h5>
                    <div class="form-row">
                        <div class="col">
                            <label class="form-label">Tipo</label>
                            <select class="custom-select" v-model="empresa.anticipacion.tipo" @change="empresa.anticipacion.tiempo = 1">
                                <option value="diaria">Diaria</option>
                                <option value="semanal">Semanal</option>
                                <option value="mensual">Mensual</option>
                            </select>
                        </div>
                        <div class="col">
                            <label class="form-label">Tiempo</label>
                            <!-- <input type="text" class="form-control" v-model="empresa.anticipacion.tiempo"/> -->
                            <select class="custom-select" v-model="empresa.anticipacion.tiempo">
                                <option :value="option.tiempo" v-for="(option,i) in tiemposAnticipacion[empresa.anticipacion.tipo]" :key="i">{{option.text}}</option>
                            </select>
                        </div>
                    </div>
                    <!-- Cancelable -->
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" value="false" v-model="empresa.anticipacion.cancelacion">
                        <label class="form-check-label ml-1">El comensal puede cancelar y/o modificar pedidos</label>
                    </div>
                </div>
            </div>
            <div class="wizard-step" ref='step-2'>
                <div class="wizard-content">
                    <h4 class="mb-4">Comensales: configure tipos de comensales <div class="badge badge-warning">{{empresa.nombre}}</div></h4>
                    <div class="alert alert-info">
                        Este parámetro permite configurar distintos tipos de comensales para que cada uno tenga diferentes limites de platos y subvenciones. Por ejemplo: puede configurar un Gerente con un limite de 2 platos diarios y una subvencion del 80% o un empleado administrativo con un limite de 1 plato y sin subvención.
                    </div>
                    <!-- Sucursales -->
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="" value="false" @change="triggerCheckbox($event,'perfiles')">
                        <label class="form-check-label ml-1">Existen diferentes tipos de comensales</label>
                    </div>
                    <div class="alert alert-danger" v-if="errores.perfilesIncompletos">
                        No se asignaron perfiles o los perfiles asignados tienen errores. Atención: Los perfiles marcados en rojo no se crearan, corrija la información o desmarque la opción de diferentes tipo de comensales.
                    </div>
                    <div v-if="triggers.perfiles">
                        <!-- Dos columnas inputs -->
                        <h5 class="my-3">Administra perfiles de consumidores</h5>
                        <form-perfiles @perfiles-configurados="obtenerSeleccionPerfiles($event)"></form-perfiles>
                    </div>
                </div>
            </div>
            <div class="wizard-step" ref="step-3">
                <h5 class="mb-2 mt-4">Asigná las unidades de negocio</h5>
                <div class="alert alert-info" v-if="!errores.unidades && !unidadesDeNegocioSinProducto.length">Luego es posible seleccionar los productos y precios para cada unidad.</div>
                <div class="alert alert-danger" v-if="errores.unidades">No es posible crear una empresa sin unidades de negocio, seleccioná una</div>
                <div class="form-row pl-2">
                    <div class="col-4 mb-2" v-for="(unidad,i) in unidadesDeNegocio" :key="i">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="false" @click="unidadCheckbox(i,$event,unidad)">
                            <label class="form-check-label ml-1" for="checkPrecio">{{unidad.nombre}} <div class="badge badge-light">{{unidad.cantidadProductos}} productos</div></label>
                        </div>
                    </div>
                </div>
                <div class="alert alert-info" v-if="unidadesDeNegocioSinProducto.length">
                    Atención las siguientes unidades de negocio no se muestran porque no tienen productos asignados: <span class="font-weight-bold" v-for="u,k in unidadesDeNegocioSinProducto" :key="k">{{u.nombre}},&nbsp;</span>
                </div>
            </div>
            <!-- Recorrido automático -->
            <div v-for="(step,i) in stepsDinamicos" :key="step.id" class="wizard-step" :ref="'step-'+(i+stepsIniciales)">
                <div class="wizard-content">
                    <h5 class="mb-2">Asigna productos de la unidad <div class="badge badge-warning">{{step.unidad.nombre}}</div></h5>
                    <div class="row"> 
                        <div class="col">
                            <lista-carga v-if="step.productsLoad" :card="false" class="mt-5"></lista-carga>
                            <div class="row" v-else>
                                <div class="col-12 mb-3">
                                    <table :id="'productos-step-'+(i+stepsIniciales)" :ref="'table-'+(i+stepsIniciales)" class="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Nombre</th>
                                            <!-- <th>Detalle</th> -->
                                            <th>Precio</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                        <tbody>
                                            <tr v-for="(producto,i) in step.unidad.productos" v-bind:key="i" class="">
                                                <td><input class="" type="checkbox" v-model="producto.seleccionado" @change="refreshInputPrecio($event,producto)"></td>
                                                <td>{{ producto.nombre }}</td>
                                                <!-- <td>{{ producto.detalle }}</td> -->
                                                <td>
                                                    <div class="form-row align-items-center">
                                                        <div class="col">
                                                            <input type="text" class="form-control" v-model="producto.precio" :disabled="!producto.precioActivo" placeholder="0.00"/>
                                                        </div>
                                                        <div class="col">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox" id="checkPrecio" v-model="producto.precioActivo" @change="refreshInputPrecio($event,producto)" value="false">
                                                                <label class="form-check-label ml-1" for="checkPrecio">Tiene precio</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><div class="btn btn-primary" @click="abrirModalVerMas(producto)">Ver info</div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Step final -->
            <div class="wizard-step" ref='step-final'>
                <div class="wizard-content">
                    <h4>Vista previa {{empresa.nombre}}</h4>
                    <div class="row mt-2">
                        <div class="col">
                            <div class="card card-u rounded" v-if="empresa.modalidad == 'productos'">
                                <div class="card-header header-u bg-light">Modalidad seleccionada <div class="badge badge-warning">Productos</div></div>
                                <ul class="list-group list-group-flush">
                                    <!-- <li class="list-group-item">Anticipacion: {{empresa.anticipacion.tipo}}</li>
                                    <li class="list-group-item">Tiempo de anticipación: {{tiemposAnticipacion[empresa.anticipacion.tipo][empresa.anticipacion.tiempo-1].text }}</li>
                                    <li class="list-group-item">Cancelación: {{(empresa.anticipacion.cancelacion) ? "Habilitada" : "Deshabilitada"}}</li>
                                    <li class="list-group-item">Stock de productos: {{(empresa.stock) ? "Con control de stock" : "Sin control de stock"}}</li> -->
                                    <li class="list-group-item">
                                        <div class="row">
                                            <div class="col">Anticipación: {{empresa.anticipacion.tipo}}</div>
                                            <div class="col">Tiempo de anticipación: {{tiemposAnticipacion[empresa.anticipacion.tipo][empresa.anticipacion.tiempo-1].text }}</div>
                                        </div>
                                    </li>
                                    <li class="list-group-item">
                                        <div class="row">
                                            <div class="col">Cancelación: {{(empresa.anticipacion.cancelacion) ? "Habilitada" : "Deshabilitada"}}</div>
                                            <div class="col">Stock de productos: {{(empresa.stock) ? "Con control de stock" : "Sin control de stock"}}</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="card card-u rounded" v-else>
                                <div class="card-header header-u bg-light">Modalidad seleccionada <div class="badge badge-warning">Contador</div></div>
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">
                                        <div class="row">
                                            <div class="col">Rango desde: {{empresa.contador.desde}} hs</div>
                                            <div class="col">Hasta: {{empresa.contador.hasta}} hs</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col my-2">
                            <div class="card card-u rounded">
                                <div class="card-header header-u bg-light">Perfiles de comensales</div>
                                <ul class="list-group list-group-flush" v-if="empresa.perfiles.length">
                                    <li class="list-group-item" v-for="perfil,i in empresa.perfiles" :key="i">
                                        <div class="row">
                                            <div class="col">Nombre: <div class="badge badge-light">{{perfil.nombre}}</div></div>
                                            <div class="col">Limite de platos: <div class="badge badge-light">{{perfil.limite}}</div></div>
                                            <div class="col">Subvencion: <div class="badge badge-light">{{perfil.subvencion}}%</div></div>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="list-group list-group-flush" v-else><li class="list-group-item">Ningún perfil asignado</li></ul>
                            </div>
                        </div>
                    </div>
                    <!-- Unidades y productos -->
                    <h5 class="mt-3">Unidades y productos</h5>
                    <div class="row">
                        <div class="col-4 my-2"  v-for="(step,i) in stepsDinamicos" :key="i">
                            <div class="card card-u rounded">
                                <div class="card-header header-u bg-light">{{step.unidad.nombre}}</div>
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item" v-for="(producto,i) in soloProductosSeleccionados(step)" :key="i">
                                        <div class="d-flex">
                                            <div class="w-75">{{ producto.nombre }}</div>
                                            <div class="w-25 text-right">
                                                <div class="badge badge-light">$ {{ (producto.precio) ? producto.precio : "-"}}</div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 mt-2 mt-4 text-right">
            <div class="btn btn-light mr-2" @click="anterior()"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg> Anterior</div>
            <div class="btn btn-success" @click="siguiente()" v-if="!lastStep">Siguiente <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg></div>
            <div class="btn btn-success" @click="crearEmpresa()" v-else><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg> Crear nueva empresa</div>
        </div>
        <!-- <div class="d-flex">
            <div class="btn btn-light rounded-circle" @click="seleccionarStep(i)" v-for="(step,i) in stepsTotales" :key="i">
                {{i+1}}
            </div>
            <div class="btn btn-light rounded-circle" @click="seleccionarStep(stepsTotales,true)">
                final
            </div>
        </div> -->
    </div>
     <modal-lista-ver-mas 
    v-if="modalVerMas.open"
    :producto="modalVerMas.producto"
    @cerrar-modal-ver-mas="modalVerMas.open = false"
    ></modal-lista-ver-mas>
</template>

<script>
import {apis, prefabQ, tableConfig} from "../../../.env"
import axios from 'axios'
import firebase from "firebase/app"
import "firebase/database"
import Swal from 'sweetalert2'
import $ from "jquery"
require("datatables.net")

import FormEtiquetas from './FormEtiquetas.vue';
import FormPerfiles from './FormPerfiles.vue';
import SelectEmpresa from "./SelectEmpresa";
import ListaCarga from '../ListaCarga.vue';
import  ModalListaVerMas  from "../Producto/modal/ModalListaVerMas"
export default {
    name: "WizardNuevaEmpresa",
    components: {SelectEmpresa,FormPerfiles, FormEtiquetas,ListaCarga, ModalListaVerMas,},
    props: ['empresas'],
    data(){
        return {
            currentStep: 0,
            lastStep:false,
            // Establece los pasos iniciales antes de los dinámico
            // Si hay un paso que pide un nombre, otro un correo y luego n dinámico
            // Se establece stepIniciales en 2 (el primer paso es step-0)
            stepsIniciales: 4,
            stepsDinamicos: [],
            // Para mostrar un indice se suman los steps
            stepsTotales: 0,
            errores: {
                unidades: false,
                empresaExistente: false,
                nombre: false,
                contadorDesdeHasta: false,
                perfilesIncompletos: false,
            },
            empresa: {
                sucursales: null,
                catering_id: null,
                centroapropiacion_id: null,
                id_empresa: null,
                nombre: null,
                tipo_empresa: null,
                modalidad: 'contador',
                contador: {
                    desde: '',
                    hasta: ''
                },
                stock: false,
                anticipacion: {
                    cancelacion: false,
                    tiempo: 1,
                    tipo: 'diaria'
                },
                perfiles: [],
            },
            triggers: {
                sucursales: false,
                perfiles: false
            },
            unidadesDeNegocio: [],
            unidadesDeNegocioSinProducto: [],
            tiemposAnticipacion: {
                'diaria': [
                    {tiempo: 1, text: '1 día'},
                    {tiempo: 2, text: '2 días'},
                    {tiempo: 3, text: '3 días'},
                    {tiempo: 4, text: '4 días'},
                    {tiempo: 5, text: '5 días'},
                    {tiempo: 6, text: '6 días'},
                ],
                'semanal': [
                    {tiempo: 1, text: '1 semana'},
                    {tiempo: 2, text: '2 semanas'},
                    {tiempo: 3, text: '3 semanas'},
                ],
                'mensual': [
                    {tiempo: 1, 'text': '1 mes'}
                ]
            },
            modalVerMas: {
                open:false,
                producto: null
            }


        }
    },
    mounted(){
        this.obtenerUnidadesDeNegocio();
        this.refrescarTabs();
        this.stepsTotales = this.stepsIniciales + this.stepsDinamicos.length;

    },
    methods: {
        /**
         * Obtiene las unidades desde firebase y las formatea de una forma determinada
         */
        obtenerUnidadesDeNegocio(){
            axios
            .get(apis.unidadesDeNegocio)
            .then((responseUnidades) => {
                this.unidadesDeNegocio = Object.keys(responseUnidades.data).flatMap((unidad)=>{
                    if((responseUnidades.data[unidad].productos != undefined && !Object.keys(responseUnidades.data[unidad].productos).length || responseUnidades.data[unidad].productos == undefined)) {
                        this.unidadesDeNegocioSinProducto.push({nombre:responseUnidades.data[unidad].nombre});
                        return []
                    }

                    return {
                        nombre: responseUnidades.data[unidad].nombre,
                        cantidadProductos: (responseUnidades.data[unidad].productos != undefined) ? Object.keys(responseUnidades.data[unidad].productos).length : 0,
                        productos: responseUnidades.data[unidad].productos,
                        empresas: responseUnidades.data[unidad].empresas,
                        fireId: unidad,
                    }
                })
            })
            .catch((error) => {
                console.log(error);
                alert("Se produjo un error");
            });
        },
        /**
         * El Select2 emite un evento con la data seleccionada 
         * Comprobamos que la empresa no haya sido asignada en comedores
         * Se asigna la empresa a la creación
         * @param seleccion un evento generado por Select2 provee la data de la seleccion. 
         */
        obtenerSeleccionEmpresa(seleccion){
            this.empresa.tipo_empresa = null;
            this.errores.empresaExistente = false;
            this.errores.nombre = false;
            axios
            .get(prefabQ('empresaEnComedores',seleccion.id_empresa))
            .then(response=>{
                if (response.data == null) {
                    this.empresa.catering_id = seleccion.catering_id;
                    this.empresa.centroapropiacion_id = seleccion.centroapropiacion_id;
                    this.empresa.id_empresa = seleccion.id_empresa;
                    this.empresa.nombre = seleccion.nombre;
                    this.empresa.tipo_empresa = seleccion.tipo_empresa;
                } else {
                    this.errores.empresaExistente = true;
                }
            }).catch(error=>{
                if (error) {
                    console.log(error);
                    alert("Se produjo un error al comprobar la existencia de la empresa en comedores")
                }
            });
        },
        triggerCheckbox(checkbox,trigger){
            this.triggers[trigger] = checkbox.target.checked;
        },
        obtenerSeleccionPerfiles(seleccion){
            this.empresa.perfiles = seleccion
        },
        obtenerSeleccionSucursales(seleccion){
            this.empresa.sucursales = seleccion;
        },
        unidadCheckbox(elementId, value, unidad){
            // console.log(unidad);
            this.errores.unidades = false;
            if (value.target.checked) {
                this.stepsDinamicos.push({
                    id:elementId,
                    unidad,
                    title: unidad.nombre,
                    productsLoad: true,
                });
            } else {
                this.stepsDinamicos.forEach((el,index)=>{
                    if (el.unidad.fireId == unidad.fireId) {
                        // console.log(el.elementId +"=="+ elementId);
                        // console.log();
                        this.stepsDinamicos.splice(index,1)
                    }
                })
            }
            this.$nextTick(()=>{
                this.refrescarTabs();
                // this.$refs["step-"+(this.currentTab)].style.display = "block";
            })
        },
        /**
         * Obtiene los productos específicos de la unidad para mostrar un data table
         * que permite seleccionarlos y fijar precios. A través de elementStep configura el data table
         * @param elemento el elemento (unidad) de la propiedad element que genera la tab
         */
        productosEspecificosSeleccionados(elemento){
            // Si el elemento no tiene productos sale
            if (elemento.unidad.productos == undefined) return null
            // Si ya se cargaron los producto sale
            if (elemento.productsLoad == false) return null
            let productos = elemento.unidad.productos;
            let promesas = [];
            Object.keys(productos).forEach(fireId=>{
                // console.log(productos[fireId].producto_id);
                // let promesa = axios.get(apis.productoConId + productos[fireId].producto_id + ".json?print=pretty")
                let promesa = axios.get(prefabQ('producto' , productos[fireId].producto_id))
                .then(productoResponse=>{
                    let fullProducto = productoResponse.data;
                    productos[fireId].nombre = fullProducto.nombre;
                    productos[fireId].precio = null;
                    productos[fireId].imagen = fullProducto.imagen;
                    productos[fireId].info_nutricional = fullProducto.info_nutricional;
                    productos[fireId].modo_preparacion = fullProducto.modo_preparacion;
                    productos[fireId].detalle = fullProducto.detalle;
                    productos[fireId].precioActivo = false; 
                    productos[fireId].seleccionado = true;
                }).catch((error)=>{
                    console.log(error);
                    alert("Hubo un problema al obtener los productos de la unidad")
                })
                promesas.push(promesa);
            })


            // Una vez que las promesas se cumplen mostramos el dataTable
            // $("productos-step-" + elementStep).dataTable().fnDestroy();
            // elemento.productosEspecificos = true;
            $(this.$refs['table-'+this.currentStep]).dataTable().fnDestroy();

         

            Promise.all(promesas).then(() => {
                elemento.productsLoad = false;
                this.$nextTick(()=>{
                    let config = tableConfig;
                    config.buttons = [];
                    $(this.$refs['table-'+this.currentStep]).dataTable(config);
                   
                })
            });
        },
          /**
         * A través del event toma el value de un checkbox que pertenece al producto
         * de un element (productos por unidades). Asigna al producto el precio y si este esta activo.
         * @param event evento de un checkbox, nos da el target
         * @param producto de un element (unidads de negocio seleccionada)
         */
        refreshInputPrecio(event, producto){
            if (!event.target.checked) {
                producto.precio = null;
                producto.precioActivo = false; 
            }
        },
        crearEmpresa(){
            // Se recorren las unidades seleccionadas y se almacenan solo los productos seleccionados, con su precio e id
            let unidades = Object.keys(this.stepsDinamicos).map((unidad)=>{
                let productosSeleccionados = Object.keys(this.stepsDinamicos[unidad].unidad.productos).flatMap(producto => {
                    if (this.stepsDinamicos[unidad].unidad.productos[producto].seleccionado == true) {
                        return {
                            precio: this.stepsDinamicos[unidad].unidad.productos[producto].precio,
                            producto_id: this.stepsDinamicos[unidad].unidad.productos[producto].producto_id
                        }
                    }else {
                        return []
                    }
                })
                return{
                    unidad_id: this.stepsDinamicos[unidad].unidad.fireId,
                    productos: productosSeleccionados
                }
            });
            // Obtuvimos la variable unidades que contiene un objetos con las unidades e id y los productos
            
            // Almacenar en Firebase
            firebase.database().ref("empresas/" + this.empresa.id_empresa + "/").update({
                    sucursales: this.empresa.sucursales,
                    catering_id: this.empresa.catering_id,
                    centroapropiacion_id: this.empresa.centroapropiacion_id,
                    nombre: this.empresa.nombre,
                    tipo_empresa: this.empresa.tipo_empresa,
                    modalidad: this.empresa.modalidad,
                    contador: (this.empresa.modalidad == 'contador') ? this.empresa.contador : null,
                    stock: this.empresa.stock,
                    anticipacion: (this.empresa.modalidad == 'productos') ? this.empresa.anticipacion : null,
                    perfiles: this.empresa.perfiles,
                    unidades: unidades                
            }).then(()=>{
                Swal.fire('Todo listo', 'La empresa '+ this.empresa.nombre + ' fue agregada','success').then(()=>{
                    this.$emit('empresa-creada');
                })
            }).catch(error=>{
                if (error) {
                    console.log(error);
                }
            })
        },
        /**
         * Filtra los productos seleccionados de los stepsDinamicos (unidad) para subir a firebase 
         * solo la informacion seleccionada con los precios seleccionados.
         * @param unidad es una unidad de negocio listada en stepsDinamicos (genera las tabs)
         */
        soloProductosSeleccionados(unidad){
            let productosSeleccionados = Object.keys(unidad.unidad.productos).flatMap(producto => {
                if (unidad.unidad.productos[producto].seleccionado == true) {
                    return {
                        precio: unidad.unidad.productos[producto].precio,
                        nombre: unidad.unidad.productos[producto].nombre
                    }
                }else {
                    return []
                }
            });
            if (productosSeleccionados.length == 0) {
                productosSeleccionados.push({
                        precio: null,
                        nombre: "Ningún producto seleccionado"
                });
            }
            return productosSeleccionados
        },
        abrirModalVerMas(producto){
            this.modalVerMas.producto = producto;
            this.modalVerMas.open = true;
        },
        /**
         * Métodos del Wizard
         */

        /**
         * Recorre los steps a través de la cantidad de iniciales y dinámicos
         * Los pone en display=none y el current en block
         */
        refrescarTabs(){
            for (let i = 0; i < this.stepsIniciales+this.stepsDinamicos.length; i++) {
                if(i!=this.currentStep){
                    this.$refs["step-"+i].style.display = "none";
                }
            }
            if (this.lastStep) {
                this.$refs["step-final"].style.display = "block";
            }else {
                this.$refs["step-"+this.currentStep].style.display = "block";
                this.$refs["step-final"].style.display = "none";
            }

        },
        siguiente(){
            // Se verifican los datos introducidos
            if (this.sonInvalidosLosDatosActuales()) return null
            // Si es válido se pasan las tabs
            if (this.currentStep < (this.stepsDinamicos.length + this.stepsIniciales)-1) {
                this.lastStep = false;
                this.currentStep++;
                this.refrescarTabs();
                // Si se llama en cada cambio se pueden activar compoertamientos en tabs dinámicamente
                // this.productosEspecificosCheckbox(this.currentStep,this.elements[this.currentStep-2]);
                if (this.currentStep >= this.stepsIniciales) {
                    this.productosEspecificosSeleccionados(this.stepsDinamicos[this.currentStep-this.stepsIniciales]);
                }
            }else {
                this.lastStep=true;
                this.currentStep++;
                this.refrescarTabs();
            }
        },
        anterior(){
            if (this.currentStep>0){
                this.lastStep = false;
                if (!this.lastStep) this.currentStep--;
                this.refrescarTabs();
            }
        },
        seleccionarStep(i,final){
            if(!final){
                this.currentStep = i;
                this.lastStep = false;
                this.refrescarTabs();
            }else {
                this.currentStep = i;
                this.lastStep = true;
                this.refrescarTabs();
            }
        },
        sonInvalidosLosDatosActuales(){
            this.limpiarErrores();
            let errores = false;

            // First step validation
            if (this.currentStep == 0 && (this.empresa.nombre == null || this.errores.empresaExistente)) {
                this.errores.nombre = true;
                errores = true;
            }
            
            // Validación en modalidad contador (horario de turno)
            if (this.currentStep == 1 && this.empresa.modalidad == 'contador'){
                if (!this.empresa.contador.desde.length || !this.empresa.contador.hasta.length) {
                    this.errores.contadorDesdeHasta = true;
                    errores = true;
                }
            } else if (this.currentStep == 1 && this.empresa.modalidad == 'productos'){
                // console.log("Estas en la modalidad productos");
            }   

            // Validacion de perfiles
            if (this.currentStep == 2 && (this.triggers.perfiles == true && !this.empresa.perfiles.length)) {
                this.errores.perfilesIncompletos = true;
                errores = true;
            }

            // Puede validar si seleccionas elementos para crear steps dinámico
            if (this.currentStep == 3 && !this.stepsDinamicos.length) {
                // Setea la propiedad errores
                this.errores.unidades = true;
                errores = true;
            }     

            return errores
        },
        limpiarErrores(){
            this.errores.nombre = false;
            this.errores.contadorDesdeHasta = false;
            this.errores.empresaExistente = false;
            this.errores.perfilesIncompletos = false;
        }

    }
}
</script>

<style>
.form-control {
	display: inline-block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 1.75rem 0.375rem 0.75rem;
	font-size: .8rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	vertical-align: middle;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.custom-select{
    font-size: .8rem;
}

.form-check-input {
    width: 16px;
    height: 16px;
}

.textarea {
	height: auto;
}

.header-u {
    box-shadow: none !important;
    padding: .5rem 1rem;
    margin-bottom: 0;
    /* background-color: rgba(0,0,0,.03); */
    border-bottom: none;
}

.card-u {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    box-shadow: none;
}

.checkBox {
    padding-left: 1.25rem;
}

.checkBoxLabel {
    margin-left: 0 !important;
}

.has-error {border:1px solid rgb(185, 74, 72) !important;}
    /* .wizard-step {
        height: 300px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: flex-start;
        align-items: center;     
    }

    .wizard-content {
        width: 90%;
    } */
</style>