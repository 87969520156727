<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-body">
                            <h5 class="mb-2">Estás modificando la etiqueta: {{selected.nombre}}</h5>
                            <div>
                                <p class="my-2" style="font-size:14px;font-weight:normal">Nuevo nombre</p>
                                <input type="text" class="form-control" v-model="name">
                                <div class="text-danger mt-1" v-if="error.name.length">&otimes; {{error.name}}</div>
                            </div>
                            <div class="text-right mt-3">
                                <button
                                    type="button"
                                    @click.prevent="$emit('end',false)"
                                    class="btn btn-light mr-2"
                                >
                                    Cancelar
                                </button>
                                <button
                                type="button"
                                @click.prevent="editarReceta"
                                class="btn btn-success"
                                >
                                Editar etiqueta
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
// import axios from 'axios';
/*eslint-disable*/
let fireRecetario = firebase.apps[1];

export default {
    name: "EliminarReceta",
    props: ['selected','recetasFull','recetasPorTag','tags'],
    data(){
        return {
            name:null,
            error:{name:''},
        }
    },
    mounted(){
        
    },
    methods: {
        editarReceta(event){
            // Se validan los inputs
             
            this.error.name = '';

            let valid = true;
            if(this.name == null || !this.name.length){
                this.error.name = 'El nombre es obligatorio';
                valid = false;
            }

            // Si no son validos se corta el submit con errores
            if(!valid) return null;

            // Se valida la existencia
            let exist = Object.keys(this.tags).find((fId)=>{
                return this.tags[fId].nombre.trim().toLowerCase() == this.name.trim().toLowerCase();
            });

            if(exist) {
                this.error.name = 'El nombre ya existe';
                return null;
            }

            // Se actualiza la vista para mostrar es estado de carga
            event.target.innerHTML = `
                <div class="spinner-border" style="width:12px;height:12px" role="status">
                    <span class="sr-only">Loading...</span>
                </div> Editando..
            `
            event.target.disabled = true;
            for (let input of document.querySelectorAll('form input,form select')) {
                input.disabled = true;
            }
            
            
            fireRecetario.database().ref(`${this.selected.tipo}/${this.selected.id}`).update({nombre:this.name})
            .then(()=>{
                console.log('etiqueta modificada');
                // if(this.newTag != null){
                    let promises = [];
                    this.recetasPorTag.forEach((id)=>{
                        let tipo = (this.selected.tipo == 'tipo_entradas')?'entrada':'condicion';
                        let newTags = this.recetasFull[id][tipo].flatMap((t)=>{
                            if(t==this.selected.nombre || t==this.name) return []
                            return t
                        });

                        newTags.push(this.name);

                        promises.push( fireRecetario.database().ref(`recetas/${id}/${tipo}`).update(newTags) );
                    })

                    Promise.all(promises).then(()=>{
                        console.log('productos modificados');
                        this.$emit('end',true)
                    })
                // }

            }).catch(error=>{
                if(error){
                    console.log(error);
                    alert("Se produjo un error, actualiza la página (tecla: F5), intentá nuevamente o comunicate con sistemas")
                }
            })
        

        },
        
    }
}
</script>

<style scoped>
h4 {
    margin: 0;
    text-align: center;
    color: #1ea21e;
}

mark {
    background: none;
    text-decoration: underline red 3px;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 60%;
    margin: 0px auto;
    padding: 6px 20px 1px 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

    /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>