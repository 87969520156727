<template>
  <div class="page-content">
		<!-- Breadcrumb -->
		<nav class="page-breadcrumb" id="breadcrumb">
			<ol class="breadcrumb">
			<li class="breadcrumb-item">{{breadcrumbA}}</li>
			<li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
			</ol>
		</nav>
		<!-- Page title -->
		<div class="row">
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
				<h6 class="card-title">{{breadcrumbB}}</h6>
				<div class="d-flex align-items-center">
					<p class="card-description w-75 p-0 m-0">{{ descripcion }}</p>
					<button
						v-on:click="crearEmpresa()"
						type="button"
						class="btn w-25" 
						:class="{'btn-light': triggers.crearEmpresa,'btn-success':!triggers.crearEmpresa}"
					>
						{{(!triggers.crearEmpresa) ? 'Crear una nueva empresa' : 'Cancelar la creación'}}
					</button>
				</div>
				</div>  
			</div>
			</div>
		</div> 
		<!-- Template content  -->
		<div class="row" v-if="triggers.crearEmpresa">
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
                    <!-- <form-nueva-empresa></form-nueva-empresa> -->
					<wizard-nueva-empresa
						@empresa-creada="actualizarListado()"
						:empresas="empresasActivas"
					></wizard-nueva-empresa>
				</div>
			</div>
			</div>
		</div>
		<!-- Template content  -->
		<div class="row">
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
                    <lista-empresas 
						@modificar-empresa="seleccionarModificar($event)" 
						:key="keyListado">
					</lista-empresas>
				</div>
			</div>
			</div>
		</div>
		<!-- Template content  -->
		<div class="row" v-if="triggers.modificarEmpresa">
			<div class="col-md-12 grid-margin stretch-card">
				<div class="card">
					<div class="card-body">
						<wizard-modificar-empresa :empresaSeleccionada="empresaSeleccionada" @empresa-modificada="actualizarListado()"></wizard-modificar-empresa>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { apis } from "../../.env";
import axios from 'axios'

import ListaEmpresas from '../components/Empresa/ListaEmpresas.vue';
import WizardModificarEmpresa from '../components/Empresa/WizardModificarEmpresa.vue';
import WizardNuevaEmpresa from '../components/Empresa/WizardNuevaEmpresa.vue';
export default {
    name: 'Empresas',
    components:{ListaEmpresas, WizardNuevaEmpresa, WizardModificarEmpresa,},
	// components:{ListaEmpresas, WizardNuevaEmpresa,},
    data() {
        return {
			breadcrumbA: 'Inicio',
            breadcrumbB: 'Empresas',
            descripcion: 'Agregá nuevas empresas y modifica las existentes',
			empresasActivas: [],
			empresaSeleccionada: null,
            triggers: {
				crearEmpresa: false,
				modificarEmpresa: false,
			},
			keyListado: 0,
        }
    },
	mounted(){
		axios.get(apis.empresasConsumos)
        .then(empresas=>{
            this.empresasActivas = empresas.data.flatMap(empresa=>{
                if (empresa.estado == "INACTIVA") return []
				// Se crea una propiedad text para poder utilizarse en Select2 
				// y un nombre para cualquier otro propósito
                return {
                    id: empresa.id,
                    text: empresa.name,
					nombre: empresa.name,
                    catering_id: empresa.catering_id,
                    centroapropiacion_id: empresa.centroapropiacion_id,
                    tipo_empresa: empresa.tipo_empresa,
                }

            })
        })
	},
	methods: {
		seleccionarModificar(data){
			this.triggers.modificarEmpresa = false;
			this.triggers.crearEmpresa = false;
			setTimeout(() => {
				this.empresaSeleccionada = data;
				this.triggers.modificarEmpresa = true;
			}, 200);
		},
		crearEmpresa(){
			this.triggers.modificarEmpresa = false;
			this.triggers.crearEmpresa = !this.triggers.crearEmpresa;
		},
		actualizarListado(){
			this.triggers.modificarEmpresa = false;
			this.triggers.crearEmpresa = false;
			this.keyListado++;
		}
	}
}
</script>

<style>

</style>