<template>
  <lista-carga v-if="load" :card="false"></lista-carga>
  <div class="row" v-else>
    <div class="col">
      <label class="tabla-label">{{title}}</label>
      <div class="table-responsive">
        <table :id="tipo" class="table" width="100%">
          <thead>
            <tr>
              <th>Nombre</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(tag, i) in tags" v-bind:key="i">
              <td>{{tag.nombre}}</td>
              
              <td class="text-right">
                <button
                  type="button"
                  class="btn btn-success btn-sm"
                  @click="handleAction('update',tag,i)"
                >
                  Modificar
                </button>
                <button
                  type="button"
                  class="btn btn-danger btn-sm ml-3"
                  @click="handleAction('delete',tag,i)"
                >
                  Eliminar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- <modal-eliminar-receta
    v-if="modalEliminarReceta.open"
    :receta="modalEliminarReceta.receta"
    @cerrar-eliminar="cerrarModal($event)"
  ></modal-eliminar-receta> -->
</template>
<script>
// import axios from "axios";
// import firebase from "firebase/app";
// import "firebase/database";
import ListaCarga from "../../ListaCarga";
import $ from "jquery";
import { tableConfig } from "../../../../.env";
// import ModalEliminarReceta from '../modals/EliminarReceta'

export default {
  name: "ListTags",
  props: ["tags",'title','tipo'],
  components: { ListaCarga },
  data() {
    return {
      load: true,
      // tableId:null,
      // modalEliminarReceta: {
      //   open: false,
      //   receta: null,
      // }
    };
  },
  methods: {
    handleAction(action,tag,id) {
      this.$emit(action, {
        tipo:this.tipo,
        nombre:tag.nombre,
        id:id
      });
    },
    abrirModalEliminar(receta){
      this.modalEliminarReceta.receta = receta;
      this.modalEliminarReceta.open = true;
    },
    cerrarModal(actualizacion){
      this.modalEliminarReceta.open = false;
      if (actualizacion) this.$emit('actualizar-lista');
    },
    mostrarDataOGuion(data) { if (data) { return data; } else { return "-"; } },
  },
  mounted() {
    if (this.tags) {
      // this.tableId = 'id' + parseInt(Math.random()*100);
      setTimeout(() => {
        this.load = false;
        let config = tableConfig;
        config.pageLength = 8;
        this.$nextTick(() => {
          $(`#${this.tipo}`).dataTable(config);
        });
      }, 200);
    }
  },
};
</script>

<style scoped>
.tabla-label {
  position: absolute;
  top: 0;
  font-size: large;
  margin-bottom: 0;
}

.form-control {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.textarea {
  height: auto;
}

.img-icon {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 30%;
}

.texto-incompleto {
  width: 96px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
