<template>
  <form>
    <div class="form-row">
      <div class="col mb-3">
        <h4>Añadir nuevo plato</h4>
      </div>
    </div>
    <div class="form-row">
      <div class="col-12 mb-3">
        <label for="recetas" class="form-label">Seleccione recetas</label>

        <multiselect 
          v-model="plato.recetas"
          mode="tags"
          :close-on-select="false"
          :searchable="true"
          :create-option="false"
          :options="Object.keys(recetas).map((r)=>{return {label:`(${recetas[r].codigo}) ${recetas[r].titulo}`,value:r}})"
          class="multiselect-blue"
          :placeholder="'Agregue recetas, puede buscar y seleccionar'"
          
        ></multiselect>
      </div>

      <div class="col-12 mb-3">
        
          <ul class="list-group">
            <li class="list-group-item" v-for="(receta,r) in plato.recetas" :key="r">
              <div class="row align-items-center">
                <div class="col-1">
                  <img :src="recetas[receta].imagen" style="width:32px;height:32px;object-fit:cover" class="rounded">
                </div>
                <div class="col-6">{{recetas[receta].titulo}} {{(recetas[receta].kcal!=undefined)? `(Kcal: ${recetas[receta].kcal})` : ''}}</div>
                <div class="col">
                  <div class="form-group form-check">
                    <input type="checkbox" class="form-check-input" :checked="plato.receta_principal==receta" @change="handleMainDish($event,receta)">
                    <label class="form-check-label" for="exampleCheck1">Es principal</label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group form-check">
                    <input type="checkbox" class="form-check-input" :checked="plato.image==receta" @change="handleMainImage($event,receta)">
                    <label class="form-check-label" for="exampleCheck1">Utilizar imagen</label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        
      </div>
    </div>

    <div class="form-row">
      <div class="col-12 col-md-4 mb-3">
        <label for="recetas" class="form-label">Nombre de fantasía</label>
        <input type="text" class="form-control" v-model="plato.name">
      </div>
      <div class="col-12 col-md-4 mb-3">
        <label for="recetas" class="form-label">Categoria</label>
        <select class="custom-select" v-model="plato.category">
          <option :value="null" selected disabled>Seleccioná un tipo de entrada</option>
          <option :value="c" v-for="(category,c) in categories" :key="c">{{category.nombre}}</option>
        </select>
      </div>
      <div class="col-12 col-md-4 mb-3">
        <label for="recetas" class="form-label">Kilocalorias</label>
        <input type="text" class="form-control" v-model="plato.kcal">
      </div>
      <div class="col-12 mb-3">
        <label for="recetas" class="form-label">Descripción</label>
        <textarea class="form-control w-100" rows="4" v-model="plato.description"></textarea>
      </div>
    </div>

    <div class="form-row">
      <div class="col text-right">
        <button class="btn btn-success" @click.prevent="submit">Crear plato</button>
      </div>
    </div>
  </form>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import firebase from "firebase/app";
import "firebase/database";

// Firebase guarda cada instancia inicalizada en el array apps. Ladefault queda en el indice 0 y el recetario en el 1
let fireRecetario = firebase.apps[1];

export default {
  name:'CreatePlato',
  props:{recetas:Object,categories:Object},
  components:{Multiselect},
  data() {
    return {
      plato:{
        name:null,
        recetas:[],
        receta_principal:null,
        description:null,
        category:null,
        kcal:null,
        image:null,
      },
      error:{},
    }
  },
  methods: {
    handleMainDish(e,d){
      if(e.target.checked){
        this.plato.receta_principal = d;
      }else {
        this.plato.receta_principal = null;
      }
    },
    handleMainImage(e,d){
      if(e.target.checked){
        this.plato.image = d;
      }else {
        this.plato.image = null;
      }
    },
    submit(){
      fireRecetario.database().ref(`platos`).push(this.plato)
      .then(()=>{
        this.$emit('end',true);
      })
      .catch(error=>{
        if (error) {
          console.log(error);
          alert("Se produjo un error al crear un plato, actualice la página (tecla: F5), reintente o consulte a sistemas");
        }
      });
    }
  },
  mounted() {
    // console.log(JSON.parse(JSON.stringify(Object.keys(this.recetas).map((r)=>{return {label:this.recetas[r].titulo,id:this.recetas[r].codigo}}))))
  },
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect-blue {
    --ms-tag-bg: #DBEAFE !important;
    --ms-tag-color: #2563EB !important;
    --ms-ring-color: #2563EB20 !important;
    
    --ms-tag-font-size: 0.8rem !important;

    --ms-border-color: #282f3ae6 !important;
    --ms-py: 0.375rem !important;
    --ms-px: 0.75rem !important;

    --ms-option-font-size: .8rem !important;
    
    --ms-dropdown-border-color: #282f3ae6 !important;
}

.form-check .form-check-label {
    margin-left: 0;
    margin-bottom: 0;
    bottom: -3px;
    left: 4px;
    position: relative;
}

</style>