<template>
  <lista-carga v-if="load" :card="false"></lista-carga>
  <div class="row" v-else>
    <div class="col">
      <label class="tabla-label">Lista de recetas</label>
      <div class="table-responsive">
        <table id="recetas" class="table" width="100%">
          <thead>
            <tr>
              <th>Imagen</th>
              <th>Cod.</th>
              <th>Titulo</th>
              <th>Entrada</th>
              <th>Condición</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(receta, i) in recetas" v-bind:key="i">
              <td><img :src="receta.imagen" /></td>
              <td>{{ receta.codigo }}</td>
              <td>{{ receta.titulo }}</td>
              <td><div class="badge badge-primary mr-1" v-for="(entrada,i) in receta.entrada" :key="i">{{entrada}}</div></td>
              <td><div class="badge badge-primary mr-1" v-for="(condicion,i) in receta.condicion" :key="i">{{condicion}}</div></td>
              <td class="">
                <button
                  type="button"
                  class="btn btn-success btn-sm"
                  @click="administrar(receta)"
                >
                  Modificar
                </button>
                <button
                  type="button"
                  class="btn btn-danger btn-sm ml-3"
                  @click="abrirModalEliminar(receta)"
                >
                  Eliminar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <modal-eliminar-receta
    v-if="modalEliminarReceta.open"
    :receta="modalEliminarReceta.receta"
    @cerrar-eliminar="cerrarModal($event)"
  ></modal-eliminar-receta>
</template>
<script>
// import axios from "axios";
// import firebase from "firebase/app";
// import "firebase/database";
import ListaCarga from "../../ListaCarga";
import $ from "jquery";
import { tableConfig } from "../../../../.env";
import ModalEliminarReceta from '../modals/EliminarReceta'

export default {
  name: "ListaRecetas",
  props: ["recetas"],
  components: { ListaCarga, ModalEliminarReceta },
  data() {
    return {
      load: true,
      modalEliminarReceta: {
        open: false,
        receta: null,
      }
    };
  },
  methods: {
    administrar(receta) {
      this.$emit("modificar-receta", receta);
    },
    abrirModalEliminar(receta){
      this.modalEliminarReceta.receta = receta;
      this.modalEliminarReceta.open = true;
    },
    cerrarModal(actualizacion){
      this.modalEliminarReceta.open = false;
      if (actualizacion) this.$emit('actualizar-lista');
    },
    mostrarDataOGuion(data) { if (data) { return data; } else { return "-"; } },
  },
  mounted() {
    if (this.recetas) {
      setTimeout(() => {
        this.load = false;
        let config = tableConfig;
        config.pageLength = 8;
        this.$nextTick(() => {
          $("#recetas").dataTable(config);
        });
      }, 200);
    }
  },
};
</script>

<style scoped>
.tabla-label {
  position: absolute;
  top: 0;
  font-size: large;
  margin-bottom: 0;
}

.form-control {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.textarea {
  height: auto;
}

.img-icon {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 30%;
}

.texto-incompleto {
  width: 96px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
