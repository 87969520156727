<template>
  <div class="page-content">
		<!-- Breadcrumb -->
		<nav class="page-breadcrumb" id="breadcrumb">
			<ol class="breadcrumb">
			<li class="breadcrumb-item">{{breadcrumbA}}</li>
			<li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
			</ol>
		</nav>
		<!-- Page title -->
		<div class="row">
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
				<h6 class="card-title">{{breadcrumbB}}</h6>
				<p class="card-description"> {{descripcion}}</p>
				</div>  
			</div>
			</div>
		</div> 
		<!-- Template content  -->
		<div class="row">
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
                    <form-nuevo-producto></form-nuevo-producto>
				</div>
			</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import FormNuevoProducto from '../components/Producto/FormNuevoProducto';
export default {
    name: 'Productos',
    components:{FormNuevoProducto},
    data() {
        return {
			breadcrumbA: 'Inicio',
            breadcrumbB: 'Producto',
            descripcion: 'Agregá productos nuevos, modifica los existentes',
            
        }
    },
}
</script>

<style>

</style>