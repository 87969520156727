<template>
    <!-- <lista-carga v-if="load" :card="false"></lista-carga> -->
    <div class="row">
        <div class="col">
            <div class="div" ref='step-0'>
                <h4 class="mb-2" ref="titulo">Estas modificando la empresa <div class="badge badge-warning">{{empresaSeleccionada.nombre}}</div></h4> 
                 <h5 class="mb-2 mt-4">Asigná las unidades de negocio</h5>
                <div class="alert alert-info" v-if="!errores.unidades && !unidadesDeNegocioSinProducto.length">Luego es posible seleccionar los productos y precios para cada unidad.</div>
                <div class="alert alert-danger" v-if="errores.unidades">No es posible crear una empresa sin unidades de negocio, seleccioná una</div>
                <div class="form-row pl-2">
                    <div class="col-4 mb-2" v-for="(unidad,i) in unidadesDeNegocio" :key="i">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" :ref="'checkbox-'+i" value="false" @click="unidadCheckbox(i,$event,unidad)">
                            <label class="form-check-label ml-1" for="checkPrecio">{{unidad.nombre}} <div class="badge badge-light">{{unidad.cantidadProductos}} productos</div></label>
                        </div>
                    </div>
                </div>
                <div class="alert alert-info" v-if="unidadesDeNegocioSinProducto.length">
                    Atención las siguientes unidades de negocio no se muestran porque no tienen productos asignados: <span class="font-weight-bold" v-for="u,k in unidadesDeNegocioSinProducto" :key="k">{{u.nombre}},&nbsp;</span>
                </div>
            </div>
            <div v-for="(element,i) in elements" :key="element.elementId" :ref="'step-'+(i+1)">
                <h4 class="mb-2">Estas modificando la empresa <div class="badge badge-light">{{empresaSeleccionada.nombre}}</div> en la unidad <div class="badge badge-warning">{{element.unidad.nombre}}</div></h4> 
                <div class="row">
                    <div class="col">
                        <lista-carga v-if="element.productsLoad" :card="false" class="my-3"></lista-carga>
                        <div class="row" v-else>
                        <div class="col-12 mb-3">
                            <table :id="'productos-step-'+(i+1)" :ref="'table-'+(i+1)" class="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Nombre</th>
                                    <!-- <th>Detalle</th> -->
                                    <th>Precio</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                                <tbody>
                                    <tr v-for="(producto,i) in element.unidad.productos" v-bind:key="i" class="">
                                        <td><input class="" type="checkbox" v-model="producto.seleccionado" @change="refreshInputPrecio($event,producto)"></td>
                                        <td>{{ producto.nombre }}</td>
                                        <!-- <td>{{ producto.detalle }}</td> -->
                                        <td>
                                            <div class="form-row align-items-center">
                                                <div class="col">
                                                    <input type="text" class="form-control" v-model="producto.precio" :disabled="!producto.precioActivo" placeholder="0.00"/>
                                                </div>
                                                <div class="col">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox" id="checkPrecio" v-model="producto.precioActivo" @change="refreshInputPrecio($event,producto)" value="false">
                                                        <label class="form-check-label ml-1" for="checkPrecio">Tiene precio</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td><div class="btn btn-primary" @click="abrirModalVerMas(producto)">Ver info</div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="div" ref='step-final'>
                <h4>Empresa: <div class="badge badge-success">{{empresaSeleccionada.nombre}}</div></h4>
                <h5 class="mt-3">Unidades y productos</h5>
                <div class="row">
                    <div class="col-4 my-2"  v-for="(element,i) in elements" :key="i">
                        <div class="card card-u rounded">
                            <div class="card-header header-u bg-light">{{element.unidad.nombre}}</div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item" v-for="(producto,i) in soloProductosSeleccionados(element)" :key="i">
                                    <div class="d-flex">
                                        <div class="w-75">{{ producto.nombre }}</div>
                                        <div class="w-25 text-right">
                                            <div class="badge badge-light">$ {{ (producto.precio) ? producto.precio : "-"}}</div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 mt-2 mt-4 text-right">
            <div class="btn btn-light mr-2" @click="anterior()"><svg  width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg> Anterior</div>
            <div class="btn btn-success" @click="siguiente()" v-if="!lastStep">Siguiente <svg  width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg></div>
            <div class="btn btn-success" @click="modificarEmpresa()" v-else><svg  width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg> Crear nueva empresa</div>
        </div>
    </div>
     <modal-lista-ver-mas 
        v-if="modalVerMas.open" 
        :producto="modalVerMas.producto"
        @cerrar-modal-ver-mas="modalVerMas.open = false"
    ></modal-lista-ver-mas>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";
import $ from "jquery";
require("datatables.net");
import { apis,productoConElId,tableConfig, productosEnUnidad } from "../../../.env";
import ListaCarga from "../ListaCarga";
import Swal from 'sweetalert2';
import  ModalListaVerMas  from "../Producto/modal/ModalListaVerMas";
export default {
    name: "WizardModificarEmpresa",
    props: ['empresaSeleccionada'],
    components: {ListaCarga,ModalListaVerMas},
    data(){
        return {
            // load: true,
            currentStep: 0,
            lastStep:false,
            unidadesDeNegocio: null,
            unidadesDeNegocioSinProducto:[],
            elements: [],
            productosOrdenados:[],
            errores: {
                nombre: false,
                unidades: false,
                empresaExistente: false,
            },
            modalVerMas: {
                open:false,
                producto: null
            }
        }
    },
    mounted(){
        // console.log(this.empresaSeleccionada);
        this.obtenerUnidadesDeNegocio().then(()=>{
            // ParseUnidades.. devulve una promesa cumplida. Al cumplirse actualizamos la tabs
            this.parseUnidadesRecibidasToElements().then(()=>{
                this.vincularCheckboxConUnidadesRecibidas().then(()=>{
                    
                })
            });
        })
        setTimeout(() => {
            // Use el.scrollIntoView() to instantly scroll to the element
            this.$refs.titulo.scrollIntoView({behavior: 'smooth'});
            
           this.refrescarTabs();
        }, 1000);
    },
    methods: {
        /**
         * Obtiene los productos específicos de la unidad para mostrar un data table
         * que permite seleccionarlos y fijar precios. A través de elementStep configura el data table
         * @param elementStep el numero de tab que corresponde a esta unidad
         * @param elemento el elemento (unidad) de la propiedad element que genera la tab
         */
        productosEspecificosStepActual(elementStep, elemento){
            // Si ya se cargaron los producto sale
            if (elemento.productsLoad == false) return null
            
            // Donde se listarán los precios por fireId (facilitará ponerles precio ya que se le asigna al id que existe en el elementos)
            let productosOrdenados = [];

            axios
            .get('https://fs-comedores-admin-default-rtdb.firebaseio.com/unidades_negocio/'+elemento.unidad.fireId+'.json?print=pretty')
            .then((unidadResponse) => {
                const unidadData = unidadResponse.data;                
                if(unidadData.productos == undefined) return false
                // Cuando todas las promesas (info de cada propducto) estén listas se les asignara el precio y la seleccion
                let promesas = [];
                // Se recorren todas los productos de la unidad
                Object.keys(unidadData.productos).forEach( (id) => {                    
                    // Se obtiene la información completa del producto
                    let promesa = axios
                    .get(productoConElId(unidadData.productos[id].producto_id))
                    .then((productoResponse) => {
                        // Ordenamos el producto a traves del fireId
                        productosOrdenados[unidadData.productos[id].producto_id] = {
                            producto_id: unidadData.productos[id].producto_id,
                            nombre: productoResponse.data.nombre,
                            precio: null,
                            imagen: (productoResponse.data.imagen != undefined) ? productoResponse.data.imagen : null,
                            info_nutricional: (productoResponse.data.info_nutricional != undefined) ? productoResponse.data.info_nutricional : null,
                            modo_preparacion: (productoResponse.data.modo_preparacion != undefined) ? productoResponse.data.modo_preparacion : null,
                            detalle: productoResponse.data.detalle,
                            // precioActivo: (unidadData.productos[id].precio != undefined) ? true : false,
                            precioActivo: false,
                            seleccionado: false,
                        }
                    });
                    // Guardamos cada promesa para ver si se cumple mas abajo
                    promesas.push(promesa);
                })
                
                // Cuando todas las promesas se cumplieron, tenemos productos con toda la información.
                Promise.all(promesas).then(()=>{
                    // console.log(productosOrdenados);
                    // Ahora se recorren los productos de la unidad seleccionada
                    Object.keys(elemento.unidad.productos).forEach((productoIndex) => {
                        if(productosOrdenados[elemento.unidad.productos[productoIndex].producto_id]!=undefined){
                            // console.log(productosOrdenados[elemento.unidad.productos[productoIndex].producto_id].seleccionado);
                            // Si al producto se le asigno un precio
                            if(elemento.unidad.productos[productoIndex].precio != undefined){
                                // Se lo asignamos al producto full obtenido anteriormente y solo accedemos al elemento (O(n))
                                if(productosOrdenados[elemento.unidad.productos[productoIndex].producto_id] != undefined){
                                    productosOrdenados[elemento.unidad.productos[productoIndex].producto_id].precio = elemento.unidad.productos[productoIndex].precio
                                    productosOrdenados[elemento.unidad.productos[productoIndex].producto_id].precioActivo = true
                                }
                                // productosOrdenados[elemento.unidad.productos[productoIndex].producto_id].precio = elemento.unidad.productos[productoIndex].precio;
                            }
                            // Si se lista es porque el producto fué seleccionado, entonces se le asigna tru a seleccionado
                            productosOrdenados[elemento.unidad.productos[productoIndex].producto_id].seleccionado = true;
                        }
                    })
                    elemento.unidad.productos = [];
                    Object.keys(productosOrdenados).forEach((el) => {
                        elemento.unidad.productos.push(productosOrdenados[el]);
                    })
                }).then(() => {
                    // Todos los productos tienen su precio original y aparecen como seleccionado, listos para listar
                    elemento.productsLoad = false;
                    this.$nextTick(()=>{
                        let config = tableConfig;
                        config.buttons = [];
                        config.pageLength = 6;
                        $(this.$refs['table-'+(elementStep)]).dataTable(config);
                    })
                })
            })
        },
        /**
         * A través del event toma el value de un checkbox que pertenece al producto
         * de un element (productos por unidades). Asigna al producto el precio y si este esta activo.
         * @param event evento de un checkbox, nos da el target
         * @param producto de un element (unidads de negocio seleccionada)
         */
        refreshInputPrecio(event, producto){
            if (!event.target.checked) {
                producto.precio = null;
                producto.precioActivo = false; 
            }
        },
        /**
         * Obtiene las unidades desde firebase y las formatea.
         * @return una promesa cumplida
         */
        obtenerUnidadesDeNegocio(){
            let promesa = new Promise((resolve) => {
                axios
                .get(apis.unidadesDeNegocio)
                .then((responseUnidades) => {

                    this.unidadesDeNegocio = Object.keys(responseUnidades.data).flatMap((unidad)=>{
                        if((responseUnidades.data[unidad].productos != undefined && !Object.keys(responseUnidades.data[unidad].productos).length || responseUnidades.data[unidad].productos == undefined)) {
                            this.unidadesDeNegocioSinProducto.push({nombre:responseUnidades.data[unidad].nombre});
                            return []
                        }
                        return {
                            nombre: responseUnidades.data[unidad].nombre,
                            cantidadProductos: (responseUnidades.data[unidad].productos != undefined) ? Object.keys(responseUnidades.data[unidad].productos).length : 0,
                            productos: responseUnidades.data[unidad].productos,
                            empresas: responseUnidades.data[unidad].empresas,
                            fireId: unidad,
                            seleccionada: false,
                        }
                    })
                    return resolve();
                })
                .catch((error) => {
                    console.log(error);
                    alert("Se produjo un error");
                });
            });

            return promesa;
        },
        /**
         * Las unidades que posee la empresa se guardan en elements con la estructura determinada
         * @return una promesa cumplida
         */
        parseUnidadesRecibidasToElements(){
            var transform = new Promise((resolve) => {
                this.elements = Object.keys(this.empresaSeleccionada.unidades).map(id=>{
                    return {
                        elementId: null,
                        //  NO el element id depende de la unidad
                        productsLoad: true,
                        unidad: this.empresaSeleccionada.unidades[id],
                    }
                });
                return resolve()
            });
            return transform;
        },
        /**
         * Recorre todas las unidades de negocio y una a una comprueba si existe en elements
         * Al element encontrado le asigna el elementId que depende del indice de la unidad en unidadesDeNegocio
         * Finalmente se busca el checkbox y se lo checkea
         */
        vincularCheckboxConUnidadesRecibidas(){
            let promesa = new Promise((resolve) => {
                Object.keys(this.unidadesDeNegocio).forEach((unidadIndex) => {
                    Object.keys(this.elements).forEach((elIndex) => {
                        if (this.elements[elIndex].unidad.fireId == this.unidadesDeNegocio[unidadIndex].fireId) {
                            // Acá se actualiza el elementId y a través del ref padre de los checkbox actualizamos el value
                            this.elements[elIndex].elementId = parseInt(unidadIndex);
                            this.$refs['checkbox-'+unidadIndex].checked = true;
                            // Se aplica esta propiedad para poder mostar un mensaje al deseleccionar ver unidadCheckbox()
                            this.unidadesDeNegocio[unidadIndex].unidadExistente = true;
                           
                        }
                    })
                })
            });
            return promesa
        },
        /**
         * Filtra los productos seleccionados del element (unidad) para subir a firebase 
         * solo la informacion seleccionada con los precios seleccionados.
         * @param element es una unidad de negocio listada en elements (genera las tabs)
         */
        soloProductosSeleccionados(element){
            let productosSeleccionados = Object.keys(element.unidad.productos).flatMap(producto => {
                if (element.unidad.productos[producto].seleccionado == true) {
                    return {
                        precio: element.unidad.productos[producto].precio,
                        nombre: element.unidad.productos[producto].nombre
                    }
                }else {
                    return []
                }
            });
            if (productosSeleccionados.length == 0) {
                productosSeleccionados.push({
                        precio: null,
                        nombre: "Ningún producto seleccionado"
                });
            }
            // console.log(productosSeleccionados);
            return productosSeleccionados
        },
        /**
         * Prepara la informacion de elements para subir a firebase
         * Lo tranforma en (product_id: -FiReId, precio: 250)
         */
        modificarEmpresa(){
            let unidades = Object.keys(this.elements).map((unidad)=>{
                let productosSeleccionados = Object.keys(this.elements[unidad].unidad.productos).flatMap(producto => {
                    if (this.elements[unidad].unidad.productos[producto].seleccionado == true) {
                        return {
                            precio: this.elements[unidad].unidad.productos[producto].precio,
                            producto_id: this.elements[unidad].unidad.productos[producto].producto_id
                        }
                    }else {
                        return []
                    }
                })
                return{
                    unidad_id: this.elements[unidad].unidad.fireId,
                    productos: productosSeleccionados
                }
            });
            // console.log(unidades);
            firebase.database().ref("empresas/" + this.empresaSeleccionada.id_empresa + "/unidades").remove()
            .then(()=>{
                firebase.database().ref("empresas/" + this.empresaSeleccionada.id_empresa).update({
                    unidades: unidades
                }).then(()=>{
                    Swal.fire('Todo listo', 'La empresa '+ this.empresaSeleccionada.nombre + ' fue modificada','success').then(()=>{
                        this.$emit('empresa-modificada');
                    })
                }).catch(error=>{
                    if (error) {
                        alert("Se produjo un error al modificar la empresa")
                        console.log(error);
                    }
                })
            })
            
        },
        unidadCheckbox(elementId, value, unidad){
            if(unidad.unidadExistente){
                value.target.checked = true
                Swal.fire('¡Atención!', 'La unidad tiene productos asignados si se des-selecciona perderás todos los cambios','warning').then(()=>{
                    unidad.unidadExistente = false
                });
                return null
            }
            
            this.errores.unidades = false;
            if (value.target.checked) {
                this.elements.push({
                    elementId:elementId,
                    unidad,
                    productsLoad: true,
                });
            } else {
                this.elements.forEach((el,index)=>{
                    if (el.elementId == elementId) {
                        // console.log(el.elementId +"=="+ elementId);
                        // console.log();
                        this.elements.splice(index,1)
                    }
                })
            }
            this.$nextTick(()=>{
                this.refrescarTabs();
                this.$refs["step-"+(this.currentStep)].style.display = "block";
            })
        },
        refrescarTabs(){
            // Ponemos todas las tabs en none (se esconden) menos la que esta como currentStep
            for (let i = 0; i <= this.elements.length; i++) {
                if(i!=this.currentStep){
                    this.$refs["step-"+i].style.display = "none";
                }
            }
            // Si lastStep es verdadero, entonces llegamos a la tab final, la mostarmos
            if (this.lastStep) {
                this.$refs["step-final"].style.display = "block";
            }else {
                // Si no estábamos en la final entonces dejamos visible la currentTab
                this.$refs["step-"+this.currentStep].style.display = "block";
                this.$refs["step-final"].style.display = "none";
            }

        },
        siguiente(){
            if (this.sonInvalidosLosDatosIniciales()) return null
            /**
             *  Las tabs se inician en 0 y los elements desde 1. 
             *  Si estamos en n-1, entonces podemos avanzar por última vez
             */ 
            if (this.currentStep<this.elements.length) {
                this.lastStep = false;
                this.currentStep++;
                this.refrescarTabs();
                this.productosEspecificosStepActual(this.currentStep,this.elements[this.currentStep-1]);
            }else {
                // Sino estamos en presencia de la última tab.
                this.lastStep=true;
                this.currentStep++;
                this.refrescarTabs();
            }
        },
        anterior(){
            if (this.currentStep>0){
                this.lastStep = false;
                if (!this.lastStep) this.currentStep--;
                this.refrescarTabs();
            }
        },
        sonInvalidosLosDatosIniciales(){
            let errores = false;
            if (this.elements.length == 0) {
                this.errores.unidades = true;
                errores = true;
            }            
            return errores
        },
        abrirModalVerMas(producto){
            this.modalVerMas.producto = producto;
            this.modalVerMas.open = true;
        }
    }
}
</script>

<style>

</style>