<template>
  <div class="page-content">
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ breadcrumbB }}
        </li>
      </ol>
    </nav>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card dash-card">
          <div class="card-body">
            <h4 class="dash-title">¡Buenos días {{ nombreUsuario }}!</h4>
            <p class="dash-description">{{ descripcion }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
// import $ from "jquery";
// import { apis } from "../../.env.js";

const userData = JSON.parse(sessionStorage.getItem("userData"));
// let empresa = sessionStorage.getItem("empresa");

export default {
  name: "Dashboard",
  data() {
    return {
      breadcrumbA: "Inicio",
      breadcrumbB: "Dashboard",
      descripcion:
        "En este portal podrás crear ver recetas, crearlas y modificarlas",
      nombreUsuario: userData.leg_nombre.split(' ')[0][0]+userData.leg_nombre.split(' ')[0].slice(1).toLowerCase()
    };
  },
   mounted() {
    // var dias = [];
    // var viandas = [];
    // const axiosrequest1 = axios.get(
    //   apis.carritoEmpresa + empresa + ".json?print=pretty"
    // );
    // const axiosrequest2 = axios.get(
    //   "https://catering.foodservice.com.ar/api/formusers?empresa_id=" +
    //     userData.empresa_id +
    //     "&modelo=User",
    //   {
    //     headers: {
    //       Authorization: "Bearer " + sessionStorage.getItem("token"),
    //     },
    //   }
    // );

    // await axios
    //   .all([axiosrequest1, axiosrequest2])
    //   .then(
    //     axios.spread((comensales, usuarios) => {
    //       // console.log(comensales)

    //       $("#registrados").html(usuarios.data.length);

    //       $.each(comensales.data, function(clave, valor) {
    //         dias.push(clave);

    //         $.each(valor, function(k, v) {
    //           $.each(v, function(ki, va) {
    //             viandas.push(va.codigo);
    //           });
    //         });
    //       });
    //       $("#viandas").html(viandas.length);
    //       $("#dias").html(dias.length);
    //     })
    //   )
    //   .then(() => {});
  },
};
</script>

<style>
      .dash-card {
        background-color: #00DBDE;
        background-image: linear-gradient(205deg, #00DBDE 0%, #FC00FF 100%);
        padding: 20px 0;
        color: #fff;
        border:none;
      }

      .dash-title {
        font-size: 1.2rem;
        margin: 0;
      }

      .dash-description {
        margin: 0;
      }
    </style>
