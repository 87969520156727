export var apis = {
    carritoEmpresa: 'https://fs-comedores-admin-default-rtdb.firebaseio.com/carrito/',
    turnosEmpresa: 'https://fs-comedores-admin-default-rtdb.firebaseio.com/turnos/',
    productos: 'https://fs-comedores-admin-default-rtdb.firebaseio.com/productos.json?print=pretty',
    productoConId: 'https://fs-comedores-admin-default-rtdb.firebaseio.com/productos/',
    empresas: 'https://fs-comedores-admin-default-rtdb.firebaseio.com/empresas.json?print=pretty',
    unidadesDeNegocio: 'https://fs-comedores-admin-default-rtdb.firebaseio.com/unidades_negocio.json?print=pretty',
    recetas: 'https://recetario-foodservice.firebaseio.com/recetas.json?print=pretty',
    // recetas: 'https://fs-comedores-admin-default-rtdb.firebaseio.com/recetas.json?print=pretty',
    empresasConsumos: "https://consumos.foodservice.com.ar/api/empresas",
    recetasSalida: "https://auth.foodservice.com.ar/?type=RecetasSalida&access_token=1234567",
    recetasEntradas: 'https://recetario-foodservice.firebaseio.com/tipo_entradas.json',
    recetasCondiciones: 'https://recetario-foodservice.firebaseio.com/tag_condicion.json',
    ingredientes:'https://recetario-foodservice.firebaseio.com/ingredientes.json',
    platos: 'https://recetario-foodservice.firebaseio.com/platos.json',
    
    genericos: 'https://auth.foodservice.com.ar/?type=genericos&access_token=1234567',
};

// Imagen generica que se usa en las recetas y productos
export var previewIconImage = 'https://firebasestorage.googleapis.com/v0/b/fs-comedores-admin.appspot.com/o/fs-preview-icon.jpg?alt=media&token=39900ed3-024f-4453-9689-ad90cb961191';


export function prefabQ(query, parametro){
    switch (query) {
        case 'producto':
            return 'https://fs-comedores-admin-default-rtdb.firebaseio.com/productos/'+parametro+'.json?print=pretty';
        case 'empresaEnComedores':
            return 'https://fs-comedores-admin-default-rtdb.firebaseio.com/empresas/'+parametro+'.json?print=pretty'
        case 'ingredientesEnRecetaSalida':
            return `https://auth.foodservice.com.ar/?type=RecetasSalidaIngrediente&id=${parametro}&access_token=1234567`
        default:
            return 'api de consulta inexistente'
    }
}

export function productoConElId(id){
    return 'https://fs-comedores-admin-default-rtdb.firebaseio.com/productos/'+id+'.json?print=pretty'
}

export function empresaEnComedoresConId(id){
    return 'https://fs-comedores-admin-default-rtdb.firebaseio.com/empresas/'+id+'.json?print=pretty'
}

export var firebaseConfig = {
    apiKey: "AIzaSyCbxKVAzSoxwo1DICe86oETxOjBZwE00DU",
    authDomain: "fs-comedores-admin.firebaseapp.com",
    databaseURL: "https://fs-comedores-admin-default-rtdb.firebaseio.com",
    projectId: "fs-comedores-admin",
    storageBucket: "fs-comedores-admin.appspot.com",
    messagingSenderId: "788991141446",
    appId: "1:788991141446:web:4b2740cebf361e0880cc47"
};

export var firebaseRecetario = {
    // Prod
    apiKey: "AIzaSyApB2uQlboVr5b77Cvouv_p6B7-lcKhTYo",
    authDomain: "recetario-foodservice.firebaseapp.com",
    databaseURL: "https://recetario-foodservice.firebaseio.com",
    projectId: "recetario-foodservice",
    storageBucket: "recetario-foodservice.appspot.com",
    messagingSenderId: "839765163047",
    appId: "1:839765163047:web:e12263300de6048e"


    // Dev
    // apiKey: "AIzaSyBIdDKcZ5YHXzwG3UOjRiDRzV7vFymuMmo",
    // authDomain: "fs-recetario.firebaseapp.com",
    // databaseURL: "https://fs-recetario-default-rtdb.firebaseio.com",
    // projectId: "fs-recetario",
    // storageBucket: "fs-recetario.appspot.com",
    // messagingSenderId: "847772736340",
    // appId: "1:847772736340:web:5222a18850cd6e1d9bc6c1"

};

export var firebaseLogin = {
    apiKey: "AIzaSyBLyOhPH9YaePUSi5fIMjaavlrQVJ2-UnY",
    authDomain: "cantina-89c6a.firebaseapp.com",
    databaseURL: "https://aplicativos.firebaseio.com",
    projectId: "cantina-89c6a",
    storageBucket: "cantina-89c6a.appspot.com",
    messagingSenderId: "353062217145",
    appId: "1:353062217145:web:8849a49a1ec52077"
};



export var tableConfig = {
    "language": {
        "decimal": ",",
        "thousands": ".",
        "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
        "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
        "infoPostFix": "",
        "infoFiltered": "(filtrado de un total de _MAX_ registros)",
        "loadingRecords": "Cargando...",
        "lengthMenu": "Mostrar _MENU_ registros",
        "paginate": {
            "first": "Primero",
            "last": "Último",
            "next": "Siguiente",
            "previous": "Anterior"
        },
        "processing": "Procesando...",
        "search": "Buscar:",
        "searchPlaceholder": "Término de búsqueda",
        "zeroRecords": "No se encontraron resultados",
        "emptyTable": "Ningún dato disponible en esta tabla",
        "aria": {
            "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
            "sortDescending": ": Activar para ordenar la columna de manera descendente"
        },
    },
    "scrollX": false, 
    "search": {regex:true},
    dom: 'Bfrtip',
    responsive: false,
    nowrap: false,
    buttons: [
        'copy',
        'excel',
        'csv',
        'pdf'
    ]
};